import BackgroundColorModifier from "./modifiers/BackgroundColorModifier";
import ButtonModifier from "./modifiers/ButtonModifier";
import GeneratedVariantSelector from "./modifiers/GeneratedVariantSelector";
import ImageModifier from "./modifiers/ImageModifier";
import TextModifier from "./modifiers/TextModifier";
import useInitEmailSectionMutation from "@hooks/emails/useInitEmailSectionQuery";
import { EmailSectionType } from "@openapi";
import { Flex, Separator } from "@radix-ui/themes";
import { SparklesIcon } from "lucide-react";
import ChatMessageContainer from "~/components/chat/ChatMessageContainer";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import TextAreaWithLabel from "~/components/core/inputs/TextAreaWithLabel";
import { ImageAssetSchema } from "~/components/style-library/assets/BrandImageAsset";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import {
  useEmailState,
  useUpdateSectionField,
} from "~/routes/intern/email_editor/context/EmailEditorContext";
import {
  isHeroSection,
  isSalesEventHeroSection,
  isNewSection,
} from "~/utils/emails/useSectionTypeCheck";

const HeroView = () => {
  const activeBrandId = useActiveBrandID();
  const updateSection = useUpdateSectionField();

  const { selectedSectionId, sections } = useEmailState();
  const selectedSection = sections.find(
    (section) => section.id === selectedSectionId
  );

  const { mutate: initEmailSection, isPending: isInitEmailSectionLoading } =
    useInitEmailSectionMutation();

  if (!selectedSection) {
    return null;
  }

  if (
    !isHeroSection(selectedSection) &&
    !isNewSection(selectedSection) &&
    !isSalesEventHeroSection(selectedSection)
  ) {
    return null;
  }

  const image = selectedSection?.image ?? null;

  const imageMod = (
    <ImageModifier
      enabled={image?.enabled ?? false}
      initialImageUrl={image?.image_url}
      onSwitchToggle={(enabled) => {
        updateSection({
          sectionId: selectedSection.id,
          field: "image.enabled",
          value: enabled,
        });
      }}
      onImageChange={(image: ImageAssetSchema) => {
        updateSection({
          sectionId: selectedSection.id,
          field: "image.image_url",
          value: image.url,
        });
      }}
    />
  );

  if (
    !isHeroSection(selectedSection) &&
    !isSalesEventHeroSection(selectedSection)
  ) {
    const onGenerateOrWriteManually = (generate: boolean) => {
      initEmailSection({
        brand_id: activeBrandId,
        section_type: EmailSectionType.hero,
        id: selectedSection.id,
        index: selectedSection.index,
        generate: generate,
        asset_id: image?.id,
      });
    };
    return (
      <Flex direction="column" gap="24px">
        <Flex gap="16px">
          <ChatMessageContainer message="Please input what content should be generated." />
        </Flex>
        <TextAreaWithLabel labelColor="var(--text-tertiary)" label="Prompt" />

        {imageMod}

        <Separator size="4" />
        <Flex justify="end" gap="16px">
          <AppButton
            onClick={() => onGenerateOrWriteManually(false)}
            variant="outlined"
            size="3"
            radius="large"
            disabled={isInitEmailSectionLoading}
          >
            Write manually
          </AppButton>
          <AppButton
            onClick={() => onGenerateOrWriteManually(true)}
            variant="dark"
            radius="large"
            size="3"
            disabled={isInitEmailSectionLoading}
          >
            <SparklesIcon />
            Generate
          </AppButton>
        </Flex>
      </Flex>
    );
  }

  const title = selectedSection.title;
  const subtitle = selectedSection.subtitle;
  const ctaButton = selectedSection.cta_button;
  const backgroundColor = selectedSection.palette.background;

  let customModifier = null;
  if (isSalesEventHeroSection(selectedSection)) {
    customModifier = (
      <>
        <TextModifier
          textElement={selectedSection.discount_text}
          label="Discount"
          enabled={selectedSection.discount_text.enabled ?? false}
          fieldName="discount_text.text"
          onSwitchToggle={(enabled) => {
            updateSection({
              sectionId: selectedSection.id,
              field: "discount_text.enabled",
              value: enabled,
            });
          }}
        />
        <TextModifier
          textElement={selectedSection.promo_code}
          label="Promo Code"
          enabled={selectedSection.promo_code.enabled ?? false}
          fieldName="promo_code.text"
          onSwitchToggle={(enabled) => {
            updateSection({
              sectionId: selectedSection.id,
              field: "promo_code.enabled",
              value: enabled,
            });
          }}
        />
      </>
    );
  } else if (isHeroSection(selectedSection)) {
    customModifier = (
      <TextModifier
        textElement={subtitle}
        label="Subtitle"
        enabled={selectedSection.subtitle.enabled ?? false}
        fieldName="subtitle.text"
        onSwitchToggle={(enabled) => {
          updateSection({
            sectionId: selectedSection.id,
            field: "subtitle.enabled",
            value: enabled,
          });
        }}
      />
    );
  }

  return (
    <>
      <GeneratedVariantSelector />

      {imageMod}

      <TextModifier
        textElement={title}
        label="Title"
        enabled={title.enabled ?? false}
        fieldName="title.text"
        onSwitchToggle={(enabled) => {
          updateSection({
            sectionId: selectedSection.id,
            field: "title.enabled",
            value: enabled,
          });
        }}
      />

      {customModifier}

      <ButtonModifier
        fieldName="cta_button"
        buttonElement={ctaButton}
        onSwitchToggle={(enabled) => {
          updateSection({
            sectionId: selectedSection.id,
            field: "cta_button.enabled",
            value: enabled,
          });
        }}
      />

      <BackgroundColorModifier
        hideSwitch={true}
        palette={selectedSection.palette}
        sectionId={selectedSection.id}
      />
    </>
  );
};

export default HeroView;
