import classes from "./FacebookAdCard.module.css";
import { Flex } from "@radix-ui/themes";
import {
  Earth,
  Ellipsis,
  Forward,
  MessageSquare,
  ThumbsUp,
  X,
} from "lucide-react";
import AdMediaSVGCanvas from "~/components/ads/AdMediaSVGCanvas";
import { useAdEditorSelectedAdMediaState } from "~/contexts/AdEditorContext";

const FacebookAdCard = () => {
  const { ad: adData, media } = useAdEditorSelectedAdMediaState();
  const mediaUrl = media?.variants?.find(
    (variant) => variant.aspect_ratio === "square"
  )?.file;

  const domain = adData?.data.campaign.destination.replace(
    /^(https?:\/\/)?(www.)?/,
    ""
  );
  const header = (
    <Flex direction="column" className={classes.header} gap="8px">
      <Flex justify="between">
        <Flex gap="6px" align="center">
          <div className={classes.icon}></div>
          <Flex direction="column">
            <span className={classes.company_name}>
              {adData?.data.brand_name}
            </span>
            <Flex gap="10px" align="center">
              <span className={classes.sponsored}>Sponsored</span>
              <Earth color="#656565" size="12px" />
            </Flex>
          </Flex>
        </Flex>
        <Flex gap="8px">
          <Ellipsis color="#656565" />
          <X color="#656565" />
        </Flex>
      </Flex>
      <span>{adData?.data.primary_text}</span>
    </Flex>
  );

  const content = (
    <AdMediaSVGCanvas svgUrl={mediaUrl} customStyle={{ borderRadius: "0px" }} />
  );
  const footer1 = (
    <Flex justify="between" className={classes.footer1} align="center">
      <Flex direction="column" gap="2px">
        <span className={classes.sponsored}>{domain}</span>
        <span className={classes.primary_text}>
          {adData?.data.primary_text}
        </span>
      </Flex>
      <button className={classes.button}>Shop Now</button>
    </Flex>
  );
  const interactFooterButton = (icon: React.ReactNode, text: string) => (
    <Flex gap="10px" align="center">
      {icon}
      <span className={classes.interactive_button_text}>
        <b>{text}</b>
      </span>
    </Flex>
  );
  const footer2 = (
    <Flex align="center" className={classes.footer2}>
      {interactFooterButton(<ThumbsUp color="#656565" />, "Like")}
      {interactFooterButton(<MessageSquare color="#656565" />, "Comment")}
      {interactFooterButton(<Forward color="#656565" />, "Share")}
    </Flex>
  );
  return (
    <Flex direction="column" className={classes.container}>
      {header}
      {content}
      {footer1}
      {footer2}
    </Flex>
  );
};

export default FacebookAdCard;
