import AppButton from "../core/buttons/AppButton/AppButton";
import { Flex, Text } from "@radix-ui/themes";
import { ArrowRightIcon } from "lucide-react";
import styled from "styled-components";

const CenterContainer = styled(Flex)`
  border: 1px solid #ddd7d7;
  border-radius: 16px;
  background: #fff;
  padding: 40px;
`;

const Video = styled.video`
  background-color: black;
  border-radius: 8px;
`;

const OnboardingVideo = ({ onNext }: { onNext: () => void }) => {
  return (
    <Flex direction={"column"} justify={"center"} align={"center"}>
      <CenterContainer
        direction="column"
        justify={"center"}
        align={"center"}
        p="6"
      >
        <Flex align={"center"} gap="24px" width={"514px"} direction={"column"}>
          <Text size="6" weight={"bold"}>
            Introducing your AI Marketing Assistant
          </Text>
          <Video
            autoPlay
            width={"340px"}
            height={"500px"}
            color="black"
            poster="https://media.newyorker.com/photos/59096d86ebe912338a37697a/master/pass/Jahromi-The-Weird-Agelessness-of-Don-Draper.jpg"
          />
          <AppButton onClick={onNext} style={{ width: "350px" }}>
            Get Started <ArrowRightIcon />
          </AppButton>
        </Flex>
      </CenterContainer>
    </Flex>
  );
};

export default OnboardingVideo;
