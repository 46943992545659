import StyleLibraryCardContent from "../StyleLibraryCardContent";
import ChangeFontFamilyDialog from "./ChangeFontDialog/ChangeFontFamilyDialog";
import classes from "./TypographyCardSection.module.css";
import { FontType, operations } from "@openapi";
import { Flex, Text } from "@radix-ui/themes";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import _ from "lodash";
import { PlusIcon } from "lucide-react";
import { useMemo, useState } from "react";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import TextFieldWithIncrement from "~/components/core/inputs/TextFieldWithIncrement";
import {
  useBrandStyle,
  useBrandStylingDispatch,
} from "~/contexts/BrandStylingContext";
import { getFontWeightDescription } from "~/utils/brand-style/helpers";

type UpdateTypographyFontSizesParams =
  operations["brand_api_update_typography_sizes"]["requestBody"]["content"]["application/json"];
type UpdateTypographyFontSizesResponse =
  operations["brand_api_update_typography_sizes"]["responses"][200]["content"]["application/json"];

const TypographyCardSection = () => {
  const { data: brandStyle, isLoading } = useBrandStyle();
  const brandStylingDispatch = useBrandStylingDispatch();
  const [sizeChanges, setSizeChanges] = useState<{
    [key in FontType]?: string;
  }>({});
  const fonts = useMemo(() => {
    if (!brandStyle) {
      return [];
    }
    return brandStyle.typography.fonts.sort((f1, f2) =>
      f1.type.localeCompare(f2.type)
    );
  }, [brandStyle]);
  const [isFontDialogOpen, setIsFontDialogOpen] = useState(false);

  const fontsByType = useMemo(() => {
    return _.keyBy(fonts, "type");
  }, [fonts]);

  const incrementFontSize = (type: FontType, increment: number) => {
    const originalSize = fontsByType[type].size;
    const currentSize = sizeChanges[type] ?? originalSize;
    const unit = currentSize.replace(/[0-9]/g, "");
    const newValue = Math.max(parseFloat(currentSize) + increment, 1);
    const newSize = `${newValue}${unit}`;
    if (newSize === originalSize) {
      const { [type]: _, ...rest } = sizeChanges;
      setSizeChanges(rest);
    } else {
      setSizeChanges({ ...sizeChanges, [type]: newSize });
    }
  };

  const updateFontSizesMutation = useMutation<
    UpdateTypographyFontSizesResponse,
    Error,
    UpdateTypographyFontSizesParams
  >({
    mutationFn: async (params) => {
      const { data } = await axios.post(
        "/api/v1/brand/stylebook/typography/sizes",
        params,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return data;
    },
    onSuccess: (data) => {
      console.log("Font sizes updated successfully:", data);

      brandStylingDispatch({
        type: "PATCH_TYPOGRAPHY_SIZES",
        payload: sizeChanges,
      });
      setSizeChanges({});
    },
    onError: (error) => {
      // TODO: global error handling and toasts
      alert(error);
      console.error("Error saving font sizes:", error);
    },
  });

  const openFontSelector = () => {
    setIsFontDialogOpen(true);
  };
  const onSaveChanges = () => {
    const params = _(sizeChanges)
      .map((size, type) => ({ id: fontsByType[type].id, size: size! }))
      .value();
    updateFontSizesMutation.mutate(params);
  };
  const sampleText = "The quick brown fox jumps over the lazy dog";

  return (
    <StyleLibraryCardContent
      title="Typography"
      isLoading={isLoading}
      actions={
        <>
          {(brandStyle?.typography.header_font_family ||
            brandStyle?.typography.paragraph_font_family) && (
            <AppButton
              variant="outlined"
              radius="large"
              size="3"
              onClick={openFontSelector}
            >
              Change Font
            </AppButton>
          )}
          <AppButton
            variant="dark"
            radius="large"
            size="3"
            onClick={onSaveChanges}
            disabled={
              Object.keys(sizeChanges).length === 0 ||
              updateFontSizesMutation.isPending
            }
          >
            Save Changes
          </AppButton>
        </>
      }
    >
      <Flex
        m={"2"}
        minWidth={"100%"}
        direction={"column"}
        gap={"4"}
        style={{
          display: "flex",
          overflow: "auto",
          ...(!!fonts.length
            ? {}
            : {
                background: "var(--background_light_grey)",
                border: "1px solid rgb(228, 228, 231)",
                borderRadius: "var(--radius-3)",
                padding: "var(--space-4)",
              }),
        }}
      >
        {!fonts.length && (
          <Flex direction="column" align={"center"} width={"100%"} gap={"4"}>
            <Text
              size={"3"}
              align={"center"}
              style={{ color: "var(--text_light_grey)" }}
            >
              No font selected for headings & paragraph
            </Text>
            <AppButton variant="dark" radius="large" onClick={openFontSelector}>
              <PlusIcon /> Add Font
            </AppButton>
          </Flex>
        )}
        {fonts.map((font) => {
          const fontSize = sizeChanges[font.type] ?? font.size;
          return (
            <Flex
              key={font.id}
              direction={"row"}
              minWidth={"100%"}
              align={"center"}
              gap={"2"}
              minHeight={"48px"}
              style={{
                whiteSpace: "nowrap",
                color: "var(--text-tertiary)",
              }}
            >
              <Text
                size={"2"}
                style={{
                  textTransform: "capitalize",
                }}
              >
                {font.type.toLowerCase()}
              </Text>
              <Text
                className={classes.textSample}
                style={{
                  fontFamily: `${font.font_family.name}`,
                  fontWeight: font.weight,
                  fontSize: fontSize,
                }}
              >
                {sampleText}
              </Text>
              <Text size={"2"}>{`${
                font.font_family.name
              }, ${getFontWeightDescription(font.weight)}`}</Text>
              <Text size={"2"}>Font Size</Text>
              <TextFieldWithIncrement
                readOnly
                disabled={updateFontSizesMutation.isPending}
                value={fontSize}
                style={{
                  background: "white",
                }}
                onIncrement={() => {
                  incrementFontSize(font.type, 1);
                }}
                onDecrement={() => {
                  incrementFontSize(font.type, -1);
                }}
              />
            </Flex>
          );
        })}
      </Flex>
      <ChangeFontFamilyDialog
        isOpen={isFontDialogOpen}
        setIsOpen={setIsFontDialogOpen}
        fontFamilies={{
          header: brandStyle?.typography.header_font_family
            ? {
                ...brandStyle.typography.header_font_family,
                fontWeight: fontsByType[FontType.H1]?.weight,
              }
            : null,
          paragraph: brandStyle?.typography.paragraph_font_family
            ? {
                ...brandStyle.typography.paragraph_font_family,
                fontWeight: fontsByType[FontType.PARAGRAPH]?.weight,
              }
            : null,
        }}
      />
    </StyleLibraryCardContent>
  );
};

export default TypographyCardSection;
