import StyleLibraryCardContent from "../StyleLibraryCardContent";
import ButtonStyleDialog from "./dialogs/ButtonStyleDialog";
import { components, operations } from "@openapi";
import { Box, Flex } from "@radix-ui/themes";
import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import Cookies from "js-cookie";
import { PlusIcon } from "lucide-react";
import { useState } from "react";
import styled from "styled-components";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import BrandButton from "~/components/core/buttons/BrandButton";
import {
  useBrandStyle,
  useBrandStylingDispatch,
} from "~/contexts/BrandStylingContext";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useDeleteButtonStyle from "~/hooks/style-library/useDeleteButtonStyle";

const ButtonContainer = styled(Box)`
  padding: 12px;
  border-radius: 12px;
  background-color: var(--background_light_grey);
  align-content: center;
`;
type UpsertButtonStyleParams =
  operations["brand_api_update_button_style"]["requestBody"]["content"]["application/json"];
export type UpsertButtonStyleResponse =
  operations["brand_api_update_button_style"]["responses"][200]["content"]["application/json"];

const ButtonStylesCardSection = () => {
  const { data: brandStyle, isLoading } = useBrandStyle();
  const buttonStyles = brandStyle?.button_styles ?? [];
  const [editingStyle, setEditingStyle] = useState<
    components["schemas"]["BrandButtonStyleSchema"] | null
  >(null);
  const [open, setOpen] = useState(false);

  const activeBrandID = useActiveBrandID();
  const brandStylingDispatch = useBrandStylingDispatch();

  const handleAddEdit =
    (style: components["schemas"]["BrandButtonStyleSchema"] | null) => () => {
      setEditingStyle(style);
      setOpen(true);
    };

  const handleOpen = (open: boolean) => {
    if (!open) {
      setEditingStyle(null);
    }
    setOpen(open);
  };

  const upsertButtonStyle = useMutation<
    UpsertButtonStyleResponse,
    AxiosError,
    UpsertButtonStyleParams
  >({
    mutationFn: async (params) => {
      const { data } = await axios.post(
        `/api/v1/brand/${activeBrandID}/stylebook/button${
          editingStyle?.id ? `/${editingStyle.id}` : ""
        }`,
        params,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return data;
    },
    onSuccess: (data) => {
      brandStylingDispatch({
        type: "UPSERT_BUTTON_STYLE",
        payload: {
          isNew: !editingStyle?.id,
          style: data,
        },
      });
      handleOpen(false);
    },
    onError: (error) => {
      console.log("Button style upsert failed:", error);
      const errorMessage =
        error.status !== 500 && error.response?.data
          ? String(error.response?.data)
          : "Something went wrong";
    },
  });

  const handleSave = (
    style: components["schemas"]["UpdateButtonStyleRequestData"]
  ) => {
    upsertButtonStyle.mutate(style);
  };

  const deleteButtonStyle = useDeleteButtonStyle({
    buttonStyleId: editingStyle?.id ?? "",
    onSuccess: () => {
      handleOpen(false);
    },
    onError: (message) => {},
  });

  const handleDelete = () => {
    deleteButtonStyle.mutate();
  };

  const isUpdating = upsertButtonStyle.isPending || deleteButtonStyle.isPending;

  return (
    <StyleLibraryCardContent
      title="Button Styles"
      isLoading={isLoading}
      actions={
        <AppButton onClick={handleAddEdit(null)} variant="ghost" size={"3"}>
          <PlusIcon
            style={{
              padding: "0.1rem",
            }}
          />
          Add Button
        </AppButton>
      }
    >
      {open && (
        <ButtonStyleDialog
          open={open}
          onOpenChange={handleOpen}
          buttonStyle={editingStyle ?? undefined}
          onSave={handleSave}
          onDelete={handleDelete}
          isUpdating={isUpdating}
        />
      )}
      <Flex
        direction="row"
        align="center"
        wrap="wrap"
        gap="4"
        style={{
          alignItems: "stretch",
        }}
      >
        {buttonStyles.map((style) => {
          return (
            <ButtonContainer key={style.id}>
              <BrandButton
                brandButtonStyle={style}
                onClick={handleAddEdit(style)}
              >
                Shop Now
              </BrandButton>
            </ButtonContainer>
          );
        })}
      </Flex>
    </StyleLibraryCardContent>
  );
};

export default ButtonStylesCardSection;
