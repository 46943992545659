import { components } from "@openapi";
import { Grid } from "@radix-ui/themes";
import styled from "styled-components";

const Image = styled.img<{ $aspectRatio?: string; height?: string }>`
  height: ${(props) => props.height};
  width: 100%;
  object-fit: cover;
  border-radius: 12px;
  border: none;
  overflow: hidden;
`;

const ImagePlaceholder: React.FC<{
  product?: Pick<
    components["schemas"]["ShopifyProductInfoExtra"],
    "image_url" | "title"
  >;
}> = ({ product }) => (
  <div style={{ filter: "blur(0)", overflow: "hidden", borderRadius: 12 }}>
    <Image
      height="180px"
      src={product?.image_url ?? ""}
      alt={product?.title}
      style={{
        filter: "blur(100px)",
      }}
    />
  </div>
);

const CampaignProductsPreview: React.FC<{
  productsData?: components["schemas"]["ShopifyProductInfoExtra"][] | null;
}> = ({ productsData }) => (
  <Grid gap="4" maxWidth="100%" columns="repeat(3, minmax(0, 1fr))">
    <Image
      height="180px"
      src={productsData?.[0]?.image_url ?? ""}
      alt={productsData?.[0]?.title}
    />
    {productsData?.[1]?.image_url ? (
      <Image
        height="180px"
        src={productsData?.[1]?.image_url ?? ""}
        alt={productsData?.[1]?.title}
      />
    ) : (
      <ImagePlaceholder product={productsData?.[0]} />
    )}
    {productsData?.[2]?.image_url ? (
      <Image
        height="180px"
        src={productsData?.[2]?.image_url ?? ""}
        alt={productsData?.[2]?.title}
      />
    ) : (
      <ImagePlaceholder product={productsData?.[0]} />
    )}
  </Grid>
);

export default CampaignProductsPreview;
