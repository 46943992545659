import { EmailSectionType, operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import Handlebars from "handlebars";
import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";

const Container = styled.div`
  display: flex;
  height: 100%;
`;

const TemplateList = styled.div`
  width: 300px;
  overflow-y: auto;
  border-right: 1px solid #ccc;
  padding: 20px;
`;

const PreviewPane = styled.div`
  flex: 1;
  padding: 20px;
  overflow-y: auto;
`;

const SectionTitle = styled.h2`
  margin-top: 20px;
  margin-bottom: 10px;
`;

const TemplateItem = styled.div<{ $isSelected: boolean }>`
  cursor: pointer;
  padding: 10px;
  margin-bottom: 5px;
  background-color: ${(props) =>
    props.$isSelected ? "#e0e0e0" : "transparent"};
  &:hover {
    background-color: #f0f0f0;
  }
`;

const PreviewContainer = styled.div`
  width: 100%;
  height: 100%;
`;

type EmailSectionTemplatesApiResponse =
  operations["emails_api_get_section_templates"]["responses"][200]["content"]["application/json"];

type EmailSectionTemplateSchema =
  EmailSectionTemplatesApiResponse["templates"][number];

const InternEmailTemplatesComponent: React.FC = () => {
  const [selectedTemplate, setSelectedTemplate] =
    useState<EmailSectionTemplateSchema | null>(null);
  const previewRef = useRef<HTMLDivElement>(null);

  const activeBrandID = useActiveBrandID();

  const {
    data: templatesResponse,
    isLoading,
    error,
  } = useQuery<EmailSectionTemplatesApiResponse>({
    queryKey: ["emailSectionTemplates"],
    queryFn: async () => {
      const response = await axios.get("/api/v1/emails/section-templates", {
        params: { brand_id: activeBrandID },
      });
      return response.data;
    },
  });

  useEffect(() => {
    if (selectedTemplate && previewRef.current) {
      while (previewRef.current.firstChild) {
        previewRef.current.removeChild(previewRef.current.firstChild);
      }

      const container = document.createElement("div");
      const loader = Handlebars.compile(selectedTemplate.html);
      container.innerHTML = `
        <style>
          body { margin: 0; font-family: Arial, sans-serif; }
        </style>  
        ${loader(
          templatesResponse?.section_defaults?.[selectedTemplate.section_type]
        )}
      `;

      previewRef.current.appendChild(container);
    }
  }, [selectedTemplate]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {(error as Error).message}</div>;

  const templates = templatesResponse?.templates || [];

  const groupedTemplates = templates.reduce((acc, template) => {
    const sectionType = template.section_type as EmailSectionType;
    if (!acc[sectionType]) {
      acc[sectionType] = [];
    }
    acc[sectionType].push(template);
    return acc;
  }, {} as Record<EmailSectionType, EmailSectionTemplateSchema[]>);

  return (
    <Container>
      <TemplateList>
        {Object.entries(groupedTemplates).map(
          ([sectionType, sectionTemplates]) => (
            <React.Fragment key={sectionType}>
              <SectionTitle>{sectionType}</SectionTitle>
              {sectionTemplates.map((template) => (
                <TemplateItem
                  key={template.id}
                  $isSelected={selectedTemplate?.id === template.id}
                  onClick={() => setSelectedTemplate(template)}
                >
                  {template.name}
                </TemplateItem>
              ))}
            </React.Fragment>
          )
        )}
      </TemplateList>
      <PreviewPane>
        {selectedTemplate ? (
          <PreviewContainer ref={previewRef} />
        ) : (
          <div>Select a template to preview</div>
        )}
      </PreviewPane>
    </Container>
  );
};

export default InternEmailTemplatesComponent;
