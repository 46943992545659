import { Flex, Switch, Text } from "@radix-ui/themes";
import React from "react";
import styled from "styled-components";

const Container = styled(Flex)`
  border: 1px solid #ddd7d7;
  border-radius: 12px;
  padding: 12px;
  flex-direction: column;
  gap: 16px;
`;

const Title = styled(Text)`
  font-size: 16px;
  color: var(--text-secondary);
  font-weight: 500;
`;

const ModifierContainer = ({
  title,
  hideSwitch = false,
  checked = false,
  onSwitch,
  children,
  disabled = false,
}: {
  children: React.ReactNode;
  title: string;
  checked?: boolean;
  hideSwitch?: boolean;
  onSwitch?: (checked: boolean) => void;
  disabled?: boolean;
}) => {
  return (
    <Container>
      <Flex justify="between">
        <Text size={"3"} weight="medium">
          {title}
        </Text>
        {!hideSwitch && (
          <Flex gap="8px">
            <Text size={"3"}>Show</Text>
            <Switch
              onCheckedChange={onSwitch}
              checked={checked}
              disabled={disabled}
            />
          </Flex>
        )}
      </Flex>
      {children}
    </Container>
  );
};

export default ModifierContainer;
