import BackgroundColorModifier from "./modifiers/BackgroundColorModifier";
import CollectionMetadataModifier from "./modifiers/CollectionMetadataModifier";
import CollectionModifier from "./modifiers/CollectionModifier";
import GeneratedVariantSelector from "./modifiers/GeneratedVariantSelector";
import TextModifier from "./modifiers/TextModifier";
import { EmailSectionType } from "@openapi";
import { Flex } from "@radix-ui/themes";
import { SparklesIcon } from "lucide-react";
import ProductSelectorDialog from "~/components/campaign/wizard/products/components/productSelector/ProductSelectorDialog";
import ChatMessageContainer from "~/components/chat/ChatMessageContainer";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useInitEmailSectionMutation from "~/hooks/emails/useInitEmailSectionQuery";
import {
  useEmailState,
  useUpdateSectionField,
} from "~/routes/intern/email_editor/context/EmailEditorContext";
import {
  isCollectionGridSection,
  isNewSection,
} from "~/utils/emails/useSectionTypeCheck";

const CollectionGridView = () => {
  const activeBrandId = useActiveBrandID();
  const updateSection = useUpdateSectionField();

  const { mutate: initEmailSection } = useInitEmailSectionMutation();

  const { selectedSectionId, sections } = useEmailState();
  const selectedSection = sections.find(
    (section) => section.id === selectedSectionId
  );

  if (!selectedSection) {
    return null;
  }

  if (
    !isNewSection(selectedSection) &&
    !isCollectionGridSection(selectedSection)
  ) {
    return null;
  }

  if (!isCollectionGridSection(selectedSection)) {
    return (
      <Flex direction="column" gap="24px">
        <Flex gap="16px">
          <ChatMessageContainer message="Would you like to select products manually?" />
        </Flex>

        <Flex justify="end" gap="16px">
          <ProductSelectorDialog
            onConfirm={(products) => {
              initEmailSection({
                brand_id: activeBrandId,
                section_type: EmailSectionType.products,
                id: selectedSection.id,
                index: selectedSection.index,
                generate: false,
                product_ids: Array.from(products.values()).map(
                  (product) => product.product_id
                ),
              });
            }}
            triggerComponent={
              <AppButton variant="outlined" radius="large" size="3">
                Select Manually
              </AppButton>
            }
          />
          <AppButton variant="dark" radius="large" size="3">
            <SparklesIcon />
            AI Assisted
          </AppButton>
        </Flex>
      </Flex>
    );
  }

  const title = selectedSection.title;
  const subtitle = selectedSection.subtitle;
  const collections = selectedSection.collections;

  return (
    <>
      <GeneratedVariantSelector />

      <CollectionModifier
        collections={collections}
        onCollectionChange={(collectionIds) => {
          initEmailSection({
            brand_id: activeBrandId,
            section_type: EmailSectionType.collection_grid,
            id: selectedSectionId,
            index: selectedSection.index,
            generate: false,
            collection_ids: collectionIds,
          });
        }}
        limit={4}
        onDragEnd={(newCollections) => {
          updateSection({
            sectionId: selectedSectionId,
            field: "collections",
            value: newCollections,
          });
        }}
      />

      <TextModifier
        textElement={title}
        label="Title"
        enabled={title.enabled ?? false}
        fieldName="title.text"
        onSwitchToggle={(enabled) => {
          updateSection({
            sectionId: selectedSection.id,
            field: "title.enabled",
            value: enabled,
          });
        }}
      />
      <TextModifier
        textElement={subtitle}
        label="Subtitle"
        enabled={subtitle.enabled ?? false}
        fieldName="subtitle.text"
        onSwitchToggle={(enabled) => {
          updateSection({
            sectionId: selectedSection.id,
            field: "subtitle.enabled",
            value: enabled,
          });
        }}
      />

      <CollectionMetadataModifier collections={collections} />

      <BackgroundColorModifier
        sectionId={selectedSection.id}
        hideSwitch={true}
        palette={selectedSection.palette}
      />
    </>
  );
};

export default CollectionGridView;
