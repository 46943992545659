import { Button, Card, Flex, Heading, Select, Text } from "@radix-ui/themes";
import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

interface InternAdMediaTemplateVariantSVGElementToolkitTabProps {
  element: SVGElement | null;
  stateCounter: number;
  onAttributeChange: () => void;
}

const InternAdMediaTemplateVariantSVGElementToolkitTab: React.FC<
  InternAdMediaTemplateVariantSVGElementToolkitTabProps
> = ({ element, stateCounter, onAttributeChange }) => {
  const [svgStateCounter, setSVGStateCounter] = useState<number | null>(null);

  const [preserveAspectRatio, setPreserveAspectRatio] =
    useState<string>("xMidYMid meet");

  useEffect(() => {
    if (element?.tagName.toLowerCase() === "image") {
      const aspectRatio =
        element.getAttribute("preserveAspectRatio") || "xMidYMid meet";
      setPreserveAspectRatio(aspectRatio);
    }
  }, [element]);

  const onPreserveAspectRatioSelection = (value: string) => {
    if (element?.tagName.toLowerCase() === "image") {
      element.setAttribute("preserveAspectRatio", preserveAspectRatio);
      onAttributeChange();
    }
    setPreserveAspectRatio(value);
  };

  const convertTextToForeignObject = () => {
    if (!element) return;

    const textContent = element.textContent || "";
    const computedStyle = getComputedStyle(element);
    const { x, y, width, height } = (element as SVGGraphicsElement).getBBox();
    const foreignObject = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "foreignObject"
    );

    foreignObject.setAttribute("x", x.toString());
    foreignObject.setAttribute("y", y.toString());
    foreignObject.setAttribute("width", width.toString());
    foreignObject.setAttribute("height", height.toString());

    const div = document.createElement("div");
    div.setAttribute("xmlns", "http://www.w3.org/1999/xhtml");
    div.style.width = "100%";
    div.style.height = "100%";
    div.style.display = "flex";
    div.style.alignItems = "center";

    div.style.fontFamily = computedStyle.fontFamily;
    div.style.fontSize = computedStyle.fontSize;
    div.style.fontWeight = computedStyle.fontWeight;
    div.style.fontStyle = computedStyle.fontStyle;
    div.style.letterSpacing = computedStyle.letterSpacing;
    div.style.fill = computedStyle.fill;
    div.style.color = computedStyle.fill;
    div.style.textAnchor = computedStyle.textAnchor;
    div.style.justifyContent = computedStyle.textAnchor;
    div.style.textAlign = computedStyle.textAnchor;
    div.style.lineHeight = "normal";
    div.style.textOverflow = "ellipsis";
    div.innerHTML = textContent;

    if (computedStyle.textAnchor === "middle") {
      div.style.justifyContent = "center";
      div.style.textAlign = "center";
    }

    foreignObject.appendChild(div);
    element.parentNode?.replaceChild(foreignObject, element);

    onAttributeChange();
  };

  const convertShapeToImage = () => {
    if (!element) return;

    const style = getComputedStyle(element);
    const fill = style.fill;
    var href: string | null = null;

    if (fill.startsWith("url(") && fill.endsWith(")")) {
      const patternId = fill.slice(5, -2);
      const patternElement = document.querySelector(patternId);

      if (
        patternElement &&
        patternElement.tagName.toLowerCase() === "pattern"
      ) {
        const imageElement = patternElement.querySelector("image");
        if (imageElement) {
          href =
            imageElement.getAttributeNS(
              "http://www.w3.org/1999/xlink",
              "href"
            ) || imageElement.getAttribute("href");
        }

        if (!href) {
          const useElement = patternElement.querySelector("use");
          if (useElement) {
            const useHref =
              useElement.getAttributeNS(
                "http://www.w3.org/1999/xlink",
                "href"
              ) || useElement.getAttribute("href");
            if (useHref && useHref.startsWith("#")) {
              const referencedElement = document.querySelector(useHref);
              if (
                referencedElement &&
                referencedElement.tagName.toLowerCase() === "image"
              ) {
                href =
                  referencedElement.getAttributeNS(
                    "http://www.w3.org/1999/xlink",
                    "href"
                  ) || referencedElement.getAttribute("href");
              }
            }
          }
        }
        patternElement.parentNode?.removeChild(patternElement);
      }
    } else if (/^rgb\(\d+,\s*\d+,\s*\d+\)$/.test(fill)) {
      const { width, height } = (element as SVGGraphicsElement).getBBox();
      const canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      const context = canvas.getContext("2d");
      if (context) {
        context.fillStyle = fill;
        context.fillRect(0, 0, width, height);
        href = canvas.toDataURL("image/png");
      }
    }

    const { x, y, width, height } = (element as SVGGraphicsElement).getBBox();
    const svgNs = "http://www.w3.org/2000/svg";
    const image = document.createElementNS(svgNs, "image");

    image.setAttribute("x", x.toString());
    image.setAttribute("y", y.toString());
    image.setAttribute("width", width.toString());
    image.setAttribute("height", height.toString());
    image.setAttribute("preserveAspectRatio", "xMidYMid meet");

    if (href) {
      image.setAttribute("href", href);
    }

    element.parentNode?.replaceChild(image, element);

    onAttributeChange();
  };

  const centerHorizontally = () => {
    if (!element || !element.ownerSVGElement?.parentElement) return;
    const { width } = (element as SVGGraphicsElement).getBBox();
    const newX = (1080 - width) / 2;
    element.setAttribute("x", newX.toString());
    onAttributeChange();
  };

  const convertPathToImage = () => {
    if (!element) return;

    const pathElement = element as SVGPathElement;
    const parentElement = pathElement.parentNode as SVGElement | null;

    if (!parentElement) return;

    const svgNs = "http://www.w3.org/2000/svg";
    const { x, y, width, height } = pathElement.getBBox();
    const pathData = pathElement.getAttribute("d");
    const imageElement = document.createElementNS(svgNs, "image");
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    const context = canvas.getContext("2d");
    let href = null;

    if (context) {
      context.fillStyle = "#dfdfdf";
      context.fillRect(0, 0, width, height);
      href = canvas.toDataURL("image/png");
    }

    imageElement.setAttribute("x", x.toString());
    imageElement.setAttribute("y", y.toString());
    imageElement.setAttribute("width", width.toString());
    imageElement.setAttribute("height", height.toString());
    imageElement.setAttribute("preserveAspectRatio", "xMidYMid slice");

    if (href) {
      imageElement.setAttribute("href", href);
    }

    let defs = document.querySelector("defs");
    if (!defs) {
      defs = document.createElementNS(svgNs, "defs");
      parentElement.insertBefore(defs, parentElement.firstChild);
    }

    if (pathData) {
      const clipPathId = uuidv4();
      const clipPath = document.createElementNS(svgNs, "clipPath");
      clipPath.setAttribute("id", clipPathId);

      const clipPathContent = document.createElementNS(svgNs, "path");
      clipPathContent.setAttribute("d", pathData);
      clipPath.appendChild(clipPathContent);

      defs.appendChild(clipPath);

      imageElement.setAttribute("clip-path", `url(#${clipPathId})`);
    }

    parentElement.replaceChild(imageElement, pathElement);
  };

  useEffect(() => {
    if (svgStateCounter && svgStateCounter !== stateCounter) {
      onAttributeChange();
    }
    setSVGStateCounter(stateCounter);
  }, [stateCounter, svgStateCounter, onAttributeChange]);

  if (!element) {
    return (
      <Text as="p" size="1">
        No element selected
      </Text>
    );
  }

  return (
    <Flex direction="column" gap="8px">
      {element.tagName.toLowerCase() === "text" && (
        <Card>
          <Flex direction="column" gap="2">
            <Heading size={"2"}>Text</Heading>
            <Button onClick={() => convertTextToForeignObject()}>
              Convert to ForeignObject
            </Button>
          </Flex>
        </Card>
      )}
      {["rect", "path", "image"].includes(element.tagName.toLowerCase()) && (
        <Card>
          <Flex direction="column" gap="3">
            <Heading size={"3"}>Image</Heading>
            {["rect", "path"].includes(element.tagName.toLowerCase()) && (
              <Button onClick={() => convertShapeToImage()}>
                Convert to Image
              </Button>
            )}
            {element.tagName.toLowerCase() === "path" && (
              <Button onClick={() => convertPathToImage()}>
                Convert Path to Masked Image
              </Button>
            )}
            {element.tagName.toLowerCase() === "image" && (
              <Flex direction={"column"} gap="1">
                <Heading size={"1"}>Content Rendering</Heading>
                <Select.Root
                  value={preserveAspectRatio}
                  onValueChange={onPreserveAspectRatioSelection}
                >
                  <Select.Trigger>{preserveAspectRatio}</Select.Trigger>
                  <Select.Content>
                    <Select.Item value="none">none</Select.Item>
                    <Select.Item value="xMinYMin meet">
                      xMinYMin meet
                    </Select.Item>
                    <Select.Item value="xMidYMin meet">
                      xMidYMin meet
                    </Select.Item>
                    <Select.Item value="xMaxYMin meet">
                      xMaxYMin meet
                    </Select.Item>
                    <Select.Item value="xMinYMid meet">
                      xMinYMid meet
                    </Select.Item>
                    <Select.Item value="xMidYMid meet">
                      xMidYMid meet
                    </Select.Item>
                    <Select.Item value="xMaxYMid meet">
                      xMaxYMid meet
                    </Select.Item>
                    <Select.Item value="xMinYMax meet">
                      xMinYMax meet
                    </Select.Item>
                    <Select.Item value="xMidYMax meet">
                      xMidYMax meet
                    </Select.Item>
                    <Select.Item value="xMaxYMax meet">
                      xMaxYMax meet
                    </Select.Item>
                    <Select.Item value="xMinYMin slice">
                      xMinYMin slice
                    </Select.Item>
                    <Select.Item value="xMidYMin slice">
                      xMidYMin slice
                    </Select.Item>
                    <Select.Item value="xMaxYMin slice">
                      xMaxYMin slice
                    </Select.Item>
                    <Select.Item value="xMinYMid slice">
                      xMinYMid slice
                    </Select.Item>
                    <Select.Item value="xMidYMid slice">
                      xMidYMid slice
                    </Select.Item>
                    <Select.Item value="xMaxYMid slice">
                      xMaxYMid slice
                    </Select.Item>
                    <Select.Item value="xMinYMax slice">
                      xMinYMax slice
                    </Select.Item>
                    <Select.Item value="xMidYMax slice">
                      xMidYMax slice
                    </Select.Item>
                    <Select.Item value="xMaxYMax slice">
                      xMaxYMax slice
                    </Select.Item>
                  </Select.Content>
                </Select.Root>
              </Flex>
            )}
          </Flex>
        </Card>
      )}
      <Card>
        <Flex direction="column" gap="3">
          <Heading size={"3"}>Layout</Heading>
          <Button onClick={centerHorizontally}>Center Horizontally</Button>
        </Flex>
      </Card>
    </Flex>
  );
};

export default InternAdMediaTemplateVariantSVGElementToolkitTab;
