import { CampaignWizardCardProps } from "../../../../components/campaign/wizard/cards/CampaignWizardCard";
import { CheckboxWithQuantityProps } from "../../../../components/campaign/wizard/inputs/CheckboxWithQuantity";
import {
  ChannelType,
  useCampaignWizardDispatch,
  useCampaignWizardState,
} from "../../../../contexts/CampaignWizardContext";
import { CampaignType, ItemSetType } from "@openapi";
import { MailIcon } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import MetaLogo from "~/components/logos/MetaLogo";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useCampaignRecommendationQuery from "~/hooks/campaign/useCampaignRecommendationQuery";
import useMetaAdAccountsQuery from "~/hooks/meta/useMetaAdAccountsQuery";

export const NewCampaignChannelCountDefaults: Record<
  CampaignType,
  {
    [key in ChannelType]: number;
  }
> = {
  [CampaignType.inventory_clearance]: {
    [ChannelType.EMAIL]: 2,
    [ChannelType.META_ADS]: 3,
  },
  [CampaignType.new_products]: {
    [ChannelType.EMAIL]: 2,
    [ChannelType.META_ADS]: 2,
  },
  [CampaignType.sales_event]: {
    [ChannelType.EMAIL]: 3,
    [ChannelType.META_ADS]: 3,
  },
  [CampaignType.other]: {
    [ChannelType.EMAIL]: 1,
    [ChannelType.META_ADS]: 1,
  },
};

export const RecommendedCampaignChannelCountDefaults: Record<
  ItemSetType,
  {
    [key in ChannelType]: number;
  }
> = {
  [ItemSetType.best_sellers]: {
    [ChannelType.EMAIL]: 1,
    [ChannelType.META_ADS]: 3,
  },
  [ItemSetType.discounted_products]: {
    [ChannelType.EMAIL]: 1,
    [ChannelType.META_ADS]: 3,
  },
  [ItemSetType.new_products]: {
    [ChannelType.EMAIL]: 2,
    [ChannelType.META_ADS]: 3,
  },
  [ItemSetType.new_hero_product]: {
    [ChannelType.EMAIL]: 1,
    [ChannelType.META_ADS]: 2,
  },
  [ItemSetType.new_collection]: {
    [ChannelType.EMAIL]: 2,
    [ChannelType.META_ADS]: 3,
  },
  [ItemSetType.purchased_together]: {
    [ChannelType.EMAIL]: 1,
    [ChannelType.META_ADS]: 3,
  },
  [ItemSetType.slower_moving_goods]: {
    [ChannelType.EMAIL]: 1,
    [ChannelType.META_ADS]: 3,
  },
  [ItemSetType.slowest_moving_goods]: {
    [ChannelType.EMAIL]: 2,
    [ChannelType.META_ADS]: 3,
  },
};

const useCampaignChannelProps = () => {
  const activeBrandID = useActiveBrandID();
  const { recommendationID } = useParams();
  const dispatch = useCampaignWizardDispatch();
  const {
    wizardType,
    campaignData,
    isWizardStateInitialized,
    isShowingMetaConnect,
  } = useCampaignWizardState();
  const campaignType = campaignData?.campaign_type;
  const { adAccounts, isLoading: isLoadingMeta } =
    useMetaAdAccountsQuery(activeBrandID);
  const isMetaConnected = (adAccounts?.length ?? 0) > 0;
  const [showsMetaConnect, setShowsMetaConnect] =
    useState(isShowingMetaConnect);

  const { campaignRecommendation } = useCampaignRecommendationQuery(
    activeBrandID,
    recommendationID ?? null
  );

  useEffect(() => {
    if (!isWizardStateInitialized) {
      return;
    }
    setShowsMetaConnect(isShowingMetaConnect);
  }, [isWizardStateInitialized]);

  const initialCounts: { checked: boolean; quantity: number }[] =
    useMemo(() => {
      if (!isWizardStateInitialized) {
        return [
          { checked: false, quantity: 0 },
          { checked: false, quantity: 0 },
        ];
      }
      if (!!campaignData.channels) {
        return [
          {
            checked: campaignData.channels.emails > 0,
            quantity: campaignData.channels.emails,
          },
          {
            checked: campaignData.channels.meta_ads > 0,
            quantity: campaignData.channels.meta_ads,
          },
        ];
      }
      return Object.values(ChannelType).map((c: ChannelType) => {
        const count =
          wizardType === "NEW"
            ? NewCampaignChannelCountDefaults[campaignType as CampaignType][c]
            : RecommendedCampaignChannelCountDefaults[
                (campaignRecommendation?.item_set_type as ItemSetType) ??
                  ItemSetType.best_sellers
              ][c];
        return {
          checked: true,
          quantity: count,
        };
      });
    }, [
      campaignType,
      campaignRecommendation,
      wizardType,
      isWizardStateInitialized,
    ]);

  const [checkboxValues, setCheckboxValues] = useState(initialCounts);

  useEffect(() => {
    if (!isWizardStateInitialized) {
      return;
    }
    setCheckboxValues(initialCounts);
    dispatch({
      type: "UPDATE_CAMPAIGN_DATA",
      payload: {
        channels: {
          emails: initialCounts[0].checked ? initialCounts[0].quantity : 0,
          meta_ads: initialCounts[1].checked ? initialCounts[1].quantity : 0,
          sms: 0,
          push_notifications: 0,
        },
      },
    });
  }, [
    campaignType,
    initialCounts,
    dispatch,
    checkboxValues,
    isWizardStateInitialized,
  ]);

  const cardProps = useMemo<Omit<CampaignWizardCardProps, "children">>(
    () => ({
      badgeTitle: "Channel",
      nextButtonProps: {
        disabled:
          isLoadingMeta &&
          checkboxValues[1].checked &&
          checkboxValues[1].quantity > 0,
        onClick: () => {
          if (
            !isMetaConnected &&
            checkboxValues[1].checked &&
            checkboxValues[1].quantity > 0
          ) {
            dispatch({
              type: "RESET_REQUIRED_STEP",
            });
            setShowsMetaConnect(true);
            return;
          }
          dispatch({
            type: "INCREMENT_STEP",
          });
        },
      },
    }),
    [isMetaConnected, isLoadingMeta, checkboxValues, dispatch]
  );

  const checkboxProps = useMemo<CheckboxWithQuantityProps[]>(
    () =>
      Object.values(ChannelType).map((c, i) => {
        return {
          checked: checkboxValues[i].checked,
          setChecked: (checked) => {
            if (!checked) {
              setCheckboxValues((prev) => {
                const newValues = [...prev];
                newValues[i].checked = checked;
                newValues[i].quantity = 0;
                return newValues;
              });
            } else {
              setCheckboxValues((prev) => {
                const newValues = [...prev];
                newValues[i].checked = checked;
                newValues[i].quantity =
                  NewCampaignChannelCountDefaults[campaignType as CampaignType][
                    c
                  ];
                return newValues;
              });
            }
          },
          quantity: checkboxValues[i].quantity,
          setQuantity: (quantity) => {
            setCheckboxValues((prev) => {
              const newValues = [...prev];
              newValues[i].quantity = quantity;
              return newValues;
            });
          },
          label: c,
          icon:
            c === ChannelType.EMAIL ? (
              <MailIcon />
            ) : (
              <MetaLogo color="rgb(92, 88, 88)" size={24} />
            ),
        };
      }),
    [checkboxValues, campaignType]
  );

  const metaProps = useMemo(() => {
    return {
      showsMetaConnect,
      setShowsMetaConnect,
    };
  }, [showsMetaConnect, setShowsMetaConnect]);

  return { cardProps, checkboxProps, campaignRecommendation, metaProps };
};

export default useCampaignChannelProps;
