import StyleLibraryCardContent from "../StyleLibraryCardContent";
import BrandAssets from "./BrandAssets";
import { ImageAssetCategory } from "@openapi";
import { PlusIcon } from "lucide-react";
import { createRef } from "react";
import { DropzoneRef } from "react-dropzone";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import { useBrandStyle } from "~/contexts/BrandStylingContext";

const BrandAssetsCardSection = () => {
  const { data: brandStyle, isLoading } = useBrandStyle();
  const dropzoneRef = createRef<DropzoneRef>();

  const assets = brandStyle?.background_images ?? [];

  const triggerUpload = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  return (
    <StyleLibraryCardContent
      title="Background Images"
      isLoading={isLoading}
      actions={
        <AppButton variant="ghost" size={"3"} onClick={triggerUpload}>
          <PlusIcon
            style={{
              padding: "0.1rem",
            }}
          />
          {!!assets.length ? "Add" : "Upload"} Image(s)
        </AppButton>
      }
    >
      <BrandAssets
        assets={assets}
        category={ImageAssetCategory.background_image}
        canDelete
        ref={dropzoneRef}
      />
    </StyleLibraryCardContent>
  );
};

export default BrandAssetsCardSection;
