import DraperText from "../core/DraperText";
import AppButton from "../core/buttons/AppButton/AppButton";
import BrandButton from "../core/buttons/BrandButton";
import ActionCard from "../core/cards/ActionCard";
import {
  Text,
  Flex,
  Grid,
  Skeleton,
  Button,
  Box,
  ScrollArea,
} from "@radix-ui/themes";
import { Check, X } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import ConfettiExplosion from "react-confetti-explosion";
import { useNavigate } from "react-router-dom";
import { useBrandStyle } from "~/contexts/BrandStylingContext";
import {
  useActiveBrandID,
  useAuthenticatedUserState,
} from "~/contexts/CurrentUserContext";
import useBrandQuery from "~/hooks/brand/useBrandQuery";
import useBrandUpdateMutation from "~/hooks/brand/useBrandUpdateMutation";
import { HOME_ROUTE } from "~/routes/constants";

enum GridStep {
  LOGO = 0,
  COLOR_PALETTE = 1,
  TYPOGRAPHY = 2,
  BUTTON = 3,
  FIREWORKS = 4,
}

const LogoLoading = () => {
  return <Skeleton height={"64px"} width={"128px"} />;
};

const ColorLoading = () => {
  return (
    <Flex gap="4">
      <Skeleton height={"48px"} width={"48px"} loading={true} />
      <Skeleton height={"48px"} width={"48px"} loading={true} />
      <Skeleton height={"48px"} width={"48px"} loading={true} />
    </Flex>
  );
};

const TypographyLoading = () => {
  return (
    <Flex gap="4" direction={"column"} align={"start"}>
      <Skeleton height={"20px"} width={"80px"} loading={true} />
      <Skeleton height={"10px"} width={"240px"} loading={true} />
      <Skeleton height={"10px"} width={"240px"} loading={true} />
    </Flex>
  );
};

const ButtonLoading = () => {
  return (
    <Flex gap="4" direction={"row"} align={"center"}>
      <Button disabled={true}>Button</Button>
      <Button disabled={true}>Button</Button>
    </Flex>
  );
};

const GridChild = ({
  type,
  isActive,
}: {
  type: GridStep;
  isActive: boolean;
}) => {
  const {
    activeBrand: { id: activeBrandID },
  } = useAuthenticatedUserState();

  const { data } = useBrandStyle();
  const { brandData } = useBrandQuery(activeBrandID);

  let loading = null;
  let text = null;
  let content = null;

  switch (type) {
    case GridStep.LOGO:
      loading = <LogoLoading />;
      text = "Logo";
      if (data?.logos?.[0]?.url) {
        content = (
          <div
            style={{
              backgroundImage: `url(${data.logos[0].url})`,
              width: "60%",
              height: "60%",
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              borderRadius: "var(--radius-3)",
              alignContent: "center",
              overflow: "hidden",
            }}
          />
        );
      }
      break;
    case GridStep.COLOR_PALETTE:
      text = "Colors";
      loading = <ColorLoading />;
      if (data?.color_palette_set?.primary) {
        content = (
          <Grid columns={"3"} rows={"2"} gap={"4"}>
            <Box
              style={{
                width: "36px",
                height: "36px",
                borderRadius: "6px",
                border: "2px solid #00000014",
                backgroundColor: data?.color_palette_set?.primary.background,
              }}
            />
            <Box
              style={{
                width: "36px",
                height: "36px",
                borderRadius: "6px",
                border: "2px solid #00000014",
                backgroundColor: data?.color_palette_set?.primary.foreground,
              }}
            />
            <Box
              style={{
                width: "36px",
                height: "36px",
                borderRadius: "6px",
                border: "2px solid #00000014",
                backgroundColor: data?.color_palette_set?.primary.accent,
              }}
            />
          </Grid>
        );
      }
      break;
    case GridStep.TYPOGRAPHY:
      text = "Typography";
      loading = <TypographyLoading />;
      if (data?.typography) {
        content = (
          <Flex direction={"column"} gap="4" p="2">
            <DraperText
              clamp={1}
              size={"6"}
              style={{
                fontFamily: data.typography.header_font_family?.name,
                fontWeight: data.typography.header_font_family?.font_weight,
                color: "var(--text-secondary)",
              }}
            >
              {brandData?.name}
            </DraperText>
            <DraperText
              clamp={3}
              size={"3"}
              style={{
                fontFamily: data.typography.paragraph_font_family?.name,
                fontWeight: data.typography.paragraph_font_family?.font_weight,
                color: "var(--text-secondary)",
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </DraperText>
          </Flex>
        );
      }
      break;
    case GridStep.BUTTON:
      text = "Buttons";
      loading = <ButtonLoading />;
      if (data?.button_styles && data.button_styles.length > 0) {
        content = (
          <Grid
            columns={data.button_styles.length === 1 ? "1" : "2"}
            gap={"4"}
            align={"center"}
            justify={"center"}
          >
            {data.button_styles.slice(0, 4).map((style) => {
              return (
                <BrandButton
                  brandButtonStyle={style}
                  style={{
                    margin: "4px",
                    textAlign: "center",
                  }}
                  key={style.id}
                >
                  Style
                </BrandButton>
              );
            })}
          </Grid>
        );
      }
      break;
    default:
      break;
  }

  const checkOrX = useMemo(() => {
    if (!brandData) return null;

    if (content === null) {
      return (
        <X
          color="white"
          size={18}
          style={{
            backgroundColor: "red",
            borderRadius: "50%",
            padding: "1px",
          }}
        />
      );
    }
    return (
      <Check
        color="white"
        size={18}
        style={{
          backgroundColor: "green",
          borderRadius: "50%",
          padding: "1px",
        }}
      />
    );
  }, [content, brandData]);

  return (
    <Flex
      align={"center"}
      justify={"center"}
      gap="4"
      height={"250px"}
      style={{
        backgroundColor: "white",
      }}
      position={"relative"}
      p={"4"}
    >
      <Text
        size={"1"}
        style={{
          position: "absolute",
          top: "16px",
          left: "16px",
        }}
      >
        {text}
      </Text>
      {isActive && (
        <Text
          size={"1"}
          style={{
            position: "absolute",
            top: "16px",
            right: "16px",
          }}
        >
          {checkOrX}
        </Text>
      )}
      {isActive && content ? content : loading}
    </Flex>
  );
};

const OnboardingStyleLibraryLoading = ({ onNext }: { onNext: () => void }) => {
  const [activeStep, setActiveStep] = useState(GridStep.LOGO);
  const [isExploding, setIsExploding] = useState(false);
  const { data, isLoading } = useBrandStyle();
  const navigate = useNavigate();

  const activeBrandID = useActiveBrandID();
  const { updateBrand, isLoading: isUpdatingBrand } = useBrandUpdateMutation({
    brandID: activeBrandID,
    onSuccess: () => {
      localStorage.removeItem("draper_onboarding_step");
      navigate(HOME_ROUTE);
    },
  });

  const logo = data?.logos?.[0]?.url ?? null;
  const color_palette_set = data?.color_palette_set;
  const typography = data?.typography ?? null;
  const buttons = data?.button_styles ?? [];

  const isStylingComplete =
    logo !== null &&
    color_palette_set !== null &&
    typography !== null &&
    buttons.length > 0;
  const isLastStep = activeStep === GridStep.FIREWORKS;

  // set 1 second interval
  useEffect(() => {
    if (isLoading) return;
    const interval = setInterval(() => {
      if (activeStep + 1 <= GridStep.FIREWORKS) {
        setActiveStep(activeStep + 1);
      } else {
        clearInterval(interval);
        if (isStylingComplete) setIsExploding(true);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [activeStep, isStylingComplete, isLoading]);

  const actionButtons = useMemo(() => {
    if (!isLastStep) {
      return (
        <Skeleton loading>
          <AppButton variant="outlined">Edit Assets</AppButton>
        </Skeleton>
      );
    }
    if (isStylingComplete) {
      return (
        <>
          <AppButton variant="outlined" onClick={onNext}>
            Edit Assets
          </AppButton>
          <AppButton
            variant="dark"
            onClick={() => {
              updateBrand({
                is_onboarded: true,
              });
            }}
            disabled={isUpdatingBrand}
            loading={isUpdatingBrand}
          >
            Finish
          </AppButton>
        </>
      );
    }

    return (
      <AppButton variant="dark" onClick={onNext}>
        Resolve Missing Assets
      </AppButton>
    );
  }, [isLastStep, isStylingComplete, isUpdatingBrand]);

  let chatMessageText = "Getting your brand library ready...";
  if (isLastStep) {
    if (isStylingComplete) {
      chatMessageText = "Looks like everything is in order.";
    } else {
      chatMessageText =
        "We couldn't get all the assets. Let's fix the missing ones.";
    }
  }
  return (
    <>
      <Flex
        position={"fixed"}
        direction={"column"}
        justify={"center"}
        align={"center"}
        width={"100dvw"}
        height={"100dvh"}
      >
        {isExploding && (
          <ConfettiExplosion
            duration={3000}
            force={0.8}
            width={1600}
            particleCount={250}
            zIndex={1}
            onComplete={() => setIsExploding(false)}
          />
        )}
      </Flex>
      <ScrollArea>
        <Flex
          direction={"column"}
          justify={"center"}
          align={"center"}
          gap="6"
          style={{
            boxSizing: "border-box",
            zIndex: 1000,
            height: "100%",
          }}
        >
          <ActionCard
            title="Style Library"
            subtitle={chatMessageText}
            overflowY="hidden"
          >
            <Flex direction={"row"} gap="4" align={"center"} justify={"end"}>
              {actionButtons}
            </Flex>
            <Flex
              direction={"column"}
              justify={"center"}
              align={"center"}
              gap="6"
              mx="calc(var(--action-card-xb-padding) * -1)"
              mb="calc(var(--action-card-xb-padding) * -1)"
              style={{
                zIndex: 1000,
              }}
            >
              <Grid
                columns={"2"}
                rows={"2"}
                gap="1px"
                style={{
                  background: "var(--border-primary)",
                  border: "1px solid var(--border-primary)",
                  borderWidth: "1px 0",
                  width: "100%",
                }}
              >
                {[
                  GridStep.LOGO,
                  GridStep.COLOR_PALETTE,
                  GridStep.TYPOGRAPHY,
                  GridStep.BUTTON,
                ].map((type) => (
                  <GridChild
                    type={type}
                    isActive={type < activeStep}
                    key={type}
                  />
                ))}
              </Grid>
            </Flex>
          </ActionCard>
        </Flex>
      </ScrollArea>
    </>
  );
};

export default OnboardingStyleLibraryLoading;
