import { AdMediaAspectRatio } from "@openapi";
import { AdMediaUpdateableElement } from "~/components/ads/AdMediaContext";
import { AdMediaTemplateProductGroupElementSchema } from "~/types/ads";

export const getNumericAdMediaAspectRatio = (
  ratio: AdMediaAspectRatio
): number => {
  switch (ratio) {
    case AdMediaAspectRatio.full_screen_vertical:
      return 16.0 / 9.0;
    case AdMediaAspectRatio.landscape:
      return 1.0 / 1.91;
    case AdMediaAspectRatio.portrait:
      return 5.0 / 4.0;
    case AdMediaAspectRatio.square:
      return 1.0 / 1.0;
    default:
      throw new Error("Invalid AdMediaAspectRatio");
  }
};

export const getProductImageElement = (
  element: Extract<AdMediaUpdateableElement, { type: "product_group" }>
) => {
  return element.elements.find(
    (el): el is Extract<typeof el, { type: "image" }> =>
      el.type === "image" && el.image_type === "product"
  );
};

export const getProductTitleElement = (
  element: Extract<AdMediaUpdateableElement, { type: "product_group" }>
) => {
  return element.elements.find(
    (el): el is Extract<typeof el, { type: "text" }> =>
      el.type === "text" && el.text_type === "product_title"
  );
};
