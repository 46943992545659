import { operations } from "../../../openapi";
import { useDraperApiPostMutation } from "~/utils/useDraperMutation";

type RegenerateEmailResponse =
  operations["emails_api_regenerate_email"]["responses"][200]["content"]["application/json"];

type RegenerateEmailRequestData =
  operations["emails_api_regenerate_email"]["requestBody"]["content"]["application/json"];

const useEmailRegenerateMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (data: RegenerateEmailResponse) => void;
  onError?: (error: Error) => void;
}) => {
  const regenerateEmail = useDraperApiPostMutation<
    RegenerateEmailResponse,
    RegenerateEmailRequestData
  >({
    mutationKey: ["email-regenerate-email"],
    path: `/emails/regenerate-email`,
    onError: (error) => {
      onError?.(error);
    },
    onSuccess: (data) => {
      onSuccess?.(data);
    },
  });

  return {
    regenerateEmail: regenerateEmail.mutate,
    isLoading: regenerateEmail.isPending,
  };
};

export default useEmailRegenerateMutation;
