import { FontSize } from "./TipTapFontSizeExtension";
import BoldExtension, { isBold } from "./tip-tap/BoldExtension";
import HighlightExtension from "./tip-tap/HighlightExtension";
import ItalicExtension, { isItalic } from "./tip-tap/ItalicExtension";
import StrikethroughExtension, {
  isStrikethrough,
} from "./tip-tap/StrikethroughExtension";
import UnderlineExtension, { isUnderline } from "./tip-tap/UnderlineExtension";
import { Theme } from "@radix-ui/themes";
import Color from "@tiptap/extension-color";
import Document from "@tiptap/extension-document";
import Font from "@tiptap/extension-font-family";
import Link from "@tiptap/extension-link";
import TextStyle from "@tiptap/extension-text-style";
import { useEditor, EditorContent, mergeAttributes } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { debounce } from "lodash";
import {
  Bold,
  Italic,
  Strikethrough,
  Baseline,
  Highlighter as HighlighterIcon,
  Underline as UnderlineIcon,
  Link as LinkIcon,
  List,
  ListOrdered,
} from "lucide-react";
import { useEffect, useState, useRef, useCallback } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import ColorPicker from "~/components/core/inputs/ColorPicker";
import LinkInput from "~/components/core/inputs/LinkInput";
import NumberInput from "~/components/core/inputs/NumberInput";
import BrandFontSelect from "~/components/style-library/typography/BrandFontSelect/BrandFontSelect";
import { BrandStylingProvider } from "~/contexts/BrandStylingContext";
import {
  useEmailState,
  useSetActiveTipTapID,
  useUpdateNestedObjectById,
} from "~/routes/intern/email_editor/context/EmailEditorContext";

export const CustomLink = Link.extend({
  addAttributes() {
    return {
      ...this.parent?.(), // Retain existing attributes like `href`, `target`, `rel`
      style: {
        default: null,
        parseHTML: (element) => element.getAttribute("style") || null,
        renderHTML: (attributes) => {
          if (!attributes.style) {
            return {};
          }

          return {
            style: attributes.style,
          };
        },
      },
      class: {
        default: null,
        parseHTML: (element) => element.getAttribute("class") || null,
        renderHTML: (attributes) => {
          if (!attributes.class) {
            return {};
          }

          return {
            class: attributes.class,
          };
        },
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "a",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
});

const CustomDocument = Document.extend({
  content: "inline*", // This allows inline content like spans at the root level
});

const extensions = [
  StarterKit.configure({
    bold: false,
    italic: false,
    strike: false,
  }),
  CustomDocument,
  BoldExtension,
  ItalicExtension,
  StrikethroughExtension,
  UnderlineExtension,
  HighlightExtension,
  TextStyle,
  Color,
  CustomLink.configure({
    openOnClick: false,
    autolink: true,
    defaultProtocol: "https",
  }),
  Font,
  FontSize,
];

export const ToolbarButton = styled.button<{
  $isSelected: boolean;
  width?: string;
  height?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem; /* text-sm */
  font-weight: 500; /* font-medium */
  color: ${(props) =>
    props.$isSelected ? "#1f2937" : "#6b7280"}; /* text-neutral-500 */
  border-radius: 6px;
  border-width: 1px;
  background-color: ${(props) =>
    props.$isSelected ? "rgba(0,0,0,0.1)" : "white"};
  border-color: transparent;
  white-space: nowrap;
  opacity: 1;
  height: ${(props) => props.height || "40px"};
  width: ${(props) => props.width || "40px"};
  padding: ${(props) => (props.width ? "0 0.50rem" : "0")};
  cursor: pointer;

  &:hover {
    background-color: ${(props) =>
      props.$isSelected
        ? "rgba(0,0,0,0.1)"
        : "#f5f5f5"}; /* hover:bg-neutral-100 */
    color: ${(props) =>
      props.$isSelected ? "#1f2937" : "#6b7280"}; /* hover:text-neutral-800 */
  }
`;

const Toolbar = styled.div`
  position: fixed;
  color: black;
  display: inline-flex;
  line-height: 1; /* Adjusts leading-none */
  gap: 0.5rem; /* 0.5rem (gap-0.5) */
  flex-direction: row;
  padding: 0.5rem; /* 1rem (p-1) */
  align-items: center;
  background-color: white;
  border-radius: 0.5rem; /* rounded-lg */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); /* shadow-sm */
  border: 1px solid #e5e7eb; /* border-neutral-200 */
`;

const EditorContainer = styled(EditorContent)`
  .ProseMirror:focus {
    outline: none;
  }
`;

const Tiptap = () => {
  const { activeTipTapID, selectedSectionId } = useEmailState();
  const updateNestedObjectById = useUpdateNestedObjectById();
  const setActiveTipTapID = useSetActiveTipTapID();

  const toolbarRef = useRef<HTMLDivElement>(null);

  const editor = useEditor({
    extensions,
    onBlur: ({ editor, event, transaction }) => {
      if (
        toolbarRef.current &&
        toolbarRef.current.contains(event.relatedTarget as Node)
      ) {
        // Prevent blurring when clicking on toolbar
        event.preventDefault();
        return;
      }
      if (activeTipTapID && selectedSectionId) {
        setActiveTipTapID("");
        setEditorElement(null);

        updateNestedObjectById({
          objectId: activeTipTapID.split(":")[1],
          field: "text",
          value: editor.getHTML(),
        });
      }
    },
  });

  const [editorElement, setEditorElement] = useState<HTMLElement | null>(null);

  const updateEditorPosition = useCallback(() => {
    if (!toolbarRef.current || !editorElement) return;

    const { top, left, width } = editorElement.getBoundingClientRect();
    const newTop = top - 1.5 * toolbarRef.current.offsetHeight;
    toolbarRef.current.style.display = "inline-flex";
    toolbarRef.current.style.top = `${newTop < 0 ? 0 : newTop}px`; // Align top of editor with top of target
    const editorWidth = toolbarRef.current.offsetWidth;
    const leftOffset = (editorWidth - width) / 2;

    toolbarRef.current.style.left = `${left - leftOffset}px`; // Center horizontally

    const fadeThreshold = 80; // Start fading when the editor is 50px from the top
    const opacity = Math.max(0, Math.min(1, newTop / fadeThreshold));
    toolbarRef.current.style.opacity = opacity.toString();
  }, [toolbarRef, editorElement]);

  const handleScroll = useCallback(() => {
    requestAnimationFrame(updateEditorPosition);
  }, [updateEditorPosition]);

  const debouncedUpdatePosition = useCallback(
    debounce(updateEditorPosition, 100),
    [updateEditorPosition]
  );

  useEffect(() => {
    const editorPreview = document.getElementById("email-editor-preview");
    const resizeObserver = new ResizeObserver(() => {
      requestAnimationFrame(updateEditorPosition);
    });
    if (editorPreview) {
      editorPreview.addEventListener("scroll", handleScroll);
      resizeObserver.observe(editorPreview);
    }
    return () => {
      if (editorPreview) {
        editorPreview.removeEventListener("scroll", handleScroll);
      }
      resizeObserver.disconnect();
    };
  }, [handleScroll]);

  useEffect(() => {
    if (editor) {
      editor.on("update", debouncedUpdatePosition);
    }
    return () => {
      if (editor) {
        editor.off("update", debouncedUpdatePosition);
      }
    };
  }, [editor, debouncedUpdatePosition]);

  useEffect(() => {
    if (activeTipTapID) {
      updateEditorPosition();
    }
  }, [activeTipTapID, updateEditorPosition]);

  useEffect(() => {
    if (!editor || !activeTipTapID) {
      setEditorElement(null);
      toolbarRef.current && (toolbarRef.current.style.visibility = "hidden");
      return;
    }
    console.log(activeTipTapID);

    const targetElement = document.getElementById(activeTipTapID);
    if (!targetElement) return;

    targetElement.dataset.originalDisplay = targetElement.style.display;

    const newEditorID = `editor:${activeTipTapID}`;
    let newEditorElement = document.getElementById(newEditorID);
    if (!newEditorElement) {
      newEditorElement = targetElement.cloneNode() as HTMLElement;
      newEditorElement.id = newEditorID;
      targetElement.parentNode?.insertBefore(
        newEditorElement,
        targetElement.nextSibling
      );
    }
    newEditorElement.style.display =
      newEditorElement.dataset.originalDisplay ?? "";
    targetElement.style.display = "none";

    setEditorElement(newEditorElement);

    if (toolbarRef.current) {
      console.log("setting toolbar to visible");
      toolbarRef.current.style.visibility = "visible";
    }

    // Set editor content and focus
    editor.commands.setContent(targetElement.innerHTML);
    editor.commands.focus("all");
    editor.setEditable(true);

    // Cleanup function
    return () => {
      if (targetElement) {
        targetElement.style.display =
          editorElement?.dataset.originalDisplay || "";
      }
      if (toolbarRef.current) {
        toolbarRef.current.style.visibility = "hidden";
      }
      editorElement && (editorElement.style.display = "none");
    };
  }, [editor, activeTipTapID, editorElement]);

  if (!editor) return null;

  const toolbar = createPortal(
    <Theme>
      <BrandStylingProvider>
        <Toolbar ref={toolbarRef}>
          <NumberInput
            radius="large"
            value={editor
              .getAttributes("textStyle")
              ?.fontSize?.replace("px", "")}
            style={{
              maxWidth: "74px",
              overflow: "hidden",
            }}
            onValueChange={(value) => {
              editor
                .chain()
                .setFontSize(value + "px")
                .run();
            }}
          />
          <BrandFontSelect
            loadSelectedGoogleFont
            size={"3"}
            value={editor.getAttributes("textStyle")?.fontFamily}
            onChange={(value) => {
              editor.chain().setFontFamily(value.name).run();
            }}
            style={{
              maxWidth: "140px",
            }}
          />
          <ToolbarButton
            onClick={() => {
              if (isBold(editor)) {
                editor.chain().focus().unsetBold().run();
              } else {
                editor.chain().focus().setBold().run();
              }
            }}
            $isSelected={isBold(editor)}
          >
            <Bold size={16} />
          </ToolbarButton>
          <ToolbarButton
            onClick={() => {
              if (isItalic(editor)) {
                editor.chain().focus().unsetItalic().run();
              } else {
                editor.chain().focus().setItalic().run();
              }
            }}
            $isSelected={isItalic(editor)}
          >
            <Italic size={16} />
          </ToolbarButton>
          <ToolbarButton
            onClick={() => {
              if (isStrikethrough(editor)) {
                editor.chain().focus().unsetStrikethrough().run();
              } else {
                editor.chain().focus().setStrikethrough().run();
              }
            }}
            $isSelected={isStrikethrough(editor)}
          >
            <Strikethrough size={16} />
          </ToolbarButton>
          <ToolbarButton
            onClick={() => {
              if (isUnderline(editor)) {
                editor.chain().focus().unsetUnderline().run();
              } else {
                editor.chain().focus().setUnderline().run();
              }
            }}
            $isSelected={isUnderline(editor)}
          >
            <UnderlineIcon size={16} />
          </ToolbarButton>
          <ColorPicker
            align="start"
            onChange={(color) => {
              editor.chain().setColor(color).run();
            }}
            onSaveColor={(color) => {
              editor.chain().focus().setColor(color).run();
            }}
          >
            <ToolbarButton
              $isSelected={!!editor.getAttributes("textStyle")?.color}
            >
              <Baseline size={16} />
            </ToolbarButton>
          </ColorPicker>
          <ColorPicker
            align="start"
            onChange={(color) => {
              editor.chain().setHighlight(color).run();
            }}
            onSaveColor={(_) => {
              editor.chain().focus().run();
            }}
          >
            <ToolbarButton
              $isSelected={!!editor.getAttributes("textStyle")?.highlight}
            >
              <HighlighterIcon size={16} />
            </ToolbarButton>
          </ColorPicker>
          <LinkInput
            defaultUrl={editor.getAttributes("link")?.href}
            onSaveLink={(url) => {
              if (url) {
                editor.chain().focus().setLink({ href: url }).run();
              } else {
                editor.chain().focus().unsetLink().run();
              }
            }}
          >
            <ToolbarButton $isSelected={editor.isActive("link")}>
              <LinkIcon size={16} />
            </ToolbarButton>
          </LinkInput>
          <ToolbarButton
            $isSelected={editor.isActive("bulletList")}
            onClick={() => editor.chain().focus().toggleBulletList().run()}
          >
            <List size={16} />
          </ToolbarButton>
          <ToolbarButton
            $isSelected={editor.isActive("orderedList")}
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
          >
            <ListOrdered size={16} />
          </ToolbarButton>
        </Toolbar>
      </BrandStylingProvider>
    </Theme>,
    document.getElementById("root") as HTMLElement
  );

  return (
    <div>
      {editorElement &&
        createPortal(<EditorContainer editor={editor} />, editorElement)}
      {toolbar}
    </div>
  );
};

export default Tiptap;
