import ModifierContainer from "./ModifierContainer";
import { Flex } from "@radix-ui/themes";
import ColorBox from "~/components/style-library/color-palette/ColorBox";
import {
  ColorPaletteSchema,
  useUpdateSectionField,
} from "~/routes/intern/email_editor/context/EmailEditorContext";

const BackgroundModifier = ({
  sectionId,
  palette,
  hideSwitch = false,
}: {
  sectionId: string;
  palette: ColorPaletteSchema;
  hideSwitch?: boolean;
}) => {
  const updateSection = useUpdateSectionField();

  return (
    <ModifierContainer title="Palette" hideSwitch={hideSwitch}>
      <Flex gap="16px" wrap="wrap">
        <ColorBox
          color={palette.background}
          style={{ flexDirection: "row-reverse", paddingLeft: "8px" }}
          onSaveColor={(color) => {
            updateSection({
              sectionId: sectionId,
              field: "palette.background",
              value: color,
            });
          }}
          label="Background"
        />
        <ColorBox
          color={palette.foreground}
          style={{ flexDirection: "row-reverse", paddingLeft: "8px" }}
          onSaveColor={(color) => {
            updateSection({
              sectionId: sectionId,
              field: "palette.foreground",
              value: color,
            });
          }}
          label="Foreground"
        />
        <ColorBox
          color={palette.accent}
          style={{ flexDirection: "row-reverse", paddingLeft: "8px" }}
          onSaveColor={(color) => {
            updateSection({
              sectionId: sectionId,
              field: "palette.accent",
              value: color,
            });
          }}
          label="Accent"
        />
      </Flex>
    </ModifierContainer>
  );
};

export default BackgroundModifier;
