import AppButton from "../buttons/AppButton/AppButton";
import { Flex, Text } from "@radix-ui/themes";
import { CropIcon, ImageIcon } from "lucide-react";
import { useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import CropImageDialog from "~/components/editor/dialogs/CropImageDialog";
import useGetImageDimensionsAndSize from "~/hooks/common/useGetImageDimensionsAndSize";
import { dataURIToFile } from "~/utils/fileUtils";
import { getFilenameFromUrl } from "~/utils/helpers";

const TextLine = styled(Text)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 230px;
  color: #888282;
  font-size: 14px;
`;

const ImagePreview = styled.img`
  max-width: 100px;
  max-height: 100px;
  padding: 16px;
  border: 1px dashed #ddd7d7;
`;

interface ImageSelectorProps {
  value?: string | File;
  onUpload: (file: File) => void;
  alt?: string;
  placeholderImage?: string;
  noImageText?: string;
  buttonText?: string;
  onCrop?: (file: File) => void;
}

const ImageSelector: React.FC<ImageSelectorProps> = ({
  value,
  onUpload,
  alt,
  placeholderImage,
  noImageText = "No Image set",
  buttonText = "Change",
  onCrop,
}) => {
  const [cropOpen, setCropOpen] = useState(false);

  const imageUrl = useMemo(() => {
    if (!value) {
      return;
    }
    if (typeof value === "string") {
      return value;
    }
    return URL.createObjectURL(value);
  }, [value]);
  const dimensions = useGetImageDimensionsAndSize(imageUrl);
  const fileName = imageUrl ? getFilenameFromUrl(imageUrl) : undefined;

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/svg+xml": [],
      "image/gif": [],
    },
    onDrop: (acceptedFiles) => {
      onUpload(acceptedFiles[0]);
    },
    maxFiles: 1,
  });

  const handleCrop = (data: string) => {
    const file = dataURIToFile(
      data,
      fileName ? `${fileName}.png` : "cropped-image.png"
    );
    onCrop?.(file);
  };

  return (
    <>
      <Flex gap="16px">
        <ImagePreview src={imageUrl ?? placeholderImage} alt={alt ?? "logo"} />
        <Flex direction="column" gap="12px">
          <TextLine>
            {imageUrl ? fileName ?? "" : noImageText ?? "No Image set"}
          </TextLine>
          <TextLine>{dimensions}</TextLine>
        </Flex>
      </Flex>
      <Flex gap="3" justify="between">
        <AppButton
          {...getRootProps()}
          variant="outlined"
          style={{
            flex: 1,
          }}
        >
          <ImageIcon />
          {buttonText}
        </AppButton>
        <input {...getInputProps()} />
        {onCrop && (
          <>
            <AppButton
              variant="outlined"
              disabled={!imageUrl}
              style={{ flex: 1 }}
              onClick={() => setCropOpen(true)}
            >
              <CropIcon />
              Crop
            </AppButton>
            {imageUrl && (
              <CropImageDialog
                open={cropOpen}
                onOpenChange={setCropOpen}
                img={imageUrl}
                onCrop={handleCrop}
              />
            )}
          </>
        )}
      </Flex>
    </>
  );
};

export default ImageSelector;
