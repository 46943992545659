import AppButton from "../core/buttons/AppButton/AppButton";
import TextFieldWithLabel from "../core/inputs/TextFieldWithLabel";
import { Flex, Text } from "@radix-ui/themes";
import _ from "lodash";
import { MailIcon, Trash2Icon } from "lucide-react";
import React, { useMemo } from "react";
import styled from "styled-components";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useBrandUsersQuery, {
  BrandUsersResponse,
} from "~/hooks/brand/useBrandUsersQuery";

const Button = styled(AppButton)`
  width: max-content;
  margin-left: auto;
`;

const TableHead = styled.th`
  text-align: left;
  color: var(--text-tertiary);
  font-weight: 400;
  font-size: 14px;
  border-top: 1px solid rgba(240, 240, 240, 1);
  border-bottom: 1px solid rgba(240, 240, 240, 1);
  background-color: rgba(252, 251, 250, 1);
  padding-top: 12px;
  padding-bottom: 12px;
  &:first-child {
    padding-left: 32px;
  }
  &:last-child {
    padding-right: 32px;
  }
`;

const TableCol = styled.td`
  padding-top: 12px;
  &:first-child {
    padding-left: 32px;
  }
  &:last-child {
    padding-right: 32px;
  }
`;

function userStatus(user: BrandUsersResponse[number]) {
  if (user.is_active) {
    return _.capitalize(user.role);
  }
  return "Invite Sent";
}

const AccountSettingsPermissions = () => {
  const activeBrandID = useActiveBrandID();
  const { data: users } = useBrandUsersQuery(activeBrandID);
  const activeAdmins = useMemo(() => {
    return users?.filter((user) => user.role === "admin" && user.is_active);
  }, [users]);

  return (
    <Flex gap="32px" direction="column">
      <Flex direction="column" gap="24px" px="32px" pt="32px">
        <Text weight="medium">Invite Users</Text>
        <TextFieldWithLabel
          label="Email Address"
          type="email"
          size="3"
          radius="large"
        />
        <Button radius="large">
          <MailIcon /> Send Invite
        </Button>
      </Flex>
      <table>
        <thead>
          <tr>
            <TableHead>Users</TableHead>
            <TableHead>Role/Status</TableHead>
            <TableHead style={{ width: 1 }}></TableHead>
          </tr>
        </thead>
        <tbody>
          {users?.map((user) => (
            <tr key={user.id}>
              <TableCol>
                <Flex direction="column">
                  <Text size="3">{`${user.first_name} ${user.last_name}`}</Text>
                  <Text size="2">{user.email}</Text>
                </Flex>
              </TableCol>
              <TableCol>{userStatus(user)}</TableCol>
              <TableCol>
                <AppButton
                  variant="outlined"
                  size="3"
                  radius="large"
                  disabled={
                    user.is_brand_admin || (activeAdmins?.length ?? 0) <= 1
                  }
                >
                  <Trash2Icon /> Remove
                </AppButton>
              </TableCol>
            </tr>
          ))}
        </tbody>
      </table>
    </Flex>
  );
};

export default AccountSettingsPermissions;
