import AppButton from "../buttons/AppButton/AppButton";
import CloseIconButton from "../buttons/CloseIconButton";
import { Dialog, Flex } from "@radix-ui/themes";
import React from "react";

type ContentProps = React.ComponentProps<typeof Dialog.Content>;

const Modal = ({
  open,
  onOpenChange,
  title,
  children,
  hideFooter,
  submitBtnText = "Submit",
  cancelBtnText = "Cancel",
  hideFooterBorder,
  onCancel,
  onClose,
  onSubmit,
  isSubmitDisabled,
  maxWidth = "816px",
  ...props
}: ContentProps & {
  open: boolean;
  onOpenChange?: (open: boolean) => void;
  title: string;
  children: React.ReactNode;
  hideFooter?: boolean;
  submitBtnText?: string;
  cancelBtnText?: string;
  onCancel?: () => void;
  onClose?: () => void;
  onSubmit?: () => void;
  isSubmitDisabled?: boolean;
  hideFooterBorder?: boolean;
  maxWidth?: string;
}) => {
  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Content maxWidth={maxWidth} style={{ padding: 0 }} {...props}>
        <Flex
          justify="between"
          align="center"
          p="16px"
          pb="12px"
          style={{ borderBottom: "1px solid #E2E2E2" }}
        >
          <Dialog.Title size="4" weight="medium" mb="0">
            {title}
          </Dialog.Title>
          <Dialog.Close>
            <CloseIconButton onClick={onClose} />
          </Dialog.Close>
        </Flex>
        {children}
        {!hideFooter && (
          <Flex
            p="24px"
            justify="end"
            style={{
              borderTop: hideFooterBorder ? "none" : "1px solid #DDD7D7",
            }}
          >
            <Flex gap="3">
              <Dialog.Close>
                <AppButton
                  variant="outlined"
                  size="3"
                  radius="large"
                  onClick={onCancel}
                >
                  {cancelBtnText}
                </AppButton>
              </Dialog.Close>
              <Dialog.Close>
                <AppButton
                  onClick={onSubmit}
                  variant="dark"
                  size="3"
                  radius="large"
                  disabled={isSubmitDisabled}
                >
                  {submitBtnText}
                </AppButton>
              </Dialog.Close>
            </Flex>
          </Flex>
        )}
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default Modal;
