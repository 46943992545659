import { CampaignType } from "../../../../../openapi";
import { CampaignWizardCardProps } from "../../../../components/campaign/wizard/cards/CampaignWizardCard";
import { useCampaignWizardDispatch } from "../../../../contexts/CampaignWizardContext";
import { BadgePercentIcon, PackageOpenIcon, SparkleIcon } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

export type CampaignTypeUnion = keyof typeof CampaignType;

export const campaignTypeButtonText: Record<
  CampaignTypeUnion,
  {
    text: string;
    icon: React.ReactNode;
  }
> = {
  new_products: {
    text: "New Products",
    icon: <SparkleIcon />,
  },
  sales_event: {
    text: "Sales Event",
    icon: <BadgePercentIcon />,
  },
  inventory_clearance: {
    text: "Inventory Clearance",
    icon: <PackageOpenIcon />,
  },
  other: {
    text: "Other",
    icon: <></>,
  },
};

const useCampaignTypeProps = () => {
  const location = useLocation().state;
  const dispatch = useCampaignWizardDispatch();
  const [campaignType, setCampaignType] = useState<CampaignTypeUnion | null>(
    location?.campaignType ?? null
  );

  const cardProps = useMemo<Omit<CampaignWizardCardProps, "children">>(
    () => ({
      badgeTitle: "Campaign Type",
      backButtonProps: {
        hide: true,
      },
      nextButtonProps: {
        text: "Continue",
        disabled: campaignType === null,
      },
    }),
    [campaignType]
  );

  useEffect(() => {
    if (campaignType) {
      dispatch({
        type: "UPDATE_CAMPAIGN_DATA",
        payload: {
          campaign_type: campaignType as CampaignType,
        },
      });
    }
  }, [campaignType, dispatch]);

  return {
    cardProps,
    setCampaignType,
    campaignType,
  };
};

export default useCampaignTypeProps;
