import InternAdCreativeInfoTab from "./InternAdCreativeInfoTab";
import {
  Box,
  Button,
  Card,
  Flex,
  Heading,
  Spinner,
  Text,
} from "@radix-ui/themes";
import { ArrowLeft, ChevronRight } from "lucide-react";
import { useParams, useNavigate } from "react-router-dom";
import useGetAdCreativeWithMedia from "~/hooks/ads/useGetAdCreativeWithMedia";

const InternAdCreativeComponent = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const {
    creativeQuery: { data: creative, isLoading: creativeIsLoading },
    mediaQuery: { data: media },
  } = useGetAdCreativeWithMedia(id);

  if (creativeIsLoading || !id) {
    return (
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spinner loading={true} />
      </Box>
    );
  }

  return (
    <Flex align="start" gap="0.5rem">
      <Card
        style={{
          width: "380px",
          flexGrow: 0,
          flexShrink: 0,
          padding: "16px",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#fff",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Flex direction="column" gap="0.5rem">
          <Flex align="center" gap="0.5rem" justify="between">
            <Flex align="center" gap="0.5rem">
              <Button
                variant="ghost"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ArrowLeft></ArrowLeft>
              </Button>
              <Heading size="4">Ad Creative</Heading>
            </Flex>
          </Flex>
          <InternAdCreativeInfoTab creative={creative ?? null} />
        </Flex>
      </Card>
      <Card
        style={{
          flexGrow: 1,
          flexShrink: 1,
          padding: "16px",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#fff",
          minHeight: "350px",
        }}
      >
        <Flex direction="column">
          <Flex
            direction="row"
            justify="between"
            align="center"
            style={{ marginBottom: "20px" }}
          >
            <Heading size="4">Ad Media</Heading>
          </Flex>
          <Flex direction="row">
            {media?.media.map((media, index) => {
              return (
                <Flex
                  justify="between"
                  style={{
                    cursor: "pointer",
                    borderBottom: "solid",
                    borderColor: "#dfdfdf",
                    borderWidth: "1px",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    width: "100%",
                  }}
                  onClick={() => navigate(`/ad/${id}`)}
                >
                  <Flex direction="column" gap="4px">
                    <Heading size="2">Media</Heading>
                    <Text size="1">ID: {media.id}</Text>
                    <Text size="1">Created: {media.created_at}</Text>
                    <Text size="1">Type: {media.type}</Text>
                  </Flex>
                  <Flex direction="column" gap="4px">
                    <Heading size="2">Template</Heading>
                    <Text size="1">ID: {media.template.id}</Text>
                    <Text size="1">Name: {media.template.name}</Text>
                  </Flex>
                  <Flex justify="end" gap="1em" align="center">
                    {media.variants.map((variant) => (
                      <img
                        alt={`Media variant for ${variant.aspect_ratio}`}
                        key={variant.id}
                        src={variant.preview_image ?? ""}
                        style={{
                          height: "60px",
                          borderRadius: "8px",
                        }}
                      />
                    ))}
                    <ChevronRight />
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
        </Flex>
      </Card>
    </Flex>
  );
};

export default InternAdCreativeComponent;
