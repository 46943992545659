import ModifierContainer from "./ModifierContainer";
import { Flex } from "@radix-ui/themes";
import { PencilIcon } from "lucide-react";
import { useState } from "react";
import styled from "styled-components";
import ProductSelectorDialog from "~/components/campaign/wizard/products/components/productSelector/ProductSelectorDialog";
import { Product } from "~/components/campaign/wizard/products/components/productSelector/hooks/useGetFilteredProducts";
import DraperButton from "~/components/core/buttons/DraperButton";

const ProductInfo = styled(Flex)`
  flex-direction: column;
  flex-grow: 1;
  margin-left: 12px;
`;

const FeaturedProductModifier = ({
  onChangeProduct,
}: {
  onChangeProduct: (product: Product) => void;
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  return (
    <ModifierContainer title="Featured Product" checked={true} hideSwitch>
      <ProductSelectorDialog
        isOpen={isDialogOpen}
        onOpenChange={setIsDialogOpen}
        onSelectSingleProduct={(product) => {
          onChangeProduct(product);
          setIsDialogOpen(false);
        }}
        triggerComponent={
          <DraperButton
            variant="outlined"
            text="Change Product"
            icon={<PencilIcon />}
            onClick={() => setIsDialogOpen(true)}
          />
        }
      />
    </ModifierContainer>
  );
};

export default FeaturedProductModifier;
