import { Extension } from "@tiptap/core";
import "@tiptap/extension-text-style";
import { Editor } from "@tiptap/react";

export const isUnderline = (editor: Editor) => {
  const textDecoration =
    editor.getAttributes("textStyle")?.textDecoration || "";
  return textDecoration.includes("underline");
};

export type UnderlineOptions = {
  /**
   * The types where underline can be applied
   * @default ['textStyle']
   * @example ['heading', 'paragraph']
   */
  types: string[];
};

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    underline: {
      /**
       * Set the text to underline
       * @example editor.commands.setUnderline()
       */
      setUnderline: () => ReturnType;

      toggleUnderline: () => ReturnType;

      /**
       * Unset the underline mark
       * @example editor.commands.unsetUnderline()
       */
      unsetUnderline: () => ReturnType;
    };
  }
}

/**
 * This extension allows you to use underline text.
 * @see https://tiptap.dev/api/marks/underline
 */
const Underline = Extension.create<UnderlineOptions>({
  name: "underline",

  addOptions() {
    return {
      types: ["textStyle"],
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          textDecoration: {
            default: null,
            parseHTML: (element) => element.style.textDecoration || null,
            renderHTML: (attributes) => {
              if (!attributes.textDecoration) {
                return {};
              }

              return {
                style: `text-decoration: ${attributes.textDecoration}`,
              };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setUnderline:
        () =>
        ({ chain, editor }) => {
          const currentDecoration =
            editor.getAttributes("textStyle").textDecoration || "";
          if (currentDecoration.includes("underline")) {
            return chain().run();
          }
          const decorations = currentDecoration.split(" ");
          decorations.push("underline");
          return chain()
            .setMark("textStyle", { textDecoration: decorations.join(" ") })
            .run();
        },
      unsetUnderline:
        () =>
        ({ chain, editor }) => {
          const currentDecoration =
            editor.getAttributes("textStyle").textDecoration || "";
          const decorations = currentDecoration.includes("underline")
            ? currentDecoration.replace("underline", "").trim()
            : currentDecoration;
          return chain()
            .setMark("textStyle", {
              textDecoration: decorations || null,
            })
            .removeEmptyTextStyle()
            .run();
        },
    };
  },
});

export default Underline;
