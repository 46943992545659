import KinLogo from "@components/core/KinLogo";
import { Box, Flex } from "@radix-ui/themes";
import { useState } from "react";
import BackgroundGradient from "~/components/core/layout/BackgroundGradient";
import OnboardingIntegrationContainer from "~/components/onboarding/OnboardingIntegrationContainer";
import OnboardingStyleLibraryContainer from "~/components/onboarding/OnboardingStyleLibraryContainer";
import OnboardingVideo from "~/components/onboarding/OnboardingVideo";
import useDraperStep from "~/components/onboarding/hooks/useDraperStep";

const ONBOARDING_STEP_VARIABLE = "draper_onboarding_step";

enum OnboardingStep {
  INTRO = 0,
  INTEGRATION = 1,
  STYLE_LIBRARY = 2,
}

function getStepContent(
  step: OnboardingStep,
  onNext: () => void,
  onPrev: () => void
) {
  let content = null;
  switch (step) {
    case OnboardingStep.INTRO:
      content = <OnboardingVideo onNext={onNext} />;
      break;
    case OnboardingStep.INTEGRATION:
      content = (
        <OnboardingIntegrationContainer onNextOnboardingStep={onNext} />
      );
      break;
    case OnboardingStep.STYLE_LIBRARY:
      content = <OnboardingStyleLibraryContainer />;
      break;
    default:
      throw Error("Onboarding step not implemented");
  }

  return content;
}

const OnboardingRoute = () => {
  const { step, setStep, onNext, onPrev } = useDraperStep(
    ONBOARDING_STEP_VARIABLE
  );

  return (
    <Flex
      width={"100dvw"}
      height={"100dvh"}
      justify={"center"}
      direction={"column"}
      overflowX={"hidden"}
    >
      <BackgroundGradient />
      <Box position={"fixed"} top={"4"} left={"4"}>
        <KinLogo />
      </Box>
      {getStepContent(
        step,
        () => {
          setStep(step + 1);
          localStorage.setItem("draper_onboarding_step", (step + 1).toString());
        },
        () => {
          setStep(step - 1);
        }
      )}
    </Flex>
  );
};

export default OnboardingRoute;
