import classes from "./BrandAssets.module.css";
import { ImageAssetSchema } from "./BrandImageAsset";
import { Dialog, Heading } from "@radix-ui/themes";
import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import Cookies from "js-cookie";
import { useCallback, useState } from "react";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import DialogActions from "~/components/core/dialog/DialogActions";
import DialogHeader from "~/components/core/dialog/DialogHeader";
import { useBrandStylingDispatch } from "~/contexts/BrandStylingContext";

interface BrandAssetDeleteDialogProps {
  title: string;
  asset: ImageAssetSchema;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onAssetDeleted: (asset: ImageAssetSchema) => void;
}

const BrandAssetDeleteDialog: React.FC<BrandAssetDeleteDialogProps> = ({
  title,
  asset,
  isOpen,
  setIsOpen,
  onAssetDeleted,
}) => {
  const filename = asset.original_filename;
  const [deleteError, setDeleteError] = useState<string | null>(null);
  const brandStylingDispatch = useBrandStylingDispatch();

  const { isPending, mutate: deleteImageAsset } = useMutation<null, AxiosError>(
    {
      mutationFn: async () => {
        setDeleteError(null);
        const { data } = await axios.delete(`/api/v1/media/image/${asset.id}`, {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        });
        return data;
      },
      onSuccess: () => {
        brandStylingDispatch({
          type: "DELETE_IMAGE_ASSET",
          payload: asset,
        });
        onAssetDeleted(asset);
      },
      onError: (error) => {
        console.error("Error deleting image:", error);
        var errorMessage: string =
          "An error occurred while deleting the image.";
        if (error.response?.status == 403) {
          errorMessage = "You do not have permission to delete this image.";
        } else if (error.response?.status == 404) {
          errorMessage = "The image was not found.";
          brandStylingDispatch({
            type: "SET_IS_DIRTY",
            payload: true,
          });
        }
        setDeleteError(errorMessage);
      },
    }
  );

  const handleDelete = useCallback(() => {
    deleteImageAsset();
  }, [asset, onAssetDeleted]);

  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Content
        style={{
          maxWidth: "460px",
        }}
        onInteractOutside={(e) => {
          if (isPending) {
            e.preventDefault();
          }
        }}
      >
        <DialogHeader title={title} disabled={isPending} />
        <Dialog.Description
          style={{
            display: "flex",
            gap: "1rem",
            padding: "1rem",
          }}
        >
          <span
            className={classes.asset}
            style={{ backgroundImage: `url(${asset.url})` }}
          ></span>
          <span>
            Are you sure you want to delete <strong>{filename}</strong>?
          </span>
        </Dialog.Description>
        <DialogActions>
          <Dialog.Close>
            <AppButton variant="outlined" radius="large" disabled={isPending}>
              Cancel
            </AppButton>
          </Dialog.Close>
          <AppButton radius="large" onClick={handleDelete} disabled={isPending}>
            Delete Image
          </AppButton>
        </DialogActions>
        {deleteError && (
          <Heading mt="2" size="2" color="red" align={"right"}>
            {deleteError}
          </Heading>
        )}
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default BrandAssetDeleteDialog;
