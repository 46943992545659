import HomeSection from "../../HomeSection";
import useGetCampaignRecommendations from "../../hooks/recommendations/useGetCampaignRecommendations";
import CampaignIdeasCard from "./CampaignIdeasCard";
import { Grid } from "@radix-ui/themes";

const CampaignIdeasSection = ({}: {}) => {
  const { data, isLoading } = useGetCampaignRecommendations({
    page: 1,
  });

  if (isLoading) {
    return null;
  }

  if (data?.pages.flatMap((page) => page.campaign_recommendations).length === 0)
    return null;

  const content = (
    <Grid columns={{ initial: "1", md: "2", lg: "2" }} gap="6">
      {data?.pages.flatMap((page) =>
        page.campaign_recommendations.map((recommendation) => (
          <CampaignIdeasCard
            key={recommendation.id}
            recommendation={recommendation}
          />
        ))
      )}
    </Grid>
  );

  return <HomeSection title="Campaign Ideas" content={content} />;
};

export default CampaignIdeasSection;
