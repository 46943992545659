import ModifierContainer from "./ModifierContainer";
import { ImageAssetCategory } from "@openapi";
import { Flex, Grid, Text } from "@radix-ui/themes";
import { useQueryClient } from "@tanstack/react-query";
import { CropIcon, ImageIcon } from "lucide-react";
import { useState } from "react";
import styled from "styled-components";
import AssetSelectorDialog from "~/components/campaign/wizard/dialogs/AssetSelectorDialog";
import DraperText from "~/components/core/DraperText";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import CropImageDialog from "~/components/editor/dialogs/CropImageDialog";
import { ImageAssetSchema } from "~/components/style-library/assets/BrandImageAsset";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useGetImageDimensionsAndSize from "~/hooks/common/useGetImageDimensionsAndSize";
import { BrandStylingResponse } from "~/hooks/data/useBrandStyleQuery";
import useMediaBrandImagesUpload from "~/hooks/style-library/useMediaBrandImagesUpload";
import { dataURIToFile } from "~/utils/fileUtils";
import { getFilenameFromUrl } from "~/utils/helpers";

const LogoPreview = styled.img`
  max-width: 100px;
  max-height: 100px;
  padding: 16px;
  border: 1px dashed #ddd7d7;
`;

const ImageModifier = ({
  enabled,
  initialImageUrl,
  onSwitchToggle,
  onImageChange,
}: {
  enabled: boolean;
  initialImageUrl?: string;
  onSwitchToggle: (enabled: boolean) => void;
  onImageChange?: (image: ImageAssetSchema) => void;
}) => {
  const activeBrandID = useActiveBrandID();
  const queryClient = useQueryClient();

  const dimensions = useGetImageDimensionsAndSize(initialImageUrl);
  const fileName = getFilenameFromUrl(initialImageUrl);

  const [isChangeImageDialogOpen, setIsChangeImageDialogOpen] = useState(false);
  const [isCropImageDialogOpen, setIsCropImageDialogOpen] = useState(false);

  const { mutate: uploadImageAssets, isPending: isUploading } =
    useMediaBrandImagesUpload({
      data: {
        brandID: activeBrandID,
        category: ImageAssetCategory.asset,
      },
      onSuccess: (data) => {
        queryClient.setQueryData(
          ["brand-style", activeBrandID],
          (oldData: BrandStylingResponse) => ({
            ...oldData,
            image_assets: [...oldData.image_assets, ...data.new_assets],
          })
        );
        onImageChange?.(data.new_assets[0]);
      },
      onError: (error) => {
        // TODO
      },
    });
  const handleUpload = (file: File) => {
    uploadImageAssets({
      images: [file as unknown as string],
    });
  };

  let contents = null;

  if (!initialImageUrl) {
    contents = (
      <Flex direction="column" gap="8px" justify={"start"}>
        <Text size={"3"}>Featured Image (optional)</Text>
        <AppButton
          onClick={() => setIsChangeImageDialogOpen(true)}
          variant="outlined"
          size="3"
          radius="large"
        >
          <ImageIcon size={16} />
          Add Background Image
        </AppButton>
      </Flex>
    );
  } else {
    contents = (
      <ModifierContainer
        title="Background Image"
        checked={enabled}
        onSwitch={onSwitchToggle}
        disabled={isUploading}
        hideSwitch={true}
      >
        <Flex gap="16px">
          <LogoPreview src={initialImageUrl} alt="logo" />

          <Flex direction="column" gap="12px">
            <DraperText clamp={1} size="2" color="gray">
              {fileName}
            </DraperText>
            <DraperText clamp={1} size="2" color="gray">
              {dimensions}
            </DraperText>
          </Flex>
        </Flex>
        <Grid columns="2" gap="12px">
          <AppButton
            disabled={isUploading}
            variant="outlined"
            size="3"
            onClick={() => setIsChangeImageDialogOpen(true)}
          >
            <ImageIcon />
            Change
          </AppButton>
          <AppButton
            disabled={isUploading}
            variant="outlined"
            size="3"
            onClick={() => setIsCropImageDialogOpen(true)}
          >
            <CropIcon />
            Crop
          </AppButton>
        </Grid>
        <CropImageDialog
          open={isCropImageDialogOpen}
          onOpenChange={setIsCropImageDialogOpen}
          img={initialImageUrl}
          onCrop={(data: string) => {
            const file = dataURIToFile(data, fileName ?? "cropped-image.png");
            handleUpload(file);
          }}
        />
      </ModifierContainer>
    );
  }

  return (
    <>
      {contents}
      <AssetSelectorDialog
        isDialogOpen={isChangeImageDialogOpen}
        onToggleDialogOpen={setIsChangeImageDialogOpen}
        selectedAssets={[]}
        setSelectedAssets={(newImages) => {
          onImageChange?.(newImages[0]);
        }}
      />
    </>
  );
};

export default ImageModifier;
