import {
  Dialog,
  Flex,
  ScrollArea,
  SegmentedControl,
  Skeleton,
  Text,
} from "@radix-ui/themes";
import Handlebars from "handlebars";
import { MonitorIcon, SmartphoneIcon, XIcon } from "lucide-react";
import { useState } from "react";
import styled from "styled-components";
import DraperButton from "~/components/core/buttons/DraperButton";
import { useEmailState } from "~/routes/intern/email_editor/context/EmailEditorContext";

const SegmentedControlContainer = styled(SegmentedControl.Root)`
  height: 48px;
  background-color: #edebf0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  .rt-SegmentedControlIndicator::before {
    margin: 4px;
    border: 1px solid var(--border_primary, #dddddd);
  }
`;

const PreviewContainer = styled(ScrollArea)`
  height: calc(90dvh - 80px);
  background-color: #f0edeb;
`;

const MobilePreviewContainer = styled.div`
  width: 375px;
  height: 667px;
  margin: 20px auto;
  border: 10px solid #333;
  border-radius: 30px;
  overflow: hidden;
`;

const PreviewEmailDialog = ({
  open,
  onOpenChange,
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}) => {
  const [device, setDevice] = useState<"desktop" | "mobile">("desktop");
  const { sections, name } = useEmailState();
  const emailHtml = sections
    .map((section) => {
      if (section.type !== null && section.template.html) {
        const handlebarsTemplate = Handlebars.compile(section.template.html);
        return handlebarsTemplate(section);
      }
      return null;
    })
    .join("");

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Content
        size="4"
        maxWidth="95vw"
        maxHeight="90dvh"
        style={{ padding: 0, overflow: "hidden" }}
      >
        <Flex
          justify="between"
          align="center"
          p="16px"
          pb="12px"
          style={{ borderBottom: "1px solid #E2E2E2" }}
        >
          <Dialog.Title size="4" weight="medium" mb="0">
            {name}
          </Dialog.Title>
          <SegmentedControlContainer
            radius="large"
            style={{ backgroundColor: "#F0EDEB" }}
            size="3"
            value={device}
            onValueChange={(value) => setDevice(value as "desktop" | "mobile")}
          >
            <SegmentedControl.Item value="desktop">
              <Flex align="center" gap="8px">
                <MonitorIcon size={20} /> <Text>Desktop</Text>
              </Flex>
            </SegmentedControl.Item>
            <SegmentedControl.Item value="mobile">
              <Flex align="center" gap="8px">
                <SmartphoneIcon size={20} /> <Text>Mobile</Text>
              </Flex>
            </SegmentedControl.Item>
          </SegmentedControlContainer>
          <Dialog.Close>
            <DraperButton
              size="3"
              radius="large"
              text="Close"
              variant="outlined"
              icon={<XIcon />}
            ></DraperButton>
          </Dialog.Close>
        </Flex>
        <PreviewContainer>
          {device === "desktop" ? (
            <div
              style={{
                position: "relative",
              }}
              dangerouslySetInnerHTML={{
                __html: emailHtml,
              }}
            />
          ) : (
            <MobilePreviewContainer>
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  height: "100%",
                  overflow: "auto",
                }}
                dangerouslySetInnerHTML={{
                  __html: emailHtml,
                }}
              />
            </MobilePreviewContainer>
          )}
        </PreviewContainer>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default PreviewEmailDialog;
