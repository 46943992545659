import CampaignIdeasSection from "../components/home/components/campaignIdeasSection/CampaignIdeasSection";
import CampaignDraftSection from "../components/home/components/draftSection/CampaignDraftSection";
import RecommenderSection from "../components/home/components/recommenderSection/RecommenderSection";
import { Flex } from "@radix-ui/themes";
import BackgroundGradient from "~/components/core/layout/BackgroundGradient";
import BottomCTA from "~/components/home/components/BottomCTA";

export default function HomeRoute() {
  return (
    <Flex
      gap="7"
      direction="column"
      maxWidth="1248px"
      width="100%"
      style={{
        padding: "0px 24px 200px 24px",
        alignItems: "center",
        alignContent: "center",
        margin: "0 auto",
      }}
    >
      <BackgroundGradient />
      <RecommenderSection />
      <CampaignDraftSection />
      <CampaignIdeasSection />
      <BottomCTA />
    </Flex>
  );
}
