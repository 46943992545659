import { Dialog, Flex, Grid, IconButton, Text } from "@radix-ui/themes";
import { XIcon } from "lucide-react";
import React from "react";

const AccountSettingsDialog = ({
  trigger,
  children,
}: {
  trigger: React.ReactNode;
  children: React.ReactNode;
}) => {
  return (
    <Dialog.Root>
      <Dialog.Trigger>{trigger}</Dialog.Trigger>

      <Dialog.Content
        maxWidth="70vw"
        style={{ backgroundColor: "rgba(252, 251, 250, 1)", paddingBottom: 0 }}
      >
        <Flex mt="4px" mb="27px" justify="between" align="center">
          <Text size="6" weight="medium">
            My Account
          </Text>
          <Dialog.Close>
            <IconButton variant="ghost" radius="large">
              <XIcon color="var(--icon-primary)" />
            </IconButton>
          </Dialog.Close>
        </Flex>
        <Grid columns="1fr 2fr" gap="24px" height="70vh">
          {children}
        </Grid>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default AccountSettingsDialog;
