import AdPreview from "./Preview/AdPreview";
import { Flex, IconButton, Select, Spinner, Text } from "@radix-ui/themes";
import { ArrowLeftIcon, DownloadIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import styled from "styled-components";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import {
  AD_ZOOM_OPTIONS,
  AdEditorState,
  useAdEditorDispatch,
  useAdEditorState,
} from "~/contexts/AdEditorContext";
import useUpdateAdCreativeMutation from "~/hooks/ads/useUpdateAdCreativeMutation";

const FlexSide = styled(Flex)`
  flex: 1;
`;

const EditorTopBar = () => {
  const state = useAdEditorState();
  const adEditorDispatch = useAdEditorDispatch();
  const selectedAd =
    state.status === "loaded"
      ? state.availableAds[state.selectedAdIndex]
      : undefined;
  const navigate = useNavigate();

  const handleAdChange = (adId: string) => {
    if (state.status !== "loaded") {
      return;
    }
    const index = state.availableAds.findIndex((ad) => ad.data.id === adId);
    if (index >= state.availableAds.length || index < 0) {
      console.warn("Ad not found");
      return;
    }

    adEditorDispatch({
      type: "SELECT_AD_INDEX",
      payload: index,
    });
  };

  const { mutate: updateAdCreative, isPending: isSaving } =
    useUpdateAdCreativeMutation({
      onSuccess: () => {
        toast.success("Ad saved");
      },
      onError: (error) => {
        toast.error(`Failed to save ad: ${error}`);
      },
    });

  const isDisabled = state.status !== "loaded" || isSaving;

  const handleZoomChange = (zoom: string) => {
    const zoomNum = parseInt(zoom, 10);
    adEditorDispatch({
      type: "CHANGE_ZOOM",
      payload: zoomNum as AdEditorState["zoom"],
    });
  };

  const showDownloadButtons = () => {
    adEditorDispatch({
      type: "TOGGLE_SHOW_DOWNLOAD_BUTTONS",
    });
  };

  return (
    <Flex align="center" gap="4" width="100%" justify="between" py="3" px="4">
      <FlexSide align="center" gap="4" justify="start">
        <IconButton
          size="3"
          radius="full"
          variant="outline"
          color="gray"
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowLeftIcon />
        </IconButton>
        {state.status === "loaded" && (
          <Select.Root
            onValueChange={handleAdChange}
            value={selectedAd?.data.id ?? ""}
            size="3"
          >
            <Select.Trigger
              disabled={isDisabled}
              variant="ghost"
              color="gray"
            />
            <Select.Content highContrast>
              {state.availableAds?.map((ad) => (
                <Select.Item key={ad.data.id} value={ad.data.id}>
                  {ad.data.headline}
                </Select.Item>
              ))}
            </Select.Content>
          </Select.Root>
        )}
      </FlexSide>

      <Select.Root
        onValueChange={handleZoomChange}
        value={state.zoom.toString()}
        size="3"
      >
        <Select.Trigger
          disabled={isDisabled}
          variant="ghost"
          style={{
            border: "1px solid var(--border, #ddd7d7)",
            borderRadius: "12px",
            backgroundColor: "var(--background_white, #fff)",
          }}
        />
        <Select.Content highContrast>
          {AD_ZOOM_OPTIONS.map((zoom) => (
            <Select.Item key={zoom} value={zoom.toString()}>
              {zoom}%
            </Select.Item>
          ))}
        </Select.Content>
      </Select.Root>

      <FlexSide align="center" gap="3" justify="end">
        <IconButton
          size="4"
          radius="full"
          variant="outline"
          color={state.showDownloadButtons ? "amber" : "gray"}
          style={{}}
          onClick={showDownloadButtons}
        >
          <DownloadIcon />
        </IconButton>
        <AdPreview isDisabled={isDisabled} />
        <AppButton
          disabled={isDisabled || state.pendingChanges > 0}
          onClick={updateAdCreative}
        >
          {isSaving ? (
            <>
              <Spinner />
              Saving
            </>
          ) : (
            "Save"
          )}
        </AppButton>
      </FlexSide>
    </Flex>
  );
};

export default EditorTopBar;
