import {
  AdMediaTemplateCategoryStrings,
  AdMediaTypeStrings,
} from "../../../types/ads";
import InternAdMediaTemplateCreationDialog from "./InternAdMediaTemplateCreationDialog";
import InternAdMediaTemplateImportDialog from "./InternAdMediaTemplateImportDialog";
import { operations } from "@openapi";
import { Button, Container, Flex, Heading } from "@radix-ui/themes";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

type AdMediaTemplatesApiResponse =
  operations["ads_api_get_ad_media_templates"]["responses"][200]["content"]["application/json"];

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
`;

const Card = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 15px;
  cursor: pointer;
  transition: transform 0.2s;
  &:hover {
    transform: translateY(-5px);
  }
`;

const PreviewImage = styled.img`
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 6px;
  margin-bottom: 10px;
`;

const CardTitle = styled.h3`
  margin: 0;
  font-size: 1rem;
  color: #333;
`;

const CardSubtitle = styled.p`
  margin: 5px 0;
  font-size: 0.875rem;
  color: #666;
`;

const InternAdMediaTemplatesComponent = () => {
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [isImportDialogOpen, setIsImportDialogOpen] = useState(false);
  const navigate = useNavigate();

  const templatesQuery = useQuery<AdMediaTemplatesApiResponse>({
    queryKey: ["ad-media-templates"],
    queryFn: async (): Promise<AdMediaTemplatesApiResponse> => {
      const response = await axios.get("/api/v1/ads/ad-media-templates", {
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken") ?? "",
        },
      });
      return response.data;
    },
    retry: false,
    staleTime: Infinity,
  });

  const handleCardClick = (templateId: string) => {
    navigate(`/intern/ads/template/${templateId}`);
  };

  const handleTemplateMutation = () => {
    templatesQuery.refetch();
  };

  return (
    <Container>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "5px 0px 25px",
        }}
      >
        <Heading>Templates</Heading>
        <Flex gap="8px">
          <Button onClick={() => setIsCreateDialogOpen(true)}>+ Create</Button>
          <Button onClick={() => setIsImportDialogOpen(true)}>+ Import</Button>
        </Flex>
      </div>
      <CardGrid>
        {templatesQuery.data?.templates
          .slice()
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((template) => (
            <Card
              key={template.id}
              onClick={() => handleCardClick(template.id)}
            >
              {template.preview_image !== null && (
                <PreviewImage
                  src={template.preview_image}
                  alt={template.name}
                />
              )}
              <CardTitle>{template.name}</CardTitle>
              <CardSubtitle>
                Type: {AdMediaTypeStrings[template.type]}
              </CardSubtitle>
              <CardSubtitle>
                Category: {AdMediaTemplateCategoryStrings[template.category]}
              </CardSubtitle>
              <CardSubtitle>
                Promotional: {template.is_promotional ? "Yes" : "No"}
              </CardSubtitle>
            </Card>
          ))}
      </CardGrid>
      <InternAdMediaTemplateCreationDialog
        isDialogOpen={isCreateDialogOpen}
        setIsDialogOpen={setIsCreateDialogOpen}
        onCreate={handleTemplateMutation}
      />
      <InternAdMediaTemplateImportDialog
        isDialogOpen={isImportDialogOpen}
        setIsDialogOpen={setIsImportDialogOpen}
        onCreate={handleTemplateMutation}
      />
    </Container>
  );
};

export default InternAdMediaTemplatesComponent;
