import useGetEmailCreativeData from "../../../hooks/emails/useEmailCreativeQuery";
import {
  EmailEditorViews,
  EmailSection,
  useEmailDispatch,
  useEmailState,
} from "../../../routes/intern/email_editor/context/EmailEditorContext";
import { sectionTypeToEnum } from "../../../routes/intern/email_editor/context/stateUtils";
import useHandleHeroEditableText from "../../../routes/intern/email_editor/sections/hero/useHandleHeroEditableText";
import { EmailSectionType } from "@openapi";
import Handlebars from "handlebars";
import { Blocks } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { getHeroTextFieldsUpdatedHtml } from "~/routes/intern/email_editor/sections/hero/htmlUtils";

const useEmbeddedEmail = () => {
  //context
  const dispatch = useEmailDispatch();
  const { id, selectedSectionId, sections } = useEmailState();

  const [sectionsHtmlState, setSectionsHtmlState] = useState<
    Map<string, string>
  >(new Map());
  const handleSectionSelect = (sectionId: string, sectionType: string) => {
    dispatch({
      type: "SET_SELECTED_SECTION",
      payload: sectionId,
    });
    const enumValue = sectionTypeToEnum(sectionType);
    // dispatch({
    //   type: "SET_CURRENT_EDITOR_VIEW",
    //   payload: sectionType as EmailEditorViews,
    // });
  };
  console.log(sections);

  // useEffect(() => {
  //   if (emailCreativeData) {
  //     setSectionsHtmlState(
  //       emailCreativeData?.sections.reduce(
  //         (acc: Map<string, string>, section: EmailSection) => {
  //           if (section.type === EmailSectionType.hero) {
  //             acc.set(
  //               section.id,
  //               getHeroTextFieldsUpdatedHtml(section, emailCreativeData?.id) ??
  //                 ""
  //             );
  //           }
  //           return acc;
  //         },
  //         new Map()
  //       )
  //     );
  //   }
  // }, [emailCreativeData]);

  const renderedEmail = useMemo(() => {
    if (!sections) return null;

    return (sections ?? []).map((section: EmailSection) => {
      if (section.type === null) {
        return (
          <div style={{ width: "100%", height: "130px", position: "relative" }}>
            <div
              style={{
                position: "absolute",
                top: "24px",
                right: "24px",
                bottom: "24px",
                left: "24px",
                border: "2px dotted #ccc",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <Blocks size={24} />
                <div>Add Section</div>
              </div>
            </div>
          </div>
        );
      }
      var template = Handlebars.compile(section.template.html);
      var data = {
        ...section,
      };
      return (
        <div
          style={{
            border:
              selectedSectionId === section.id ? "3px solid grey" : "unset",
          }}
          onClick={(event) => {
            handleSectionSelect(section.id, section.type);
            event.stopPropagation();
          }}
          key={section.id}
          dangerouslySetInnerHTML={{
            __html: template(data),
          }}
          // dangerouslySetInnerHTML={{
          //   __html: getUpdatedSectionHtml(
          //     section,
          //     sectionsHtmlState.get(section.id) ?? section.html_template
          //   ),
          // }}
        />
      );
    });
  }, [sectionsHtmlState, sections, selectedSectionId]);

  //textEditor
  const heroTextEditors = useHandleHeroEditableText({
    renderedEmail: renderedEmail,
    sectionsState: sections,
    emailId: id ?? undefined,
  });

  return { embeddedEmail: renderedEmail, heroTextEditors: heroTextEditors };
};

export default useEmbeddedEmail;
