import PreviewEmailDialog from "../dialogs/PreviewEmailDialog";
import SendTestEmailDialog from "../dialogs/SendTestEmailDialog";
import useEmailCreativesQuery from "@hooks/emails/useEmailCreativesQuery";
import * as Popover from "@radix-ui/react-popover";
import {
  Box,
  Flex,
  IconButton,
  SegmentedControl,
  Select,
  Text,
  Theme,
} from "@radix-ui/themes";
import { useQueryClient } from "@tanstack/react-query";
import {
  ArrowLeftIcon,
  CheckIcon,
  ChevronDownIcon,
  MonitorIcon,
  SmartphoneIcon,
} from "lucide-react";
import { useState } from "react";
import { useBlocker, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import Modal from "~/components/core/dialog/Modal";
import useGetCampaignDetails, {
  getCampaignDetailsQueryKey,
} from "~/hooks/campaign/useGetCampaignDetails";
import { CAMPAIGNS_ROUTE } from "~/routes/constants";
import {
  useEmailState,
  useSetActiveEmailId,
  useSetDeviceView,
} from "~/routes/intern/email_editor/context/EmailEditorContext";
import useUpdateEmailCreative from "~/routes/intern/email_editor/hooks/useUpdateEmailCreative";

const Bar = styled(Flex)`
  padding: 12px 16px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  backdrop-filter: blur(25px);
  background-color: rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(10px);
`;

const CenteredElement = styled(Flex)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

const SelectTrigger = styled(Select.Trigger)`
  font-size: 18px;
  font-weight: 500;
`;

const PopoverContent = styled(Popover.Content)`
  background-color: white;
  border-radius: 8px;
  padding: 8px;
  width: 150px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd7d7;
`;

const MenuItem = styled(Flex)`
  cursor: pointer;
  padding: 4px;

  &:hover {
    background-color: #f0f0f0;
    border-radius: 8px;
  }
`;

const EmailEditorTopBar = () => {
  const { campaignId } = useParams();
  const queryClient = useQueryClient();
  const email = useEmailState();
  const setDeviceView = useSetDeviceView();
  const setActiveEmailId = useSetActiveEmailId();
  const navigate = useNavigate();
  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname
  );

  const [previewEmailDialogOpen, setPreviewEmailDialogOpen] = useState(false);
  const [isSendTestDialogOpen, setIsSendTestDialogOpen] = useState(false);

  // Mutations and Queries
  const { data: campaignDetail, isLoading: isCreativesLoading } =
    useGetCampaignDetails({ campaignId: campaignId });
  const creativesData = campaignDetail?.email_creatives ?? [];

  const updateEmailCreativeMutation = useUpdateEmailCreative({
    email: {
      id: email.id,
      name: email.name,
      subtitle: email.subtitle,
      description: email.description,
      audiences: email.audiences,
      sections: email.sections // Manually spread because we have to update AND create
        .filter((section) => section.type !== null)
        .map((section, index) => {
          return {
            ...section,
            id: section.id.startsWith("new-section-") ? "" : section.id, // set empty string for new sections
            index: index,
          };
        })
        .filter(Boolean),
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: getCampaignDetailsQueryKey(campaignId),
      });
    },
    onSuccess: () => {
      blocker?.proceed?.();
    },
    onError: () => {
      blocker?.reset?.();
    },
  });

  const selectEmailComponent = creativesData.length > 0 && (
    <Select.Root
      onValueChange={setActiveEmailId}
      defaultValue={creativesData[0].id}
      size="2"
    >
      <SelectTrigger
        disabled={isCreativesLoading}
        variant="ghost"
        color="gray"
      />
      <Select.Content color="gray" highContrast position="popper">
        {creativesData.map((creative) => (
          <Select.Item key={creative.id} value={creative.id}>
            <Text size={"4"} weight={"medium"}>
              {creative.title}
            </Text>
          </Select.Item>
        ))}
      </Select.Content>
    </Select.Root>
  );

  const previewTestDropdown = (
    <Flex gap="12px">
      <Popover.Root>
        <Popover.Trigger asChild>
          <AppButton variant="outlined" size="3">
            Actions
            <ChevronDownIcon strokeWidth={1.5} size={20} />
          </AppButton>
        </Popover.Trigger>
        <Popover.Portal>
          <Theme>
            <PopoverContent sideOffset={5} align="start">
              <Flex direction={"column"} gap="2">
                <MenuItem
                  onClick={() => {
                    setPreviewEmailDialogOpen(true);
                  }}
                >
                  <Text>Preview</Text>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setIsSendTestDialogOpen(true);
                  }}
                >
                  <Text>Send Test</Text>
                </MenuItem>
              </Flex>
            </PopoverContent>
          </Theme>
        </Popover.Portal>
      </Popover.Root>

      {email.published_at ? (
        <AppButton
          size="3"
          variant="outlined"
          style={{ background: "var(--primary-white)" }}
          disabled
        >
          <CheckIcon /> Published
        </AppButton>
      ) : (
        <AppButton
          variant="primary"
          radius="full"
          size="3"
          onClick={() => {
            updateEmailCreativeMutation.mutate();
          }}
          disabled={updateEmailCreativeMutation.isPending}
        >
          Save
        </AppButton>
      )}
    </Flex>
  );

  return (
    <>
      <Bar justify="between">
        <Flex gap="18px" align="center">
          <IconButton
            size="3"
            radius="full"
            variant="outline"
            style={{
              border: "1px solid var(--border-primary)",
              boxShadow: "none",
            }}
            onClick={() => {
              if (window.history.state && window.history.state.idx > 0) {
                navigate(-1);
              } else {
                navigate(CAMPAIGNS_ROUTE);
              }
            }}
          >
            <ArrowLeftIcon />
          </IconButton>
          {(creativesData.length ?? 0) > 1 ? (
            selectEmailComponent
          ) : (
            <Text size={"4"} weight={"medium"}>
              {creativesData?.[0]?.title}
            </Text>
          )}
        </Flex>

        <CenteredElement>
          <SegmentedControl.Root
            style={{ paddingTop: "4px" }}
            size="3"
            defaultValue={email.deviceView}
            onValueChange={setDeviceView}
          >
            <SegmentedControl.Item value="desktop">
              <MonitorIcon />
            </SegmentedControl.Item>
            <SegmentedControl.Item value="mobile">
              <SmartphoneIcon />
            </SegmentedControl.Item>
          </SegmentedControl.Root>
        </CenteredElement>

        {previewTestDropdown}
      </Bar>

      <PreviewEmailDialog
        open={previewEmailDialogOpen}
        onOpenChange={setPreviewEmailDialogOpen}
      />
      <SendTestEmailDialog
        open={isSendTestDialogOpen}
        onOpenChange={setIsSendTestDialogOpen}
      />

      <Modal
        width={"460px"}
        open={blocker?.state === "blocked"}
        title="Save Changes"
        onSubmit={() => {
          updateEmailCreativeMutation.mutate();
        }}
        onClose={() => {
          blocker?.reset?.();
        }}
        cancelBtnText="Discard Changes"
        submitBtnText="Save Changes"
        onCancel={() => {
          console.log("cancel");
          blocker?.proceed?.();
        }}
      >
        <Box p="3">
          You have made some changes to the email, would you like to save them
          before closing this email ?
        </Box>
      </Modal>
    </>
  );
};

export default EmailEditorTopBar;
