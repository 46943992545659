import Sidepane from "../components/core/sidepane/Sidepane";
import { SidePanelNavigator } from "../components/core/sidepane/SidepaneNavigator";
import { CAMPAIGNS_ROUTE, HOME_ROUTE, STYLE_LIBRARY_ROUTE } from "./constants";
import { Flex } from "@radix-ui/themes";
import {
  LucideProps,
  LayoutDashboard,
  BookCheck,
  SwatchBook,
} from "lucide-react";
import { ForwardRefExoticComponent, RefAttributes } from "react";
import { Link, Outlet } from "react-router-dom";
import KinLogo from "~/components/core/KinLogo";
import { useDebug } from "~/contexts/DebugContext";
import { DynamicHeaderProvider } from "~/contexts/DynamicHeaderContext";

export enum SectionType {
  ROOT = "",
  HOME = "home",
  CAMPAIGNS = "campaigns",
  PLANNER = "planner",
  STYLE_LIBRARY = "style-library",
  PROMOTION = "promotion",
}

export type Section = {
  type: SectionType;
  route: string;
  title?: string;
  path?: string;
  icon: ForwardRefExoticComponent<
    Omit<LucideProps, "ref"> & RefAttributes<SVGSVGElement>
  >;
};

const SECTIONS: Section[] = [
  { type: SectionType.HOME, icon: LayoutDashboard, route: HOME_ROUTE },
  {
    type: SectionType.CAMPAIGNS,
    icon: BookCheck,
    title: "My Campaigns",
    route: CAMPAIGNS_ROUTE,
  },
  {
    type: SectionType.STYLE_LIBRARY,
    icon: SwatchBook,
    title: "Style Library",
    route: STYLE_LIBRARY_ROUTE,
  },
];

/**
 * Base route: /
 * @param param0
 */
export default function RootRoute() {
  const debugContext = useDebug();
  return (
    <Flex minHeight="100vh" width="100%" justify={"end"}>
      <Sidepane
        color="white"
        header={
          <div
            onMouseDown={(e) => {
              if (debugContext) {
                const timer = setTimeout(() => {
                  // Enable debug mode after 3 seconds
                  debugContext.onToggleDebug(!debugContext.debug);
                }, 3000);

                const handleMouseUp = () => {
                  clearTimeout(timer);
                  document.removeEventListener("mouseup", handleMouseUp);
                };

                document.addEventListener("mouseup", handleMouseUp);
              }
            }}
            style={{ marginBottom: "60px" }}
          >
            <Link to={"/"}>
              <KinLogo />
            </Link>
          </div>
        }
        navigation={
          <nav>
            {SECTIONS.map((section) => {
              return (
                <SidePanelNavigator section={section} key={section.type} />
              );
            })}
          </nav>
        }
      />
      <Flex
        direction={"column"}
        width={`calc(100% - ${
          document.getElementById("root-sidepanel")?.clientWidth ?? 126
        }px)`}
      >
        <DynamicHeaderProvider>
          <RootRouteBase />
        </DynamicHeaderProvider>
      </Flex>
    </Flex>
  );
}

export function RootRouteBase() {
  return (
    <main
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <Outlet />
    </main>
  );
}

export function PublicRootRouteBase() {
  return (
    <main
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        gap: "1rem",
        height: "100vh",
        width: "100vw",
      }}
    >
      <Outlet />
    </main>
  );
}
