import LoadingDots from "../campaign/wizard/misc/LoadingDots";
import { Text, Box, Flex, Heading } from "@radix-ui/themes";

interface StyleLibraryCardContentProps {
  children: React.ReactNode;
  actions?: React.ReactNode;
  title: string;
  isLoading?: boolean;
}

const StyleLibraryCardContent: React.FC<StyleLibraryCardContentProps> = ({
  actions,
  children,
  title,
  isLoading,
}) => {
  return (
    <Flex direction="column" gap="5" width={"100%"}>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Heading size="5" weight="medium">
          {title}
        </Heading>
        <Box
          style={{
            display: "flex",
            gap: "1rem",
          }}
        >
          {actions}
        </Box>
      </Box>
      {isLoading && <LoadingDots />}
      {!isLoading && children}
    </Flex>
  );
};

export default StyleLibraryCardContent;
