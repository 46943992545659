import { Flex, Grid, Text } from "@radix-ui/themes";
import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useEmailSectionTemplatesQuery from "~/hooks/emails/useEmailSectionTemplatesQuery";
import {
  useEmailState,
  useUpdateSectionField,
} from "~/routes/intern/email_editor/context/EmailEditorContext";
import { isNewSection } from "~/utils/emails/useSectionTypeCheck";

const VariantSelector = styled(Grid)`
  gap: 8px;
`;

const SectionTitle = styled(Text)`
  color: var(--text-secondary);
`;

const GeneratedVariantSelector = ({
  title = "Explore layout variants",
}: {
  title?: string;
}) => {
  const updateSection = useUpdateSectionField();

  const { editorViewStack, selectedSectionId, sections } = useEmailState();
  const section = sections.find((section) => section.id === selectedSectionId);
  const [currentIndex, setCurrentIndex] = useState(0);

  const { templates, error, isLoading } = useEmailSectionTemplatesQuery(
    useActiveBrandID(),
    section?.type ?? null
  );

  const isAllDisabled =
    templates?.length <= 1 || isLoading || !section || error !== null;

  const isPreviousDisabled = currentIndex === 0 || isAllDisabled;
  const isNextDisabled =
    currentIndex === templates?.length - 1 || isAllDisabled;

  useEffect(() => {
    if (!section) {
      return;
    }
    if (isNewSection(section)) {
      return;
    }
    const activeTemplate = templates.findIndex(
      (template) => template.id === section?.template.id
    );

    if (activeTemplate !== -1) {
      setCurrentIndex(activeTemplate);
    }
  }, [section, templates]);

  const handlePrevious = () => {
    updateSection({
      sectionId: selectedSectionId,
      field: "template",
      value: templates[currentIndex - 1],
    });
    setCurrentIndex(currentIndex - 1);
  };

  const handleNext = () => {
    updateSection({
      sectionId: selectedSectionId,
      field: "template",
      value: templates[currentIndex + 1],
    });
    setCurrentIndex(currentIndex + 1);
  };

  return (
    <Flex direction="column" gap="16px">
      <SectionTitle weight="medium">{title}</SectionTitle>
      <VariantSelector columns="2">
        <AppButton
          radius="large"
          variant="soft"
          disabled={isPreviousDisabled}
          onClick={handlePrevious}
        >
          <ChevronLeftIcon /> Previous
        </AppButton>
        <AppButton
          radius="large"
          variant="soft"
          disabled={isNextDisabled}
          onClick={handleNext}
        >
          Next <ChevronRightIcon />
        </AppButton>
      </VariantSelector>
    </Flex>
  );
};

export default GeneratedVariantSelector;
