import {
  ProductsFilters,
  ProductSort,
  ProductSorters,
} from "./useProductsFilters";
import { operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { useAdEditorSelectedAdState } from "~/contexts/AdEditorContext";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";

type GetCollectionProductsResponseType =
  operations["shopify_integration_api_get_collection_products"]["responses"][200]["content"]["application/json"];
type GetCollectionProductsParamsType =
  operations["shopify_integration_api_get_collection_products"]["parameters"]["query"];
type ShopifyProductType =
  operations["shopify_integration_api_products"]["responses"][200]["content"]["application/json"]["products"][0];

type PageData = {
  before: string | null;
  after: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  products: ShopifyProductType[];
};
const useGetFilteredProducts = (
  itemsPerPage: number,
  filters: ProductsFilters,
  sorters: ProductSorters
) => {
  const activeBrandID = useActiveBrandID();
  const adState = useAdEditorSelectedAdState();
  const { data, isLoading, error, isSuccess } = useQuery({
    queryKey: ["shopify-products", activeBrandID],
    queryFn: async (): Promise<GetCollectionProductsResponseType> => {
      const response = await axios.get(
        "/api/v1/commerce-platform/collection/products",
        {
          params: {
            brand_id: activeBrandID,
            collection_id:
              adState.data.campaign.destination_collection
                ?.commerce_platform_item_id,
          } as GetCollectionProductsParamsType,
        }
      );
      return response.data;
    },
    enabled: !!activeBrandID,
  });

  const pagedProducts = useMemo<Map<number, PageData>>(() => {
    if (!isSuccess || error || isLoading || !data?.products?.length) {
      return new Map();
    }

    // Apply name filter
    let filteredProducts = data.products;
    if (filters.name) {
      const lowercasedFilter = filters.name.toLowerCase();
      filteredProducts = filteredProducts.filter((product) =>
        product.title.toLowerCase().includes(lowercasedFilter)
      );
    }

    // TODO: Need categoryId for category filtering

    // Apply name sorting
    if (sorters.nameSort !== ProductSort.UNSET) {
      filteredProducts.sort((a, b) => {
        const nameA = a.title.toLowerCase();
        const nameB = b.title.toLowerCase();

        if (nameA < nameB) return sorters.nameSort === ProductSort.ASC ? -1 : 1;
        if (nameA > nameB) return sorters.nameSort === ProductSort.ASC ? 1 : -1;
        return 0;
      });
    }

    // Create a map to store products for each page
    const paginatedMap = new Map<number, PageData>();

    const totalProducts = filteredProducts.length;
    const totalPages = Math.ceil(totalProducts / itemsPerPage);

    for (let page = 0; page < totalPages; page++) {
      const startIdx = page * itemsPerPage;
      const endIdx = startIdx + itemsPerPage;
      const pageProducts = filteredProducts.slice(startIdx, endIdx);

      const hasNextPage = page < totalPages - 1;
      const hasPreviousPage = page > 0;

      // Construct the page data
      const pageData: PageData = {
        before: hasPreviousPage ? `cursor_before_${startIdx}` : null,
        after: hasNextPage ? `cursor_after_${endIdx - 1}` : null,
        hasNextPage,
        hasPreviousPage,
        products: pageProducts,
      };

      paginatedMap.set(page, pageData);
    }

    return paginatedMap;
  }, [
    data,
    itemsPerPage,
    filters.name,
    sorters.nameSort,
    isSuccess,
    isLoading,
    error,
  ]);

  return useMemo(() => {
    return {
      products: pagedProducts.get(filters.page)?.products,
      hasNextPage: pagedProducts.get(filters.page)?.hasNextPage,
      hasPreviousPage: pagedProducts.get(filters.page)?.hasPreviousPage,
    };
  }, [pagedProducts, filters.page]);
};

export default useGetFilteredProducts;
