import { EmailCreative } from "../../../../hooks/emails/useEmailCreativeQuery";
import { EmailEditorViews } from "../context/EmailEditorContext";
import { EmailSection } from "./EmailEditorContext";
import { EmailSectionType } from "@openapi";
import { isSomeEnum } from "~/utils/helpers";

export const reduceSectionsState = (email: EmailCreative) => {
  const mappedSections = email.sections.reduce(
    (acc: any, section: EmailSection) => {
      acc[section.id] = section;
      return acc;
    },
    {}
  );
  return { ...email, sections: mappedSections };
};

export const getCurrentEmailSection = (emailsState: any) => {
  return;
};

export const sectionTypeToEnum = (
  sectionType: string
): EmailEditorViews | null => {
  if (isSomeEnum(EmailEditorViews, sectionType)) {
    return sectionType as EmailEditorViews;
  }

  switch (sectionType) {
    case EmailSectionType.hero:
      return EmailEditorViews.hero;
    default:
      return null;
  }
};
