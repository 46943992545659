import MarkPopup from "@components/core/MarkPopup";
import { Box, Flex, Heading, Skeleton, Text } from "@radix-ui/themes";
import { MailIcon, PlusIcon } from "lucide-react";
import { Link } from "react-router-dom";
import styled, { CSSProperties } from "styled-components";
import { ReactComponent as MetaIcon } from "~/assets/MetaIcon.svg";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import BackgroundGradient from "~/components/core/layout/BackgroundGradient";
import { NEW_CAMPAIGN_ROUTE } from "~/routes/constants";

const Card = styled(Flex)<{ $opacity?: CSSProperties["opacity"] }>`
  width: 100%;
  border-radius: 12px;
  border: 1px solid var(--border);
  background-color: var(--primary-white);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
  position: relative;
  opacity: ${({ $opacity }) => $opacity};
`;

const RoundedSkeleton = styled(Skeleton)`
  border-radius: 8px;
`;

const Content = styled(Flex)`
  padding: 24px;
  border-top: 1px solid var(--border);
  color: var(--text-secondary);
`;

const ImageSkeleton = styled.div`
  border-radius: 8px;
  width: 48px;
  height: 48px;
  background: rgba(255, 153, 153, 1);
  border: 1px solid rgba(230, 230, 230, 1);
`;

const MarkPopupStyled = styled(MarkPopup)`
  position: absolute;
  top: -56px;
  right: 0px;
  z-index: 1;
  width: 45%;
`;

const SkeletonRows: React.FC<{ count: number }> = ({ count }) => (
  <>
    {[...Array(count)].map((_, index) => (
      <Flex gap="32px" align="center" key={index}>
        <ImageSkeleton />
        <RoundedSkeleton height="39px" width="70%" />
      </Flex>
    ))}
  </>
);

const SkeletonCampaign: React.FC = ({}) => (
  <>
    <Flex p="20px" width="100%" gap="8px" align="center" justify="between">
      <Text size="4" weight="bold">
        Campaign Title
      </Text>
      <RoundedSkeleton width="170px" height="40px" />
    </Flex>
    <Content direction="column" gap="16px">
      <Flex gap="16px" direction="column">
        <Flex gap="8px" align="center">
          <MailIcon strokeWidth="1" width={20} height={20} />
          <Text>Email</Text>
        </Flex>
        <SkeletonRows count={2} />
      </Flex>
      <Flex gap="16px" direction="column">
        <Flex gap="8px" align="center">
          <MetaIcon width={20} height={20} />
          <Text>Meta Ads</Text>
        </Flex>
        <SkeletonRows count={3} />
      </Flex>
    </Content>
  </>
);

const MyCampaignsZeroState = () => {
  return (
    <Flex
      width="100%"
      p="24px 56px"
      gap="24px"
      direction="column"
      align="center"
    >
      <BackgroundGradient variant="3" />
      <Flex width="100%">
        <Heading size="6">My Campaigns</Heading>
      </Flex>
      <Box width="100%" position="relative">
        <MarkPopupStyled sayHello>
          <Text mb="24px">Here will be your scheduled & live campaigns.</Text>
          <Link to={NEW_CAMPAIGN_ROUTE}>
            <AppButton>
              <PlusIcon /> New campaign
            </AppButton>
          </Link>
        </MarkPopupStyled>
        <Card direction="column" $opacity={0.8}>
          <SkeletonCampaign />
        </Card>
      </Box>
      <Card direction="column" $opacity={0.5}>
        <SkeletonCampaign />
      </Card>
    </Flex>
  );
};

export default MyCampaignsZeroState;
