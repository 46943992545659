import { IconButton, Text } from "@radix-ui/themes";
import { X } from "lucide-react";
import AppButton from "~/components/core/buttons/AppButton/AppButton";

export default function SelectedButton({
  id,
  onClick,
  text,
  selected,
}: {
  id: string;
  onClick?: (id: string) => void;
  text: string;
  selected?: boolean;
}) {
  return (
    <AppButton
      key={id}
      style={{
        width: "fit-content",
        backgroundColor: selected ? "#F7ECDC80" : "transparent",
        color: "#4C4747",
      }}
      size="3"
      radius="full"
      variant="outlined"
    >
      <Text as="span" size="2" weight="medium">
        {text}
      </Text>
      {onClick && (
        <IconButton
          radius="full"
          onClick={() => onClick(id)}
          color="gray"
          variant="ghost"
        >
          <X color="#000000A6" size={16} />
        </IconButton>
      )}
    </AppButton>
  );
}
