import AppButton from "../core/buttons/AppButton/AppButton";
import TextFieldWithLabel from "../core/inputs/TextFieldWithLabel";
import KlaviyoConnectInstructions from "../core/misc/KlaviyoConnectInstructions";
import { Avatar, Flex, Separator, Text } from "@radix-ui/themes";
import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as MetaIcon } from "~/assets/MetaIcon.svg";

const FullWidthSeparator = styled(Separator)`
  width: 100%;
  background-color: rgba(240, 240, 240, 1);
`;

const HelpText = styled(Text)`
  color: var(--text-link);
  font-size: 14px;
  cursor: pointer;
  font-weight: 500;
`;

const MetaAccountContainer = styled(Flex)`
  border: 1px solid rgba(240, 240, 240, 1);
  border-radius: 16px;
`;

const AddMetaAccountButton = styled(AppButton)`
  background-color: rgba(0, 100, 224, 1);
  color: var(--primary-white);
  width: max-content;
`;

const AccountSettingsIntegrations = () => {
  const [shouldShowHelp, setShouldShowHelp] = useState(false);

  return (
    <Flex gap="24px" direction="column">
      <Flex direction="column" gap="24px" px="32px" pt="32px">
        <Text weight="medium">Klaviyo</Text>
        <Flex gap="16px" align="end">
          <TextFieldWithLabel
            width="100%"
            label="Klaviyo Private Key"
            size="3"
            radius="large"
          />
          <AppButton size="3" radius="large">
            Connect
          </AppButton>
        </Flex>
        <Flex direction="column">
          <HelpText onClick={() => setShouldShowHelp((prev) => !prev)}>
            Help me get the Private key
          </HelpText>
          {shouldShowHelp && <KlaviyoConnectInstructions />}
        </Flex>
      </Flex>
      <FullWidthSeparator />
      <Flex direction="column" gap="24px" px="32px" pb="32px">
        <Text weight="medium">Meta Accounts</Text>
        <MetaAccountContainer justify="between" p="24px">
          <Flex gap="12px" align="center">
            <Avatar fallback="M" radius="large" />
            <Text>Account Name</Text>
          </Flex>
          <AppButton variant="outlined" size="3" radius="large">
            Remove Account
          </AppButton>
        </MetaAccountContainer>
        <AddMetaAccountButton radius="large">
          <MetaIcon
            style={{
              filter:
                "invert(99%) sepia(79%) saturate(2%) hue-rotate(217deg) brightness(115%) contrast(100%)",
            }}
          />{" "}
          Add Account
        </AddMetaAccountButton>
      </Flex>
    </Flex>
  );
};

export default AccountSettingsIntegrations;
