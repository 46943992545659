import MarkPopup from "@components/core/MarkPopup";
import { Flex, Grid, Skeleton, Text } from "@radix-ui/themes";
import { BellDotIcon, ClockIcon } from "lucide-react";
import { useMemo } from "react";
import styled from "styled-components";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import ThreeSlotsPreview from "~/components/core/layout/ThreeSlotsPreview";
import useTimeCountdown from "~/hooks/common/useTimeCountdown";
import { nextRecommenderScheduleDate } from "~/utils/timeUtils";

const NullStateHero = styled(Flex)`
  width: 100%;
  flex: 1;
  height: 100%;
  min-width: 350px;
  background-color: var(--primary-white);
  padding: 23px 30px;
  border-radius: 30px;
  border: 1px solid var(--border-primary);
  box-shadow: 0px 3.86px 7.71px 0px #0000000a;
`;

const CounterWrapper = styled(Flex)`
  border-radius: 30px;
  background-color: var(--primary-deep-purple);
  color: var(--primary-white);
  height: 100%;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const MarkPopupStyled = styled(MarkPopup)`
  position: absolute;
  top: -24px;
  left: -102px;
  z-index: 1;
  width: 120%;
`;

const Card = styled(Flex)`
  padding: 32px;
  border: 1px solid var(--border-primary);
  border-radius: 32px;
  background-color: var(--primary-white);
  box-shadow: 0px 4px 8px 0px #0000000a;
`;

const CountdownText = styled(Text)`
  font-variant-numeric: tabular-nums;
`;

const showCounter = true;

const leadingZero = (num: number, digits = 2) =>
  num.toString().padStart(digits, "0");

const RecommenderZeroState = () => {
  const scheduleDate = useMemo(() => nextRecommenderScheduleDate(), []);
  const { seconds, minutes, hours } = useTimeCountdown(scheduleDate.toDate());

  return (
    <Flex direction="column" gap="5" width="100%" overflow="hidden" pt="72px">
      <Flex gap="48px" height="544px">
        <NullStateHero>
          <CounterWrapper>
            {showCounter ? (
              <Flex gap="18px">
                <Flex direction="column" gap="40px">
                  <CountdownText align="center" size="9" weight="bold">
                    {leadingZero(hours)}
                  </CountdownText>
                  <Text size="5" weight="medium">
                    Hours
                  </Text>
                </Flex>
                <Text size="9" weight="bold">
                  :
                </Text>
                <Flex direction="column" gap="40px">
                  <CountdownText align="center" size="9" weight="bold">
                    {leadingZero(minutes)}
                  </CountdownText>
                  <Text size="5" weight="medium">
                    Minutes
                  </Text>
                </Flex>
                <Text size="9" weight="bold">
                  :
                </Text>
                <Flex direction="column" gap="40px">
                  <CountdownText align="center" size="9" weight="bold">
                    {leadingZero(seconds)}
                  </CountdownText>
                  <Text size="5" weight="medium">
                    Seconds
                  </Text>
                </Flex>
              </Flex>
            ) : (
              <ClockIcon size="72" />
            )}
          </CounterWrapper>
        </NullStateHero>
        <Flex
          direction="column"
          height="100%"
          justify="end"
          pb="54px"
          style={{ position: "relative", flex: "0.5" }}
        >
          <MarkPopupStyled sayHello>
            <Text>
              We’re currently analyzing your brand voice to tailor the best
              campaign ideas for you.
            </Text>
          </MarkPopupStyled>
          <Text mb="32px" size="8" weight="bold">
            Coming soon. Keep your eyes open.
          </Text>
          <Flex
            mb="60px"
            gap="16px"
            align="center"
            style={{ color: "#3b3b3b" }}
          >
            <Flex align="center" gap="10px">
              <Text size="3" weight="medium">
                The clock is ticking. We are coming up with 3 big campaigns for
                you. Promise.
              </Text>
            </Flex>
          </Flex>
          {/* <AppButton
          style={{ width: "max-content" }}
          >
          <BellDotIcon />
          Notify Me
        </AppButton> */}
        </Flex>
      </Flex>
      <Grid columns={{ initial: "1", md: "2", lg: "2" }} gap="24px">
        <Card direction="column">
          <ThreeSlotsPreview gap="16px" mb="32px">
            <Skeleton height="100%" style={{ aspectRatio: "3 / 2" }} />
            <Skeleton height="157px" style={{ aspectRatio: "1 / 1" }} />
            <Skeleton height="157px" style={{ aspectRatio: "1 / 1" }} />
          </ThreeSlotsPreview>

          <Text mb="16px" size="7" weight="medium">
            Campaign 2
          </Text>
          <Flex
            mb="32px"
            gap="16px"
            align="center"
            style={{ color: "#3b3b3b" }}
          >
            <Text size="3" weight="medium">
              Coming soon...
            </Text>
          </Flex>
        </Card>
        <Card direction="column">
          <ThreeSlotsPreview gap="16px" mb="32px">
            <Skeleton height="100%" style={{ aspectRatio: "3 / 2" }} />
            <Skeleton height="157px" style={{ aspectRatio: "1 / 1" }} />
            <Skeleton height="157px" style={{ aspectRatio: "1 / 1" }} />
          </ThreeSlotsPreview>

          <Text mb="16px" size="7" weight="medium">
            Campaign 3
          </Text>
          <Flex
            mb="32px"
            gap="16px"
            align="center"
            style={{ color: "#3b3b3b" }}
          >
            <Text size="3" weight="medium">
              Coming soon...
            </Text>
          </Flex>
        </Card>
      </Grid>
    </Flex>
  );
};

export default RecommenderZeroState;
