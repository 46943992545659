import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ShopifyInstall = () => {
  let location = useLocation();

  useEffect(() => {
    fetch("/api/v1/commerce-platform/install" + location.search)
      .then((res) => res.json())
      .then((data) => {
        const redirect_url = data.redirect_url;
        if (redirect_url) {
          window.location.href = redirect_url;
        }
      });
  }, []);

  return <></>;
};

export default ShopifyInstall;
