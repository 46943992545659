import classes from "./StoryAdCard.module.css";
import { Flex } from "@radix-ui/themes";
import { ChevronUp, Ellipsis, Link2, X } from "lucide-react";
import { ReactComponent as Battery } from "~/assets/battery.svg";
import { ReactComponent as IphoneSignal } from "~/assets/iphoneSignal.svg";
import { ReactComponent as Wifi } from "~/assets/wifi.svg";
import AdMediaSVGCanvas from "~/components/ads/AdMediaSVGCanvas";
import { useAdEditorSelectedAdMediaState } from "~/contexts/AdEditorContext";

const StoryAdCard = () => {
  const { ad: adData, media } = useAdEditorSelectedAdMediaState();
  const mediaUrl = media?.variants?.find(
    (variant) => variant.aspect_ratio === "full_screen_vertical"
  )?.file;
  const header = (
    <Flex align="center" justify="between" className={classes.header}>
      <span className={classes.time}>
        <b>16:30</b>
      </span>
      <Flex align="center" gap="6px">
        <IphoneSignal height="26px" />
        <Wifi height="22px" />
        <Battery height="24px" width="40px" />
      </Flex>
    </Flex>
  );
  const content = (
    <div className={classes.content}>
      <Flex className={classes.content_header} direction="column" gap="20px">
        <div className={classes.progress}>
          <div className={classes.line} />
          <div className={classes.inner_line} />
        </div>
        <Flex justify="between" className={classes.content_header_inner}>
          <Flex gap="6px" align="center">
            <div className={classes.icon}></div>
            <Flex direction="column">
              <span className={classes.company_name}>
                {adData?.data.brand_name}
              </span>
              <span className={classes.sponsored}>Sponsored</span>
            </Flex>
          </Flex>
          <Flex gap="8px">
            <Ellipsis color="#ffffff" />
            <X color="#ffffff" />
          </Flex>
        </Flex>
      </Flex>
      <AdMediaSVGCanvas svgUrl={mediaUrl} />
      <Flex
        align="center"
        justify="center"
        gap="12px"
        className={classes.shop_now}
      >
        <Link2 style={{ transform: "rotate(-45deg)" }} size="32px" />
        <span>SHOP NOW</span>
      </Flex>
    </div>
  );
  const footer = (
    <Flex className={classes.footer} justify="center" align="center">
      <ChevronUp color="#C3C4C5" size="48px" className={classes.chevron} />
    </Flex>
  );
  return (
    <Flex className={classes.container} direction="column">
      {header}
      {content}
      {footer}
    </Flex>
  );
};

export default StoryAdCard;
