import CampaignWizardCard from "../../../components/campaign/wizard/cards/CampaignWizardCard";
import SearchableExpandedSelect from "../../../components/campaign/wizard/inputs/SearchableExpandedSelect";
import useCampaignLandingDestinationProps from "./hooks/useCampaignLandingDestinationProps";
import { DestinationType } from "@openapi";
import * as Form from "@radix-ui/react-form";
import { Flex, Text, Box } from "@radix-ui/themes";
import FormErrorText from "~/components/auth/FormErrorText";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import { useCampaignWizardState } from "~/contexts/CampaignWizardContext";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useBrandQuery from "~/hooks/brand/useBrandQuery";

const CampaignLandingDestination = () => {
  const {
    cardProps,
    inputProps: {
      destination,
      isSelecting,
      setDestination,
      setIsSelecting,
      isLandingDestinationEnabled,
      setIsLandingDestinationEnabled,
    },
  } = useCampaignLandingDestinationProps();
  const { wizardType } = useCampaignWizardState();
  const activeBrandID = useActiveBrandID();
  const { brandData } = useBrandQuery(activeBrandID);

  const inputComponent = (
    <Flex direction={"row"} align={"end"} gap={"4"}>
      <Box width={"100%"}>
        <Form.Field name="landing-destination">
          <Form.Label>
            <Text size={"1"}>Landing Destination URL</Text>
          </Form.Label>
          <Flex align="end" gap="3">
            <Form.Control asChild>
              <input
                id="landing-destination"
                type="url"
                style={{
                  padding: "0 8px",
                  fontSize: "14px",
                  borderRadius: "12px",
                  border: "1px solid var(--border, #DDD7D7)",
                  width: "calc(100% - 16px)",
                  height: "45px",
                  backgroundColor: "white",
                  color: "black",
                  cursor: "default",
                }}
                value={destination?.url ?? ""}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value) {
                    setDestination({
                      type: DestinationType.custom,
                      url: e.target.value,
                    });
                    setIsLandingDestinationEnabled(true);
                    setIsSelecting(false);
                  } else {
                    setDestination({});
                  }
                }}
              />
            </Form.Control>
            <AppButton
              variant="outlined"
              radius="large"
              style={{
                backgroundColor: isSelecting ? "#0000000D" : "white",
              }}
              onClick={() => {
                setIsSelecting((prev) => !prev);
              }}
            >
              Collection Page
            </AppButton>
          </Flex>
          <Form.Message match={"typeMismatch"}>
            <FormErrorText>Please provide a valid URL</FormErrorText>
          </Form.Message>
        </Form.Field>
      </Box>
    </Flex>
  );

  const text =
    wizardType === "NEW"
      ? "Do you have a landing page already created for this campaign?"
      : destination
      ? `Do you have a landing page already created for this campaign? If not, the destination of this campaign will be the following ${
          destination.type === DestinationType.collection
            ? "collection"
            : "product"
        } page.`
      : "Do you have a landing page already created for this campaign? If not, we will create a new custom collection that features these products. You will be able to configure the name of the collection before generating the campaign.";

  return (
    <Form.Root onSubmit={(e) => e.preventDefault()}>
      <CampaignWizardCard {...cardProps} subtitle={text}>
        <Flex gap="16px">
          <AppButton
            onClick={() => {
              setIsLandingDestinationEnabled(false);
              setDestination({});
            }}
            style={{
              border:
                isLandingDestinationEnabled === false
                  ? "1px solid var(--text-link)"
                  : undefined,
            }}
            radius="large"
            variant="soft"
          >
            No
          </AppButton>
          <AppButton
            onClick={() => {
              setIsLandingDestinationEnabled(true);
              setIsSelecting(true);
            }}
            style={{
              border:
                isLandingDestinationEnabled === true
                  ? "1px solid var(--text-link)"
                  : undefined,
            }}
            radius="large"
            variant="soft"
          >
            Yes
          </AppButton>
        </Flex>

        {isLandingDestinationEnabled && inputComponent}

        {isLandingDestinationEnabled && isSelecting && (
          <SearchableExpandedSelect
            onSelect={(item) => {
              setDestination({
                collection_id: item.id,
                name: item.title,
                handle: item.handle,
                type: DestinationType.collection,
                url: `${brandData?.domain}/collections/${item.handle}`,
              });
            }}
          />
        )}
      </CampaignWizardCard>
    </Form.Root>
  );
};

export default CampaignLandingDestination;
