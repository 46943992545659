import useEmailCreativesQuery from "../../hooks/emails/useEmailCreativesQuery";
import ButtonEditor from "./email_editor/button_editor/ButtonEditor";
import {
  EmailContextProvider,
  EmailSection,
  useEmailState,
  useInitEmailState,
  useEmailDispatch,
  useSetActiveEmailId,
  useUpdateSectionField,
} from "./email_editor/context/EmailEditorContext";
import { printEditorContent } from "./email_editor/sections/hero/useHandleHeroEditableText";
import ToggleShowEditorButton from "./email_editor/text_editor/ToggleShowEditorButton";
import { getQuillHtml } from "./email_editor/text_editor/htmlUtils";
import useEmbeddedEmail from "@components/editor/hooks/useEmbeddedEmail";
import useEmailCreativeQuery from "@hooks/emails/useEmailCreativeQuery";
import { EmailSectionType } from "@openapi";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
  SelectViewport,
  SelectGroup,
} from "@radix-ui/react-select";
import { useEffect } from "react";
import FullscreenSpinner from "~/components/core/FullscreenSpinner";
import {
  isBlogSection,
  isHeroSection,
  isProductsSection,
} from "~/utils/emails/useSectionTypeCheck";

const Cherry = ({ initialEmailId }: { initialEmailId: string | null }) => {
  const dispatch = useEmailDispatch();
  const updateSectionField = useUpdateSectionField();
  const initEmailState = useInitEmailState();
  const setActiveEmailId = useSetActiveEmailId();
  const { creativesData, isCreativesLoading, creativesError } =
    useEmailCreativesQuery();
  const { activeEmailId, sections } = useEmailState();

  const { emailCreativeData, isEmailCreativeLoading, emailCreativeError } =
    useEmailCreativeQuery(activeEmailId ?? initialEmailId);

  useEffect(() => {
    if (emailCreativeData && initialEmailId) {
      initEmailState({
        ...emailCreativeData,
        activeEmailId: initialEmailId,
      });
    }
  }, [emailCreativeData, initialEmailId]);

  const { embeddedEmail, heroTextEditors } = useEmbeddedEmail();

  //mutations
  // const updateEmailMutation = useUpdateEmailCreative(
  //   emailCreativeData
  //     ? {
  //         ...emailCreativeData,
  //         sections: Object.values(emailCreativeData?.sections),
  //       }
  //     : undefined
  // );

  const handleUpdateHeroEditableTexts = () => {
    Object.entries(heroTextEditors).map(
      ([sectionId, { quillTitleEditor, quillSubtitleEditor }], index: any) => {
        updateSectionField({
          sectionId: sectionId,
          field: "title.text",
          value: getQuillHtml(quillTitleEditor?.quill),
        });
        updateSectionField({
          sectionId: sectionId,
          field: "subtitle.text",
          value: getQuillHtml(quillSubtitleEditor?.quill),
        });
      }
    );
  };

  const handleSelectCreative = (id: string) => {
    console.log("id", id);
    handleUpdateHeroEditableTexts();
    setActiveEmailId(activeEmailId);
  };

  const heroHideToolbarButtons = Object.entries(heroTextEditors).map(
    ([id, { quillSubtitleEditor, quillTitleEditor }], index) => {
      return (
        <div>
          <ToggleShowEditorButton
            quillData={quillTitleEditor}
            title="show/hide Title editor"
          ></ToggleShowEditorButton>
          <ToggleShowEditorButton
            quillData={quillSubtitleEditor}
            title="show/hide Subitle editor"
          ></ToggleShowEditorButton>
        </div>
      );
    }
  );
  const renderSectionInputs = (section: EmailSection) => {
    if (isHeroSection(section)) {
      return (
        <>
          <div>
            <label>Background Image URL:</label>
            <input
              type="text"
              value={section.image?.image_url || ""}
              onChange={(e) =>
                updateSectionField({
                  sectionId: section.id,
                  field: "background.image",
                  value: e.target.value,
                })
              }
            />
          </div>
          <div>
            <label>Background Color:</label>
            <input
              type="text"
              value={section.palette.background || ""}
              onChange={(e) =>
                updateSectionField({
                  sectionId: section.id,
                  field: "background.color",
                  value: e.target.value,
                })
              }
            />
          </div>
          <div>
            <p style={{ fontSize: "24px" }}>Button:</p>
            <label>CTA Button Text:</label>
            <input
              type="text"
              value={section.cta_button?.text || ""}
              onChange={(e) =>
                updateSectionField({
                  sectionId: section.id,
                  field: "cta_button.text",
                  value: e.target.value,
                })
              }
            />
            <ButtonEditor
              sectionId={section.id}
              existingStyles={section.cta_button}
              handleInputChange={updateSectionField}
            />
            {heroHideToolbarButtons}
            <button
              onClick={() => {
                printEditorContent(heroTextEditors);
              }}
            >
              print content
            </button>
            <button
              onClick={() => {
                handleUpdateHeroEditableTexts();
                // updateEmailMutation.mutate();
              }}
            >
              update
            </button>
          </div>
        </>
      );
    } else if (isBlogSection(section)) {
      return (
        <>
          <div>
            <label>Title:</label>
            <input
              type="text"
              value={section?.title?.text || ""}
              onChange={(e) =>
                updateSectionField({
                  sectionId: section.id,
                  field: "title.text",
                  value: e.target.value,
                })
              }
            />
          </div>
          <div>
            <label>Description:</label>
            <textarea
              value={section.description?.text || ""}
              onChange={(e) =>
                updateSectionField({
                  sectionId: section.id,
                  field: "description.text",
                  value: e.target.value,
                })
              }
            />
          </div>
          <div>
            <label>Image URL:</label>
            <input
              type="text"
              value={section?.image?.image_url || ""}
              onChange={(e) =>
                updateSectionField({
                  sectionId: section.id,
                  field: "image.image_url",
                  value: e.target.value,
                })
              }
            />
          </div>
          <div>
            <p style={{ fontSize: "24px" }}>CTA Button:</p>
            <label>Button Text:</label>
            <input
              type="text"
              value={section?.cta_button?.text || ""}
              onChange={(e) =>
                updateSectionField({
                  sectionId: section.id,
                  field: "cta_button.text",
                  value: e.target.value,
                })
              }
            />
            <label>Button Link URL:</label>
            <input
              type="text"
              value={section.cta_button?.link_url || ""}
              onChange={(e) =>
                updateSectionField({
                  sectionId: section.id,
                  field: "cta_button.link_url",
                  value: e.target.value,
                })
              }
            />
            <ButtonEditor
              sectionId={section.id}
              existingStyles={section.cta_button}
              handleInputChange={updateSectionField}
            />
          </div>
        </>
      );
    } else if (isProductsSection(section)) {
      return (
        <div>
          <div>
            <label>Title:</label>
            <input
              type="text"
              value={section?.title?.text || ""}
              onChange={(e) =>
                updateSectionField({
                  sectionId: section.id,
                  field: "title.text",
                  value: e.target.value,
                })
              }
            />
          </div>
          <div>
            <label>Subtitle:</label>
            <input
              type="text"
              value={section?.subtitle?.text || ""}
              onChange={(e) =>
                updateSectionField({
                  sectionId: section.id,
                  field: "subtitle.text",
                  value: e.target.value,
                })
              }
            />
          </div>
          <div>
            <label>Background Color:</label>
            <input
              type="color"
              value={section?.palette?.background || "#FFFFFF"}
              onChange={(e) =>
                updateSectionField({
                  sectionId: section.id,
                  field: "palette.background",
                  value: e.target.value,
                })
              }
            />
          </div>
        </div>
      );
    }
  };

  return (
    <EmailContextProvider>
      <div style={{ padding: "20px" }}>
        <Select
          onValueChange={handleSelectCreative}
          value={activeEmailId || creativesData[0].id}
        >
          <SelectTrigger
            style={{
              display: "flex",
              alignItems: "center",
              padding: "8px 12px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              backgroundColor: "#fff",
              cursor: "pointer",
              width: "fit-content",
              minWidth: "250px",
            }}
          >
            <SelectValue placeholder="Select an Email Creative..." />
            <ChevronDownIcon />
          </SelectTrigger>
          <SelectContent
            style={{
              backgroundColor: "white",
              borderRadius: "4px",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              zIndex: 1000,
            }}
          >
            <SelectViewport>
              <SelectGroup>
                {isCreativesLoading ? (
                  <SelectItem value="loading">Loading...</SelectItem>
                ) : creativesError ? (
                  <SelectItem value="error">Error loading creatives</SelectItem>
                ) : creativesData && creativesData.length > 0 ? (
                  creativesData.map((creative) => (
                    <SelectItem key={creative.id} value={creative.id}>
                      {creative.name}
                    </SelectItem>
                  ))
                ) : (
                  <SelectItem value="empty">No creatives available</SelectItem>
                )}
              </SelectGroup>
            </SelectViewport>
          </SelectContent>
        </Select>

        {activeEmailId && (
          <div style={{ display: "flex", marginTop: "20px" }}>
            <div
              style={{
                flex: 1,
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                backgroundColor: "#f9f9f9",
                marginRight: "10px",
              }}
            >
              <h2>Rendered Email</h2>
              {isEmailCreativeLoading ? (
                <p>Loading...</p>
              ) : emailCreativeError ? (
                <p>Error loading email</p>
              ) : (
                <div>{embeddedEmail}</div>
              )}
            </div>
            <div
              style={{
                flex: 1,
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                backgroundColor: "#f9f9f9",
              }}
            >
              <h2>Email Sections</h2>
              {sections.map((section, index) => {
                return (
                  <div
                    key={index}
                    style={{ padding: "8px", borderBottom: "1px solid #ddd" }}
                  >
                    <strong>{section.type}</strong> - Index: {section.index}
                    {renderSectionInputs(section)}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </EmailContextProvider>
  );
};

const CherryContainer = () => {
  const { creativesData, isCreativesLoading, creativesError } =
    useEmailCreativesQuery();

  if (isCreativesLoading || !creativesData) return <FullscreenSpinner />;

  return (
    <EmailContextProvider>
      <Cherry initialEmailId={creativesData?.[0]?.id} />
    </EmailContextProvider>
  );
};

export default CherryContainer;
