import { useActiveBrandID } from "./CurrentUserContext";
import {
  components,
  FontFaceSourceType,
  FontType,
  ImageAssetCategory,
} from "@openapi";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { ImageAssetSchema } from "~/components/style-library/assets/BrandImageAsset";
import useBrandStyleQuery, {
  BrandStylingResponse,
} from "~/hooks/data/useBrandStyleQuery";
import nullthrows from "~/utils/nullthrows";
import { assertNever } from "~/utils/typeUtils";

export function addStylesheetURL(url: string) {
  var link = document.createElement("link");
  // required for html-to-image
  link.setAttribute("crossorigin", "anonymous");
  link.rel = "stylesheet";
  link.href = url;
  document.getElementsByTagName("head")[0].appendChild(link);
  return link;
}

type BrandStylingIntermediaryStatuses =
  | {
      status: "IDLE" | "LOADING";
    }
  | {
      status: "ERROR";
      error: string;
    };

type BrandStylingContextState = {
  isDirty: boolean;
} & (
  | BrandStylingIntermediaryStatuses
  | {
      status: "READY";
      data: BrandStylingResponse;
    }
);

const initialState: BrandStylingContextState = {
  status: "IDLE",
  isDirty: true,
};

interface ActionSetStyling {
  type: "SET_STYLING";
  payload: BrandStylingResponse;
}
interface ActionSetStatus {
  type: "SET_STATUS";
  payload: BrandStylingIntermediaryStatuses;
}
interface ActionResetStyling {
  type: "RESET_STYLING";
  payload: BrandStylingContextState;
}
interface ActionUpsertButtonStyle {
  type: "UPSERT_BUTTON_STYLE";
  payload: {
    isNew: boolean;
    style: components["schemas"]["BrandButtonStyleSchema"];
  };
}
interface ActionRemoveButtonStyle {
  type: "REMOVE_BUTTON_STYLE";
  payload: components["schemas"]["BrandButtonStyleSchema"]["id"];
}
interface ActionPatchTypographySizes {
  type: "PATCH_TYPOGRAPHY_SIZES";
  payload: { [key in FontType]?: string };
}
interface ActionAddImageAssets {
  type: "ADD_IMAGE_ASSETS";
  payload: ImageAssetSchema[];
}
interface ActionDeleteImageAsset {
  type: "DELETE_IMAGE_ASSET";
  payload: ImageAssetSchema;
}
interface ActionSetIsDirty {
  type: "SET_IS_DIRTY";
  payload: boolean;
}

export type BrandStylingAction =
  | ActionSetStyling
  | ActionResetStyling
  | ActionSetStatus
  | ActionUpsertButtonStyle
  | ActionRemoveButtonStyle
  | ActionPatchTypographySizes
  | ActionAddImageAssets
  | ActionDeleteImageAsset
  | ActionSetIsDirty;

function reducer(
  state: BrandStylingContextState,
  action: BrandStylingAction
): BrandStylingContextState {
  switch (action.type) {
    case "SET_STYLING":
      return {
        ...state,
        status: "READY",
        data: action.payload,
        isDirty: false,
      };
    case "RESET_STYLING":
      return action.payload;
    case "SET_STATUS":
      return {
        ...state,
        ...action.payload,
      };
    case "UPSERT_BUTTON_STYLE":
      if (state.status !== "READY") {
        return state;
      }
      const updatedButtonStyles = action.payload.isNew
        ? [...state.data.button_styles, action.payload.style]
        : state.data.button_styles.map((style) =>
            style.id === action.payload.style.id ? action.payload.style : style
          );
      return {
        ...state,
        data: {
          ...state.data,
          button_styles: updatedButtonStyles,
        },
      };
    case "REMOVE_BUTTON_STYLE":
      if (state.status !== "READY") {
        return state;
      }
      return {
        ...state,
        data: {
          ...state.data,
          button_styles: state.data.button_styles.filter(
            (style) => style.id !== action.payload
          ),
        },
      };
    case "PATCH_TYPOGRAPHY_SIZES":
      if (state.status !== "READY") {
        return state;
      }
      const fonts = state.data.typography.fonts.map((font) => {
        const newSize = action.payload[font.type] ?? font.size;
        return { ...font, size: newSize };
      });
      return {
        ...state,
        data: {
          ...state.data,
          typography: {
            ...state.data.typography,
            fonts,
          },
        },
      };
    case "ADD_IMAGE_ASSETS": {
      if (state.status !== "READY" || action.payload.length === 0) {
        return state;
      }
      const category = action.payload[0].category;
      const logos =
        category === ImageAssetCategory.logo
          ? [...action.payload] // for logos, replace (1 logo for v1)
          : state.data.logos;
      const background_images =
        category === ImageAssetCategory.background_image
          ? [...state.data.background_images, ...action.payload]
          : state.data.background_images;
      const image_assets =
        category === ImageAssetCategory.asset
          ? [...state.data.image_assets, ...action.payload]
          : state.data.image_assets;
      return {
        ...state,
        data: {
          ...state.data,
          logos,
          background_images,
          image_assets,
        },
      };
    }
    case "DELETE_IMAGE_ASSET": {
      if (state.status !== "READY") {
        return state;
      }
      const logos =
        action.payload.category === ImageAssetCategory.logo
          ? state.data.logos.filter((asset) => asset.id !== action.payload.id)
          : state.data.logos;
      const background_images =
        action.payload.category === ImageAssetCategory.background_image
          ? state.data.background_images.filter(
              (asset) => asset.id !== action.payload.id
            )
          : state.data.background_images;
      const image_assets =
        action.payload.category === ImageAssetCategory.asset
          ? state.data.image_assets.filter(
              (asset) => asset.id !== action.payload.id
            )
          : state.data.image_assets;
      return {
        ...state,
        data: {
          ...state.data,
          logos,
          background_images,
          image_assets,
        },
      };
    }
    case "SET_IS_DIRTY":
      return {
        ...state,
        isDirty: action.payload,
      };
    default:
      assertNever(action);
      return state;
  }
}

export const BrandStylingContext =
  createContext<BrandStylingContextState>(initialState);
const DispatchContext =
  createContext<React.Dispatch<BrandStylingAction> | null>(null);

export const BrandStylingProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [newStyleElement, setNewStyleElement] =
    useState<HTMLStyleElement | null>(null);
  const [linkFonts, setLinkFonts] = useState<HTMLLinkElement[] | null>(null);

  const activeBrandID = useActiveBrandID();

  const { data, isLoading, isError, refetch } = useBrandStyleQuery();
  useEffect(() => {
    if (!isLoading) {
      return;
    }
    dispatch({
      type: "SET_STATUS",
      payload: { status: "LOADING" },
    });
  }, [isLoading]);
  const handleUserData = useCallback(
    ({
      data,
      isError,
    }: {
      data: BrandStylingResponse | undefined;
      isError: boolean;
    }) => {
      if (!data || isError) {
        return;
      }
      dispatch({
        type: "SET_STYLING",
        payload: data,
      });
    },
    []
  );

  useEffect(() => {
    if (isLoading) {
      return;
    }
    handleUserData({ data, isError });
  }, [data, isError, isLoading, handleUserData]);

  useEffect(() => {
    if (!state.isDirty) {
      return;
    }
    refetch().then(handleUserData);
  }, [state.isDirty, refetch, handleUserData]);

  const removeBrandStyles = () => {
    newStyleElement && newStyleElement.remove();
    linkFonts?.forEach((link) => {
      link.remove();
    });
    setNewStyleElement(null);
    setLinkFonts(null);
  };

  useEffect(() => {
    if (state.status !== "READY") {
      return;
    }

    removeBrandStyles();

    const customFonts = state.data.typography.custom_font_families;
    const styleElement = document.createElement("style");
    const linkElements = customFonts
      .map((fontFace) => {
        if (fontFace?.source_type === FontFaceSourceType.css) {
          styleElement.appendChild(document.createTextNode(fontFace.source));
          return null;
        } else {
          return addStylesheetURL(fontFace.source);
        }
      })
      .filter((link) => !!link);
    document.head.appendChild(styleElement);
    setNewStyleElement(styleElement);
    setLinkFonts(linkElements);
  }, [state]);

  useEffect(() => {
    if (!activeBrandID) {
      return;
    }
    removeBrandStyles();
  }, [activeBrandID]);

  useEffect(() => {
    // unmount
    return () => {
      removeBrandStyles();
    };
  }, []);

  return (
    <DispatchContext.Provider value={useMemo(() => dispatch, [dispatch])}>
      <BrandStylingContext.Provider value={useMemo(() => state, [state])}>
        {children}
      </BrandStylingContext.Provider>
    </DispatchContext.Provider>
  );
};

export function useBrandStyle() {
  const styling = useContext(BrandStylingContext);
  return {
    data: styling.status === "READY" ? styling.data : undefined,
    isLoading: styling.status === "LOADING" || styling.status === "IDLE",
    isError: styling.status === "ERROR",
    fullState: styling,
  };
}

export function useBrandStylingDispatch(): React.Dispatch<BrandStylingAction> {
  return nullthrows(
    useContext(DispatchContext),
    "Brand Styling Context dispatch context is missing"
  );
}

export default BrandStylingContext;
