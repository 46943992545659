import { Extension } from "@tiptap/core";
import "@tiptap/extension-text-style";
import { Editor } from "@tiptap/react";

export const isItalic = (editor: Editor) => {
  return editor.getAttributes("textStyle")?.fontStyle === "italic";
};

export type ItalicOptions = {
  /**
   * The types where italic can be applied
   * @default ['textStyle']
   * @example ['heading', 'paragraph']
   */
  types: string[];
};

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    italic: {
      /**
       * Set the text to italic
       * @example editor.commands.setItalic()
       */
      setItalic: () => ReturnType;

      toggleItalic: () => ReturnType;

      /**
       * Unset the italic mark
       * @example editor.commands.unsetItalic()
       */
      unsetItalic: () => ReturnType;
    };
  }
}

/**
 * This extension allows you to use italic text.
 * @see https://tiptap.dev/api/marks/italic
 */
const Italic = Extension.create<ItalicOptions>({
  name: "italic",

  addOptions() {
    return {
      types: ["textStyle"],
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          fontStyle: {
            default: null,
            parseHTML: (element) => element.style.fontStyle || null,
            renderHTML: (attributes) => {
              if (!attributes.fontStyle) {
                return {};
              }

              return {
                style: `font-style: ${attributes.fontStyle}`,
              };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setItalic:
        () =>
        ({ chain }) => {
          return chain().setMark("textStyle", { fontStyle: "italic" }).run();
        },
      unsetItalic:
        () =>
        ({ chain }) => {
          return chain()
            .setMark("textStyle", { fontStyle: null })
            .removeEmptyTextStyle()
            .run();
        },
    };
  },
});

export default Italic;
