import AdEditorCard from "../AdEditorCard";
import { operations } from "@openapi";
import { Spinner, TextArea } from "@radix-ui/themes";
import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import Cookies from "js-cookie";
import { RefreshCcwIcon } from "lucide-react";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import {
  useAdEditorDispatch,
  useAdEditorSelectedAdState,
} from "~/contexts/AdEditorContext";

type GenCreactiveRecommendationsFromCRResponse =
  operations["ads_api_generate_ad_creative_primary_text"]["responses"]["200"]["content"]["application/json"];

const AdCopyEditCard: React.FC = () => {
  const state = useAdEditorSelectedAdState();
  const dispatch = useAdEditorDispatch();

  const { mutate: generatePrimaryTextMutation, isPending: isGenerating } =
    useMutation<
      GenCreactiveRecommendationsFromCRResponse,
      AxiosError,
      {
        creativeId: string;
      }
    >({
      mutationFn: async ({
        creativeId,
      }): Promise<GenCreactiveRecommendationsFromCRResponse> => {
        const { data } = await axios.post(
          `/api/v1/ads/ad-creative/${creativeId}/generate-primary-text`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": Cookies.get("csrftoken") ?? "",
            },
          }
        );
        return data;
      },
      onSuccess: (response, { creativeId }) => {
        handleChange(response, creativeId);
      },
      onError: (error) => {
        const errorMessage =
          error.status !== 500 && error.response?.data
            ? String(error.response?.data)
            : "Something went wrong";
        console.error("Error generating primary text:", errorMessage);
      },
    });

  const handleRegenerate = () => {
    generatePrimaryTextMutation({ creativeId: state.data.id });
  };
  const handleChange = (value: string, creativeId: string) => {
    dispatch({
      type: "UPDATE_AD_DATA",
      payload: {
        id: creativeId,
        primary_text: value,
      },
    });
  };
  return (
    <AdEditorCard checkable={false} title="Ad Copy">
      <TextArea
        disabled={isGenerating}
        onChange={(event) => handleChange(event.target.value, state.data.id)}
        value={state.data.primary_text}
        maxLength={150}
        required
        placeholder="Primary text to show with the ad"
      />
      <AppButton
        variant="outlined"
        disabled={isGenerating}
        onClick={handleRegenerate}
      >
        <Spinner loading={isGenerating}>
          <RefreshCcwIcon size={20} />
        </Spinner>
        {isGenerating ? "Generating.." : "Regenerate"}
      </AppButton>
    </AdEditorCard>
  );
};

export default AdCopyEditCard;
