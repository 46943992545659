import { components, EmailSectionType } from "@openapi";
import {
  EmailSection,
  NewEmailSection,
} from "~/routes/intern/email_editor/context/EmailEditorContext";
import { EmailEditorViews } from "~/routes/intern/email_editor/context/EmailEditorContext";

export type EmailHeroSectionSchema =
  components["schemas"]["EmailHeroSectionSchema"];
export type EmailHeroProductSectionSchema =
  components["schemas"]["EmailHeroProductSectionSchema"];
export type EmailHeroCollectionSectionSchema =
  components["schemas"]["EmailHeroCollectionSectionSchema"];
export type EmailHeaderSectionSchema =
  components["schemas"]["EmailHeaderSectionSchema"];
export type EmailFooterSectionSchema =
  components["schemas"]["EmailFooterSectionSchema"];
export type EmailBlogSectionSchema =
  components["schemas"]["EmailBlogSectionSchema"];
export type EmailProductsSectionSchema =
  components["schemas"]["EmailProductsSectionSchema"];
export type EmailImageSectionSchema =
  components["schemas"]["EmailImageSectionSchema"];
export type EmailCTACardSectionSchema =
  components["schemas"]["EmailCTACardSectionSchema"];
export type EmailCollectionGridSectionSchema =
  components["schemas"]["EmailCollectionGridSectionSchema"];
export type EmailListicleSectionSchema =
  components["schemas"]["EmailListicleSectionSchema"];
export type EmailSalesEventHeroSectionSchema =
  components["schemas"]["EmailSalesEventHeroSectionSchema"];

export const isNewSection = (
  section: EmailSection
): section is NewEmailSection => {
  return section.type === null;
};

export const isHeroSection = (
  section: EmailSection
): section is EmailHeroSectionSchema => {
  return section.type === EmailSectionType.hero;
};

export const isHeroProductSection = (
  section: EmailSection
): section is EmailHeroProductSectionSchema => {
  return section.type === EmailSectionType.hero_product;
};

export const isHeroCollectionSection = (
  section: EmailSection
): section is EmailHeroCollectionSectionSchema => {
  return section.type === EmailSectionType.hero_collection;
};

export const isHeaderSection = (
  section: EmailSection
): section is EmailHeaderSectionSchema => {
  return section.type === EmailSectionType.header;
};

export const isFooterSection = (
  section: EmailSection
): section is EmailFooterSectionSchema => {
  return section.type === EmailSectionType.footer;
};

export const isBlogSection = (
  section: EmailSection
): section is EmailBlogSectionSchema => {
  return section.type === EmailSectionType.blog;
};

export const isProductsSection = (
  section: EmailSection
): section is EmailProductsSectionSchema => {
  return section.type === EmailSectionType.products;
};

export const isImageSection = (
  section: EmailSection
): section is EmailImageSectionSchema => {
  return section.type === EmailSectionType.image;
};

export const isCtaSection = (
  section: EmailSection
): section is EmailCTACardSectionSchema => {
  return section.type === EmailSectionType.cta_card;
};

export const isCollectionGridSection = (
  section: EmailSection
): section is EmailCollectionGridSectionSchema => {
  return section.type === EmailSectionType.collection_grid;
};

export const isListicleSection = (
  section: EmailSection
): section is EmailListicleSectionSchema => {
  return section.type === EmailSectionType.listicle;
};

export const isSalesEventHeroSection = (
  section: EmailSection
): section is EmailSalesEventHeroSectionSchema => {
  return section.type === EmailSectionType.hero_sales_event;
};

export const convertSectionToEnum = (
  section: EmailSection
): EmailEditorViews => {
  if (section.type === null) {
    return EmailEditorViews.AddBlock;
  }
  if (isHeroSection(section)) {
    return EmailEditorViews.hero;
  }
  if (isHeroProductSection(section)) {
    return EmailEditorViews.hero_product;
  }
  if (isHeroCollectionSection(section)) {
    return EmailEditorViews.hero_collection;
  }
  if (isHeaderSection(section)) {
    return EmailEditorViews.header;
  }
  if (isFooterSection(section)) {
    return EmailEditorViews.footer;
  }
  if (isImageSection(section)) {
    return EmailEditorViews.image;
  }
  if (isProductsSection(section)) {
    return EmailEditorViews.products;
  }
  if (isCtaSection(section)) {
    return EmailEditorViews.cta_card;
  }
  if (isCollectionGridSection(section)) {
    return EmailEditorViews.collection_grid;
  }
  if (isListicleSection(section)) {
    return EmailEditorViews.listicle;
  }
  if (isBlogSection(section)) {
    return EmailEditorViews.blog;
  }
  if (isSalesEventHeroSection(section)) {
    return EmailEditorViews.hero_sales_event;
  }
  throw new Error("Section type not implemented");
};
