import { operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export type EmailCreative =
  operations["emails_api_get_email_creative"]["responses"][200]["content"]["application/json"];

export const key = "emails/email-creative";

const useEmailCreativeQuery = (selectedCreativeId?: string | null) => {
  const { data, error, isLoading } = useQuery({
    queryKey: [key, selectedCreativeId],
    queryFn: async (): Promise<EmailCreative> => {
      const { data } = await axios.get(`/api/v1/${key}`, {
        params: { email_creative_id: selectedCreativeId },
      });
      return data;
    },
    enabled: !!selectedCreativeId,
  });
  return {
    emailCreativeData: data,
    emailCreativeError: error,
    isEmailCreativeLoading: isLoading,
  };
};
export default useEmailCreativeQuery;
