import { Campaign } from "../../hooks/useGetCampaigns";
import { campaignRecommendationTypeContent } from "../recommenderSection/RecommenderCard";
import { Flex, Text, Separator } from "@radix-ui/themes";
import { ArrowRightIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import AppButton from "~/components/core/buttons/AppButton/AppButton";

const Card = styled(Flex)`
  border: 1px solid var(--border-primary);
  border-radius: 32px;
  background-color: var(--primary-white);
  box-shadow: 0px 4px 8px 0px #0000000a;
`;

const Image = styled.img<{
  $aspectRatio: string;
  $roundCorners: "left" | "right";
  height: string;
}>`
  width: 100%;
  height: ${(props) => props.height};
  aspect-ratio: ${(props) => props.$aspectRatio};
  object-fit: cover;
  border-radius: ${(props) =>
    props.$roundCorners === "left" ? "12px 0 0 12px" : "0 12px 12px 0"};
  border: none;
`;

const CampaignDraftCard = ({ campaign }: { campaign: Campaign }) => {
  const navigate = useNavigate();

  if (!campaign?.campaign_recommendation) return null;

  const IconComponent =
    campaignRecommendationTypeContent[
      campaign.campaign_recommendation.item_set_type
    ]?.icon;

  return (
    <Card direction="column" p="16px" width="384px" gap="5">
      <Flex>
        <Image
          height="220px"
          $aspectRatio="0.5"
          $roundCorners="left"
          src={campaign.email_images?.[0] ?? ""}
          alt=""
        />
        <Image
          height="220px"
          $aspectRatio="0.5"
          $roundCorners="right"
          src={campaign.ad_images?.[1] ?? ""}
          alt=""
        />
      </Flex>
      <Flex direction="column" gap="4">
        <Text size="7" weight="medium">
          {
            campaign.campaign_recommendation?.creative_recommendations?.[0]
              ?.title
          }
        </Text>
        <Flex gap="16px" align="center" style={{ color: "#3b3b3b" }}>
          <Flex align="center" gap="10px">
            {IconComponent && (
              <IconComponent
                size={24}
                style={{ color: "var(--primary-vibrant-orange)" }}
              />
            )}
            <Text size="3" weight="medium">
              {
                campaignRecommendationTypeContent[
                  campaign?.campaign_recommendation?.item_set_type
                ]?.title
              }
            </Text>
          </Flex>
          <Separator orientation="vertical" size="4" />
          <Text size="3" weight="medium">
            {(campaign?.products_count || 0) > 1
              ? `${campaign?.products_count || 0} Products`
              : `${campaign?.products_count || 0} Product`}
          </Text>
        </Flex>
      </Flex>
      <AppButton
        variant="outlined"
        onClick={() => navigate(`/campaign/${campaign.id}`)}
        style={{ width: "max-content" }}
      >
        Resume Editing
        <ArrowRightIcon />
      </AppButton>
    </Card>
  );
};

export default CampaignDraftCard;
