import { Flex, Skeleton, Text } from "@radix-ui/themes";
import { PencilIcon, SquareArrowOutUpRightIcon } from "lucide-react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import { currencyFormatter, formatNumberLocale } from "~/utils/helpers";

const EditLink = styled(Link)`
  display: flex;
  align-items: center;
  margin-left: auto;
  text-decoration: none;
  flex-grow: 0;
`;

const dataKeyToLabelMap: Record<string, string> = {
  orders: "Order",
  revenue: "Revenue",
  delivered: "Delivered",
  open_rate: "Open Rate",
  ctr: "CTR",
  spent: "Spent",
  estimated_profit: "Estimated Profit",
  date: "Date",
  connected_to_meta: "Connected to Meta",
};

const stringFields = ["date", "connected_to_meta"];
const currencyFields = ["revenue", "spent", "estimated_profit"];

const CampaignCardContentRow = ({
  title,
  cells,
  editLink,
  currency = "USD",
}: {
  title: string;
  cells: Record<string, any> | undefined;
  editLink?: string;
  currency?: string;
}) => {
  return (
    <Flex gapX="58px" gapY="24px" wrap="wrap">
      <Flex width="250px" gap="15px" align="center">
        <Skeleton
          width="48px"
          height="48px"
          style={{ borderRadius: "8px", flexShrink: 0 }}
        />
        <Text weight="medium">{title}</Text>
        <SquareArrowOutUpRightIcon
          width="20px"
          height="20px"
          color="var(--text-link)"
        />
      </Flex>
      {cells &&
        Object.entries(cells).map(([key, value]) => (
          <Flex gap="3px" key={key} direction="column">
            <Text size="2">{dataKeyToLabelMap[key]}</Text>
            {stringFields.includes(key) ? (
              <Text weight="medium">{value}</Text>
            ) : (
              <Text weight="medium">
                {currencyFields.includes(key)
                  ? currencyFormatter(currency).format(value)
                  : formatNumberLocale(value)}
              </Text>
            )}
          </Flex>
        ))}
      {editLink && (
        <EditLink to={editLink}>
          <AppButton radius="large" size="3" variant="outlined">
            <PencilIcon />
            Edit
          </AppButton>
        </EditLink>
      )}
    </Flex>
  );
};

export default CampaignCardContentRow;
