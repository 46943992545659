import BackgroundColorModifier from "./modifiers/BackgroundColorModifier";
import ButtonModifier from "./modifiers/ButtonModifier";
import GeneratedVariantSelector from "./modifiers/GeneratedVariantSelector";
import ProductMetadataModifier from "./modifiers/ProductMetadataModifier";
import ProductsListModifier from "./modifiers/ProductsListModifier";
import TextModifier from "./modifiers/TextModifier";
import { EmailSectionType } from "@openapi";
import { Flex } from "@radix-ui/themes";
import { SparklesIcon } from "lucide-react";
import ProductSelectorDialog from "~/components/campaign/wizard/products/components/productSelector/ProductSelectorDialog";
import ChatMessageContainer from "~/components/chat/ChatMessageContainer";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useInitEmailSectionMutation from "~/hooks/emails/useInitEmailSectionQuery";
import {
  useEmailState,
  useUpdateSectionField,
} from "~/routes/intern/email_editor/context/EmailEditorContext";
import { isProductsSection } from "~/utils/emails/useSectionTypeCheck";

const ProductGridView = () => {
  const activeBrandId = useActiveBrandID();
  const updateSection = useUpdateSectionField();

  const { mutate: initEmailSection } = useInitEmailSectionMutation();

  const { selectedSectionId, sections } = useEmailState();
  const selectedSection = sections.find(
    (section) => section.id === selectedSectionId
  );

  if (!selectedSection) {
    return null;
  }

  if (!isProductsSection(selectedSection)) {
    return (
      <Flex direction="column" gap="24px">
        <Flex gap="16px">
          <ChatMessageContainer message="Would you like to select products manually?" />
        </Flex>

        <Flex justify="end" gap="16px">
          <ProductSelectorDialog
            onConfirm={(products) => {
              initEmailSection({
                brand_id: activeBrandId,
                section_type: EmailSectionType.products,
                id: selectedSection.id,
                index: selectedSection.index,
                generate: false,
                product_ids: Array.from(products.values()).map(
                  (product) => product.product_id
                ),
              });
            }}
            triggerComponent={
              <AppButton variant="outlined" size="3" radius="large">
                Select Manually
              </AppButton>
            }
          />
          <AppButton variant="dark" size="3" radius="large">
            <SparklesIcon />
            AI Assisted
          </AppButton>
        </Flex>
      </Flex>
    );
  }

  const title = selectedSection.title;
  const subtitle = selectedSection.subtitle;
  const products = selectedSection.products;

  return (
    <>
      <GeneratedVariantSelector />

      <ProductsListModifier
        products={products}
        onAddProduct={(products) => {
          initEmailSection({
            brand_id: activeBrandId,
            section_type: EmailSectionType.products,
            id: selectedSection.id,
            index: selectedSection.index,
            generate: false,
            product_ids: Array.from(products.values()).map(
              (product) => product.product_id
            ),
          });
        }}
      />

      <TextModifier
        textElement={title}
        label="Title"
        enabled={title.enabled ?? false}
        fieldName="title.text"
        onSwitchToggle={(enabled) => {
          updateSection({
            sectionId: selectedSection.id,
            field: "title.enabled",
            value: enabled,
          });
        }}
      />
      <TextModifier
        textElement={subtitle}
        label="Subtitle"
        enabled={subtitle.enabled ?? false}
        fieldName="subtitle.text"
        onSwitchToggle={(enabled) => {
          updateSection({
            sectionId: selectedSection.id,
            field: "subtitle.enabled",
            value: enabled,
          });
        }}
      />

      {/* <ButtonModifier
        fieldName="cta_button"
        buttonElement={selectedSection.cta_button}
        onSwitchToggle={(enabled) => {
          updateSection({
            sectionId: selectedSection.id,
            field: "cta_button.enabled",
            value: enabled,
          });
        }}
      /> */}

      <ProductMetadataModifier products={products} />

      <BackgroundColorModifier
        hideSwitch={true}
        palette={selectedSection.palette}
        sectionId={selectedSection.id}
      />
    </>
  );
};

export default ProductGridView;
