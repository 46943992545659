import CampaignWizardCard from "../../../components/campaign/wizard/cards/CampaignWizardCard";
import useCampaignPromotionProps from "./hooks/useCampaignPromotionProps";
import { CampaignType, ItemSetType, ShopifyDiscountValueType } from "@openapi";
import { Flex, SegmentedControl, Text, TextField, Box } from "@radix-ui/themes";
import DraperSegmentControl from "~/components/core/DraperSegmentedControl";
import AppButton from "~/components/core/buttons/AppButton/AppButton";

export const COUPON_DEFAULTS: Record<CampaignType, number | null> = {
  [CampaignType.inventory_clearance]: 15,
  [CampaignType.sales_event]: 25,
  [CampaignType.new_products]: null, // Unused, need this for typing
  [CampaignType.other]: null, // Unused, need this for typing
};

export const ITEM_SET_COUPON_DEFAULTS = {
  [ItemSetType.slower_moving_goods]: 20,
  [ItemSetType.slowest_moving_goods]: 25,
  [ItemSetType.new_products]: null,
  [ItemSetType.best_sellers]: null,
  [ItemSetType.discounted_products]: null,
  [ItemSetType.new_collection]: null,
  [ItemSetType.purchased_together]: null,
  [ItemSetType.new_hero_product]: null,
};

const CampaignPromotion = () => {
  const {
    cardProps,
    inputProps: {
      discountType,
      discountValue,
      setDiscountType,
      setDiscountValue,
      showDiscountUI,
      setShowDiscountUI,
      initDiscountValue,
    },
  } = useCampaignPromotionProps();

  const handleValueChange = (value: number) => {
    if (value < 0) {
      setDiscountValue(null);
      return;
    }
    if (value > 100 && discountType === "percentage") {
      setDiscountValue(100);
      return;
    }
    setDiscountValue(value);
  };

  const handleDiscountTypeChange = (value: ShopifyDiscountValueType) => {
    if (discountValue && value === "percentage" && discountValue > 100) {
      setDiscountValue(null);
    }
    setDiscountType(value);
  };

  const yesNoComponent = (
    <Flex direction={"column"} gap="4">
      <Flex gap="4">
        <AppButton
          radius="large"
          variant="soft"
          onClick={() => setShowDiscountUI(false)}
          style={{
            border: showDiscountUI ? undefined : "1px solid var(--text-link)",
          }}
        >
          No
        </AppButton>
        <AppButton
          radius="large"
          variant="soft"
          onClick={() => setShowDiscountUI(true)}
          style={{
            border: showDiscountUI ? "1px solid var(--text-link)" : undefined,
          }}
        >
          Yes
        </AppButton>
      </Flex>
    </Flex>
  );

  let promotionContent = (
    <Flex direction={"column"} gap="4">
      <Flex align={"start"} gap="4" direction="column">
        {yesNoComponent}

        {showDiscountUI ? (
          <Box>
            <Text size="1">Coupon</Text>
            <TextField.Root
              value={discountValue ?? ""}
              onChange={(e) =>
                handleValueChange(parseInt(e.currentTarget.value))
              }
              type="number"
              style={{
                fontWeight: 500,
                color: "#4C4747",
                fontSize: "16px",
                height: "50px",
                padding: "0px 4px",
                width: "min-content",
                minWidth: "120px",
              }}
              radius="large"
              size="3"
            >
              <TextField.Slot pl="2px" pr="0" side="right">
                <DraperSegmentControl
                  onValueChange={handleDiscountTypeChange}
                  radius="large"
                  value={discountType}
                  $buttonWidth="36px"
                >
                  <SegmentedControl.Item value="percentage">
                    %
                  </SegmentedControl.Item>
                  <SegmentedControl.Item value="fixed_amount">
                    $
                  </SegmentedControl.Item>
                </DraperSegmentControl>
              </TextField.Slot>
            </TextField.Root>
          </Box>
        ) : null}
      </Flex>
    </Flex>
  );

  return (
    <CampaignWizardCard
      {...cardProps}
      subtitle={
        initDiscountValue
          ? `We recommend a ${initDiscountValue}% promotion for this campaign. Do you want to keep this discount?`
          : "Would you like to include a promotion as part of this campaign?"
      }
    >
      <Flex direction="column" gap="24px">
        {promotionContent}
      </Flex>
    </CampaignWizardCard>
  );
};

export default CampaignPromotion;
