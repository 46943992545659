import { useCampaignWizardState } from "../../../../../../../contexts/CampaignWizardContext";
import { useActiveBrandID } from "../../../../../../../contexts/CurrentUserContext";
import {
  ProductsFilters,
  ProductSort,
  ProductSorters,
} from "./useProductsFilters";
import { operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";

type GetProductsType =
  operations["shopify_integration_api_products"]["responses"][200]["content"]["application/json"];
type GetProductsRequestParams =
  operations["shopify_integration_api_products"]["parameters"]["query"];
export type Product =
  operations["shopify_integration_api_products"]["responses"][200]["content"]["application/json"]["products"][0];

type PageData = {
  before: string | null;
  after: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  products: Product[];
};
const useGetFilteredProducts = (
  itemsPerPage: number,
  filters: ProductsFilters,
  sorters: ProductSorters
) => {
  const activeBrandID = useActiveBrandID();
  const { currentStep } = useCampaignWizardState();
  //search is used for forcing a requery even though the queryKey was used before
  const [search, setSearch] = useState(0);
  const [cachedProductsMap, setCachedProductsMap] = useState(
    new Map<Number, PageData>()
  );
  const updatePages = (
    page: number,
    before: string | null,
    after: string | null,
    hasNextPage: boolean,
    hasPreviousPage: boolean,
    products: Product[]
  ) => {
    setCachedProductsMap((prev) => {
      const updatedMap = new Map(prev);
      updatedMap.set(page, {
        before,
        after,
        hasNextPage,
        hasPreviousPage,
        products,
      });
      return updatedMap;
    });
  };

  useEffect(() => {
    setSearch((prev) => prev + 1);
    setCachedProductsMap(new Map<Number, PageData>());
  }, [filters.name, filters.categoryId, sorters.nameSort]);

  useEffect(() => {
    if (cachedProductsMap.has(filters.page)) {
      return;
    }
    setSearch((prev) => prev + 1);
  }, [filters.page]);

  const { data, isLoading, error, isSuccess } = useQuery({
    queryKey: ["shopify-products", search, activeBrandID],
    queryFn: async (): Promise<GetProductsType> => {
      const response = await axios.get("/api/v1/commerce-platform/products", {
        params: {
          brand_id: activeBrandID,
          reverse: sorters.nameSort === ProductSort.DESC,
          sort_by: sorters.nameSort === ProductSort.UNSET ? undefined : "TITLE",
          first: itemsPerPage,
          after: cachedProductsMap.get(filters.previousPage)?.after,
          query:
            filters.categoryId && filters.categoryId !== "all"
              ? `category_id:${filters.categoryId.split("/").at(-1)} AND ${
                  filters.name
                }`
              : filters.name,
        } as GetProductsRequestParams,
      });
      return response.data;
    },
    enabled: !!activeBrandID,
  });
  useEffect(() => {
    if (!isSuccess || error || isLoading) {
      return;
    }
    updatePages(
      filters.page,
      data.page_info.start_cursor,
      data.page_info.end_cursor,
      data.page_info.has_next_page,
      data.page_info.has_previous_page,
      data.products
    );
  }, [isLoading, isSuccess]);

  return useMemo(() => {
    return {
      products: cachedProductsMap.get(filters.page)?.products,
      hasNextPage: cachedProductsMap.get(filters.page)?.hasNextPage,
      hasPreviousPage: cachedProductsMap.get(filters.page)?.hasPreviousPage,
    };
  }, [cachedProductsMap, filters.page]);
};
export default useGetFilteredProducts;
