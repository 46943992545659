import { AdCreative } from "../../../types/ads";
import {
  Flex,
  Heading,
  Text,
  TextField,
  TextArea,
  Spinner,
  Box,
  Card,
} from "@radix-ui/themes";
import React from "react";

interface InternAdCreativeInfoTabProps {
  creative: AdCreative | null;
}

const InternAdCreativeInfoTab: React.FC<InternAdCreativeInfoTabProps> = ({
  creative,
}) => {
  if (!creative) {
    return (
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spinner loading={true} />
      </Box>
    );
  }

  return (
    <Flex direction="column">
      <Heading size="3" style={{ marginBottom: "10px" }}>
        Info
      </Heading>
      <Flex direction="column" gap="0.5rem">
        <label>
          <Text as="div" size="1" mb="1" weight="bold">
            ID
          </Text>
          <TextField.Root value={creative.id} disabled />
        </label>
        <label>
          <Text as="div" size="1" mb="1" weight="bold">
            Created at
          </Text>
          <TextField.Root value={creative.created_at} disabled />
        </label>
        <label>
          <Text as="div" size="1" mb="1" weight="bold">
            Brand
          </Text>
          <TextField.Root value={creative.brand_name} disabled />
        </label>
        <label>
          <Text as="div" size="1" mb="1" weight="bold">
            Campaign ID
          </Text>
          <TextField.Root value={creative.campaign.id} disabled />
        </label>
        <label>
          <Text as="div" size="1" mb="1" weight="bold">
            Campaign Brief ID
          </Text>
          <TextField.Root value={creative.campaign_brief_id ?? ""} disabled />
        </label>
        <label>
          <Text as="div" size="1" mb="1" weight="bold">
            Scheduled offset
          </Text>
          <TextField.Root
            value={`${creative.campaign_scheduling_offset} days`}
            disabled
          />
        </label>
        <label>
          <Text as="div" size="1" mb="1" weight="bold">
            Media Templates
          </Text>
          {creative.media_templates.map((template, index) => (
            <Card key={`template-${index}`}>
              <Flex align="center" gap="3">
                <Flex direction="column" gap="1">
                  <Heading size="2">{template.name}</Heading>
                  <Flex gap="2">
                    <Heading size="1">{template.type}</Heading>
                    <Text size="1">{"|"}</Text>
                    <Heading size="1">{template.category}</Heading>
                  </Flex>
                </Flex>
              </Flex>
            </Card>
          ))}
        </label>
        <label>
          <Text as="div" size="1" mb="1" weight="bold">
            Recommended Items
          </Text>
          <Flex direction="column" gap="3">
            {creative.campaign_recommended_items.map((item, index) => (
              <Card key={`recommended-item-${index}`}>
                <Flex align="center" gap="2">
                  <img
                    src={item.image_url ?? ""}
                    alt={item.title}
                    style={{
                      border: "solid 0.5px",
                      borderColor: "gray",
                      borderRadius: "8px",
                      height: "40px",
                      width: "40px",
                    }}
                  />
                  <Flex direction="column">
                    <Heading size="1">{item.title}</Heading>
                    <Text size="1">{item.commerce_platform_item_id}</Text>
                  </Flex>
                </Flex>
              </Card>
            ))}
          </Flex>
        </label>
        <label>
          <Text as="div" size="1" mb="1" weight="bold">
            Headline
          </Text>
          <TextArea
            defaultValue={creative.headline}
            disabled={true}
            resize="vertical"
            style={{
              minHeight: "130px",
            }}
          />
        </label>
        <label>
          <Text as="div" size="1" mb="1" weight="bold">
            Primary text
          </Text>
          <TextArea
            defaultValue={creative.primary_text}
            disabled={true}
            resize="vertical"
            style={{
              minHeight: "130px",
            }}
          />
        </label>
        <label>
          <Text as="div" size="1" mb="1" weight="bold">
            Description
          </Text>
          <TextArea
            defaultValue={creative.description}
            disabled={true}
            resize="vertical"
            style={{
              minHeight: "130px",
            }}
          />
        </label>
      </Flex>
    </Flex>
  );
};

export default InternAdCreativeInfoTab;
