import { CampaignStatus } from "@openapi";
import { Flex, Spinner, Text } from "@radix-ui/themes";
import { PlusIcon } from "lucide-react";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import styled from "styled-components";
import MyCampaignsZeroState from "~/components/campaign/my-campaigns/MyCampaignsZeroState";
import StatusFilters from "~/components/campaign/my-campaigns/StatusFilters";
import CampaignCard from "~/components/campaign/my-campaigns/card/CampaignCard";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import BackgroundGradient from "~/components/core/layout/BackgroundGradient";
import useGetCampaignList from "~/hooks/campaign/useGetCampaignList";

const Root = styled(Flex)`
  padding: 24px;
  width: 100%;
`;

const MyCampaigns = () => {
  const [selectedStatus, setSelectedStatus] = useState<
    CampaignStatus | null | undefined
  >(null);
  const {
    campaignsQuery: { data, hasNextPage, fetchNextPage, isFetched },
    emailsMetricsQuery,
    adMetricsQuery,
  } = useGetCampaignList({
    status: selectedStatus,
  });

  const [hadResults, setHadResults] = useState(false);
  useEffect(() => {
    if (data?.pages?.[0]?.campaigns?.length) {
      setHadResults(true);
    }
  }, [data]);

  if (isFetched && !hadResults) {
    // prevent the zero state from appearing for filtered results
    return <MyCampaignsZeroState />;
  }

  return (
    <Root gap="24px" direction="column" align="center">
      <BackgroundGradient variant="3" />
      <Flex direction="column" gap="24px" minWidth="80%">
        <Flex justify="between" align="center">
          <Text size="6" weight="bold">
            My Campaigns
          </Text>
          <Link to="/campaigns/wizard/new">
            <AppButton size="3">
              <PlusIcon /> New campaign
            </AppButton>
          </Link>
        </Flex>

        <StatusFilters
          setSelectedStatus={setSelectedStatus}
          selectedStatus={selectedStatus}
        />
        <InfiniteScroll
          style={{
            overflow: "visible",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            gap: "24px",
          }}
          next={fetchNextPage}
          hasMore={hasNextPage ?? false}
          dataLength={data?.pages.flatMap((page) => page.campaigns).length ?? 0}
          loader={
            <Flex height="100%" width="100%" justify="center" align="center">
              <Spinner />
            </Flex>
          }
          scrollThreshold={"90%"}
        >
          {data?.pages?.flatMap((page) =>
            page.campaigns.map((campaign) => (
              <CampaignCard
                campaign={campaign}
                key={campaign.id}
                emailMetrics={emailsMetricsQuery.data ?? {}}
                adMetrics={adMetricsQuery.data ?? {}}
              />
            ))
          )}
        </InfiniteScroll>
      </Flex>
    </Root>
  );
};

export default MyCampaigns;
