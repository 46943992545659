import CloseIconButton from "../../../core/buttons/CloseIconButton";
import { CheckIcon, MagnifyingGlassIcon } from "@radix-ui/react-icons";
import {
  Checkbox,
  Dialog,
  Flex,
  ScrollArea,
  Text,
  TextField,
} from "@radix-ui/themes";
import { useEffect, useState } from "react";
import styled from "styled-components";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import BrandAsset from "~/components/style-library/assets/BrandAsset";
import BrandImageAsset, {
  ImageAssetSchema,
} from "~/components/style-library/assets/BrandImageAsset";
import { useBrandStyle } from "~/contexts/BrandStylingContext";

const HoverOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }
`;

const AssetSelectorDialog = ({
  isDialogOpen,
  onToggleDialogOpen,
  selectedAssets,
  setSelectedAssets,
  relatedAssets,
  setRelatedAsset,
}: {
  isDialogOpen: boolean;
  onToggleDialogOpen: (open: boolean) => void;
  selectedAssets: ImageAssetSchema[];
  setSelectedAssets?: (asset: ImageAssetSchema[]) => void;
  relatedAssets?: string[];
  setRelatedAsset?: (url: string) => void;
}) => {
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState<ImageAssetSchema[]>([]);
  const { data: brandStyling, isError } = useBrandStyle();

  const logoAssets = brandStyling?.logos ?? [];
  const backgroundAssets = brandStyling?.background_images ?? [];
  const imageAssets = brandStyling?.image_assets ?? [];
  const allAssets = [...logoAssets, ...backgroundAssets, ...imageAssets];

  useEffect(() => {
    isDialogOpen && setSelected(selectedAssets);
  }, [isDialogOpen]);

  let relatedAssetsComponent = null;
  if (relatedAssets && relatedAssets.length > 0 && setRelatedAsset) {
    relatedAssetsComponent = (
      <Flex direction="column" gap="2" mb="24px">
        <Text size="3" weight="medium">
          Related Assets
        </Text>
        <Flex wrap="wrap" gap="24px">
          {relatedAssets.map((url) => (
            <div
              onClick={() => setRelatedAsset(url)}
              style={{
                position: "relative",
                borderRadius: "8px",
                overflow: "hidden",
              }}
            >
              <BrandImageAsset url={url} />
              <HoverOverlay>
                <CheckIcon color="white" width="24" height="24" />
              </HoverOverlay>
            </div>
          ))}
        </Flex>
      </Flex>
    );
  }

  return (
    <Dialog.Root open={isDialogOpen} onOpenChange={onToggleDialogOpen}>
      <Dialog.Content maxWidth="816px" style={{ padding: 0 }}>
        <Flex
          justify="between"
          align="center"
          p="16px"
          pb="12px"
          style={{ borderBottom: "1px solid #E2E2E2" }}
        >
          <Dialog.Title size="4" weight="medium" mb="0">
            Add from asset library
          </Dialog.Title>
          <Dialog.Close>
            <CloseIconButton />
          </Dialog.Close>
        </Flex>
        <Flex direction="column" p="24px" pr="0">
          <TextField.Root
            mb="16px"
            value={search}
            onChange={(event) => setSearch(event.currentTarget.value)}
            style={{ backgroundColor: "#F1F1F0", width: "calc(100% - 24px)" }}
            radius="large"
            color="teal"
            variant="soft"
            placeholder="Search…"
          >
            <TextField.Slot>
              <MagnifyingGlassIcon height="16" width="16" />
            </TextField.Slot>
          </TextField.Root>
          <ScrollArea>
            {relatedAssetsComponent}
            {relatedAssetsComponent ? (
              <Text size="3" weight="medium">
                Library Assets
              </Text>
            ) : null}
            <Flex pr="16px" wrap="wrap" gap="24px" maxHeight="304px">
              {allAssets.length === 0 && <Text>No assets found</Text>}
              {isError && <Text>Failed to fetch assets</Text>}

              {allAssets
                .filter((asset) =>
                  asset.original_filename
                    .toLowerCase()
                    .includes(search.toLowerCase())
                )
                .map((asset) => (
                  <BrandAsset
                    key={asset.id}
                    asset={asset}
                    overlay={
                      setRelatedAsset ? (
                        <HoverOverlay>
                          <CheckIcon color="white" width="24" height="24" />
                        </HoverOverlay>
                      ) : !!selected.find((file) => file.id === asset.id) ? (
                        <Checkbox
                          size="3"
                          color="gray"
                          highContrast
                          checked
                          style={{
                            position: "absolute",
                            bottom: "8px",
                            right: "8px",
                          }}
                        />
                      ) : undefined
                    }
                    onClick={() => {
                      if (setRelatedAsset) {
                        setRelatedAsset(asset.url);
                      } else {
                        !!selected.find((file) => file.id === asset.id)
                          ? setSelected(
                              selected.filter((file) => file.id !== asset.id)
                            )
                          : setSelected([...selected, asset]);
                      }
                    }}
                  />
                ))}
            </Flex>
          </ScrollArea>
        </Flex>
        {!setRelatedAsset ? (
          <Flex p="24px" justify="end">
            <Flex gap="3">
              <Dialog.Close>
                <AppButton variant="outlined" size="3" radius="large">
                  Cancel
                </AppButton>
              </Dialog.Close>
              <Dialog.Close>
                <AppButton
                  onClick={() => setSelectedAssets?.(selected)}
                  variant="dark"
                  size="3"
                  radius="large"
                >
                  Add
                </AppButton>
              </Dialog.Close>
            </Flex>
          </Flex>
        ) : null}
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default AssetSelectorDialog;
