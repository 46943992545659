import CurrentUser from "../auth/CurrentUser";
import { Box, Flex } from "@radix-ui/themes";

export default function SidePane({
  color,
  header,
  navigation,
}: {
  color?: string;
  header: React.ReactNode;
  navigation: React.ReactNode;
}) {
  return (
    <Flex
      id="root-sidepanel"
      direction="column"
      align="center"
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        bottom: "0",
        overflowY: "auto",
        padding: "62px 40px",
        zIndex: 2,
        flexShrink: 0,
      }}
    >
      {header}
      {navigation}

      <CurrentUser />
    </Flex>
  );
}
