import RichTextToolbar from "./RichTextToolbar";
import { Box, Separator } from "@radix-ui/themes";
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline";
import { EditorProvider } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import React from "react";
import styled from "styled-components";

const extensions = [StarterKit, Underline, TextStyle];

const RichTextContainer = styled(Box)<{}>`
  border: 1px solid var(--border-primary);
  border-radius: 10px;
  & div[contenteditable] {
    min-height: 80px;
    max-height: 300px;
    overflow-y: auto;
  }
  [contenteditable]:focus {
    outline: 0px solid transparent;
  }
  & p {
    font-size: 16px;
  }
`;

type RichTextAreaProps = {
  value: string;
  fontSize: number;
  onChange: (value: string) => void;
  onFontSizeChange: (value: number) => void;
} & Omit<React.ComponentProps<typeof RichTextContainer>, "onChange">;

const RichTextArea: React.FC<RichTextAreaProps> = ({
  value,
  fontSize,
  onChange,
  onFontSizeChange,
}) => {
  return (
    <RichTextContainer p="3">
      <EditorProvider
        slotBefore={
          <>
            <RichTextToolbar
              fontSize={fontSize}
              onFontSizeChange={onFontSizeChange}
            />
            <Separator
              size={"1"}
              style={{
                width: "auto",
                margin: "var(--space-2) calc(-1 * var(--space-3))",
              }}
              orientation="horizontal"
            />
          </>
        }
        extensions={extensions}
        content={value}
        onUpdate={(update) => {
          onChange(update.editor.getHTML());
        }}
      />
    </RichTextContainer>
  );
};

export default RichTextArea;
