import SelectedButton from "../../../components/campaign/wizard/audience/common/SelectedButton";
import CampaignWizardCard from "../../../components/campaign/wizard/cards/CampaignWizardCard";
import CategorizedSelect from "../../../components/campaign/wizard/inputs/CategorizedSelect";
import {
  useCampaignWizardDispatch,
  useCampaignWizardState,
} from "../../../contexts/CampaignWizardContext";
import useCenterCampaignCard from "../../../hooks/campaign/useCenterCampaignCard";
import { CDPAudienceType, CDPType, operations } from "@openapi";
import { Flex } from "@radix-ui/themes";
import { PlusIcon } from "lucide-react";
import { useEffect, useState } from "react";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import useCDPAudiencesQuery from "~/hooks/cdp/useCDPAudiencesQuery";

export type GetAudiencesData =
  operations["cdp_api_cdps"]["responses"][200]["content"]["application/json"];
export type Audience =
  operations["cdp_api_cdps"]["responses"][200]["content"]["application/json"][0]["audiences"][0];

const CampaignAudience = () => {
  const dispatch = useCampaignWizardDispatch();
  const { centerCurrentCard } = useCenterCampaignCard();

  const { wizardType } = useCampaignWizardState();
  const { audiencesData: data, isLoading } = useCDPAudiencesQuery();

  const [selectedAudiences, setSelectedAudiences] = useState<
    Map<string, { audienceType: string; cdpType: string; audienceName: string }>
  >(new Map());

  const [selectingAudiences, setSelectingAudiences] = useState(true);

  useEffect(() => {
    dispatch({
      type: "UPDATE_CAMPAIGN_DATA",
      payload: {
        audiences: Array.from(selectedAudiences).map(
          ([id, { audienceName, cdpType, audienceType }]) => ({
            audience_name: audienceName,
            cdp_type: cdpType.toLowerCase() as CDPType,
            audience_type: audienceType.toLowerCase() as CDPAudienceType,
            audience_id: id,
          })
        ),
      },
    });
  }, [selectedAudiences, wizardType, dispatch]);

  // Actions
  const handleSelect = (
    id: string,
    name: string,
    cdpType: string,
    audienceType: string
  ) => {
    if (selectedAudiences.has(id)) {
      return;
    }
    setSelectedAudiences((prev) => {
      const updatedMap = new Map(prev);
      updatedMap.set(id, { audienceName: name, cdpType, audienceType });
      return updatedMap;
    });
    centerCurrentCard();
  };
  const handleDeselect = (id: string) => {
    if (!selectedAudiences.has(id)) {
      return;
    }
    setSelectedAudiences((prev) => {
      const updatedMap = new Map(prev);
      updatedMap.delete(id);
      return updatedMap;
    });
    centerCurrentCard();
  };

  return (
    <CampaignWizardCard
      badgeTitle="Audience"
      subtitle="To optimize your campaign's effectiveness, please select the appropriate audience segment for this campaign."
      nextButtonProps={{
        text: "Looks good",
        disabled: !selectedAudiences.size,
      }}
    >
      <Flex direction="row" gap="2" wrap="wrap">
        {Array.from(selectedAudiences).map(([id, audience], index) => (
          <SelectedButton
            key={index}
            id={id}
            onClick={handleDeselect}
            text={audience.audienceName}
          />
        ))}
        <AppButton
          onClick={() => {
            centerCurrentCard();
            setSelectingAudiences((prev) => !prev);
          }}
          variant="soft"
          size="3"
          style={{
            border: selectedAudiences
              ? "1px solid var(--text-link)"
              : undefined,
          }}
        >
          <PlusIcon />
          Add audience
        </AppButton>
      </Flex>

      {selectingAudiences && (
        <CategorizedSelect
          data={data}
          onSelect={handleSelect}
          loading={isLoading}
        />
      )}
    </CampaignWizardCard>
  );
};

export default CampaignAudience;
