import {
  Card,
  Flex,
  Grid,
  IconButton,
  Separator,
  Text,
} from "@radix-ui/themes";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  PencilIcon,
  StarsIcon,
} from "lucide-react";
import { useCallback } from "react";
import styled from "styled-components";
import MarkAvatar from "~/components/core/avatars/MarkAvatar";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import TextAreaWithLabel from "~/components/core/inputs/TextAreaWithLabel";
import TextFieldWithLabel from "~/components/core/inputs/TextFieldWithLabel";
import AudienceDialog from "~/components/dialogs/AudienceDialog";
import useEmailRegenerateMutation from "~/hooks/emails/useEmailRegenerateMutation";
import {
  useEmailState,
  useSetAudiences,
  useUpdateEmailField,
} from "~/routes/intern/email_editor/context/EmailEditorContext";

const TextContainer = styled(Flex)`
  box-shadow: 0px 12px 48px 0px #00000014;
  border: 1px solid #ddd7d7;
  border-radius: 6px 24px 24px 24px;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  padding: 20px;
`;

const GenerateButton = styled(AppButton)`
  font-weight: 500;
  font-size: 16px;
  width: max-content;
`;

const SectionSeparator = styled(Separator)`
  width: 100%;
`;

const DescriptionText = styled(Text)`
  color: var(--text-tertiary);
`;

const SectionTitle = styled(Text)`
  color: var(--text-secondary);
`;

const VariantSelector = styled(Grid)`
  gap: 8px;
`;

const Pill = styled(Flex)`
  border-radius: 12px;
  background: rgba(223, 244, 245, 1);
  color: rgba(76, 71, 71, 1);
  font-weight: 500;
  padding: 6px 12px;
  background-color: #dff4f5;
`;

const GenerateVariantView = () => {
  const { name, subtitle, audiences, id: emailCreativeId } = useEmailState();
  const updateEmailField = useUpdateEmailField();
  const setAudiences = useSetAudiences();

  const { regenerateEmail, isLoading } = useEmailRegenerateMutation({
    onSuccess: (data) => {
      updateEmailField({ field: "sections", value: data.sections });
    },
  });

  const onGenerateEmail = useCallback(() => {
    regenerateEmail({ email_creative_id: emailCreativeId });
  }, [regenerateEmail, emailCreativeId]);

  return (
    <Flex direction="column" gap="24px">
      <Flex gap="16px">
        <MarkAvatar />
        <TextContainer>
          Select any block you want modify, or generate a new option.
        </TextContainer>
      </Flex>
      <GenerateButton
        radius="large"
        variant="outlined"
        onClick={onGenerateEmail}
        disabled={isLoading}
      >
        <StarsIcon strokeWidth={1.5} size={20} />{" "}
        {isLoading ? "Generating" : "Generate"} another variant
      </GenerateButton>
      <Flex direction="column" gap="16px">
        <SectionTitle weight="medium">Generated Variant</SectionTitle>
        <VariantSelector columns="2">
          <AppButton radius="large" variant="soft">
            <ChevronLeftIcon /> Previous
          </AppButton>
          <AppButton radius="large" variant="soft">
            Next <ChevronRightIcon />
          </AppButton>
        </VariantSelector>
      </Flex>
      <SectionSeparator />
      <TextFieldWithLabel
        label="Email Subject"
        value={name}
        onChange={(e) =>
          updateEmailField({ field: "name", value: e.target.value })
        }
      />
      <Flex gap="8px" direction="column">
        <TextAreaWithLabel
          labelColor="var(--text-tertiary)"
          label="Preheader Text"
          value={subtitle}
          onChange={(e) =>
            updateEmailField({ field: "subtitle", value: e.target.value })
          }
        />
        <DescriptionText size="2" weight="regular">
          A preheader is the short summary text that follows the subject line
          when viewing an email from the inbox.
        </DescriptionText>
      </Flex>

      <Card style={{ padding: "16px", position: "relative" }}>
        <AudienceDialog
          initialAudiences={audiences}
          trigger={
            <IconButton
              style={{
                position: "absolute",
                top: "16px",
                right: "16px",
              }}
              color="gray"
              variant="ghost"
            >
              <PencilIcon size={20} />
            </IconButton>
          }
          onConfirm={(audiences) => {
            setAudiences(audiences);
          }}
        />
        <Flex direction="column" gap="8px">
          <Text size="2" weight="medium">
            Target Audience
          </Text>
          {audiences.length > 0 ? (
            <Flex gap="8px" wrap="wrap">
              {audiences.map((audience) => (
                <Pill>{audience.audience_name}</Pill>
              ))}
            </Flex>
          ) : (
            <Flex gap="8px" wrap="wrap">
              <Pill style={{ backgroundColor: "#FFCCCB" }}>
                No audiences selected
              </Pill>
            </Flex>
          )}
        </Flex>
      </Card>
    </Flex>
  );
};

export default GenerateVariantView;
