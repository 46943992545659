import { getViewValues } from "../../layout/EmailEditorAside";
import { EmailSectionType } from "@openapi";
import { Flex, Grid, Text } from "@radix-ui/themes";
import { useState } from "react";
import styled from "styled-components";
import AssetSelectorDialog from "~/components/campaign/wizard/dialogs/AssetSelectorDialog";
import ProductSelectorDialog from "~/components/campaign/wizard/products/components/productSelector/ProductSelectorDialog";
import MarkAvatar from "~/components/core/avatars/MarkAvatar";
import ArticleSelectorDialog from "~/components/dialogs/ArticleSelectorDialog";
import CollectionSelectorDialog from "~/components/dialogs/CollectionSelectorDialog";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useInitEmailSectionMutation from "~/hooks/emails/useInitEmailSectionQuery";
import {
  EmailEditorViews,
  usePushEditorView,
  useEmailState,
} from "~/routes/intern/email_editor/context/EmailEditorContext";

const TextContainer = styled(Flex)`
  box-shadow: 0px 12px 48px 0px #00000014;
  border: 1px solid #ddd7d7;
  border-radius: 6px 24px 24px 24px;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  padding: 20px;
`;

const BlockContainer = styled(Flex)<{ isPending: boolean }>`
  padding: 24px 16px 24px 16px;
  border: 1px solid#ddd7d7;
  border-radius: 12px;
  gap: 8px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: ${({ isPending }) => (isPending ? "not-allowed" : "pointer")};
  transition: all 0.2s ease;
  background-color: ${({ isPending }) =>
    isPending ? "rgba(0,0,0,0.1)" : "white"};
  &:hover {
    border: 1px solid#9f9594;
    box-shadow: 0px 2px 4px 0px #0000000a;
    box-shadow: 0px 4px 16px 0px #00000014;
  }
`;

const AddBlockView = () => {
  const activeBrandId = useActiveBrandID();
  const pushView = usePushEditorView();
  const { selectedSectionId, sections } = useEmailState();
  const selectedSection = sections.find(
    (section) => section.id === selectedSectionId
  );

  const [isChangeImageDialogOpen, setIsChangeImageDialogOpen] = useState(false);

  const { mutate: initEmailSection, isPending } = useInitEmailSectionMutation();

  if (!selectedSection) {
    return null;
  }

  return (
    <Flex direction="column" gap="24px" mb="24px">
      <Flex gap="16px">
        <MarkAvatar />
        <TextContainer>
          Select what type of block you want to insert
        </TextContainer>
      </Flex>
      <Grid gap="16px" columns="2">
        {[
          EmailEditorViews.hero,
          EmailEditorViews.products,
          EmailEditorViews.image,
          EmailEditorViews.cta_card,
          EmailEditorViews.hero_product,
          EmailEditorViews.hero_collection,
          EmailEditorViews.collection_grid,
          EmailEditorViews.listicle,
          EmailEditorViews.blog,
        ].map((view) => {
          const { name, Icon } = getViewValues(view);

          let content = (
            <>
              {Icon && <Icon />}
              <Text align="center">{name}</Text>
            </>
          );

          if (view === EmailEditorViews.hero_product) {
            return (
              <ProductSelectorDialog
                onSelectSingleProduct={(product) => {
                  initEmailSection({
                    brand_id: activeBrandId,
                    section_type: EmailSectionType.hero_product,
                    id: selectedSection.id,
                    index: selectedSection.index,
                    generate: false,
                    product_ids: [product.product_id],
                  });
                }}
                triggerComponent={
                  <BlockContainer isPending={isPending} key={view}>
                    {content}
                  </BlockContainer>
                }
              />
            );
          } else if (
            view === EmailEditorViews.collection_grid ||
            view === EmailEditorViews.hero_collection
          ) {
            return (
              <CollectionSelectorDialog
                onConfirm={(collectionIds) => {
                  initEmailSection({
                    brand_id: activeBrandId,
                    section_type:
                      view === EmailEditorViews.hero_collection
                        ? EmailSectionType.hero_collection
                        : EmailSectionType.collection_grid,
                    id: selectedSection.id,
                    index: selectedSection.index,
                    generate: false,
                    collection_ids: collectionIds,
                  });
                }}
                triggerComponent={
                  <BlockContainer isPending={isPending} key={view}>
                    {content}
                  </BlockContainer>
                }
                limit={view === EmailSectionType.collection_grid ? 4 : 1}
              />
            );
          } else if (view === EmailEditorViews.blog) {
            return (
              <ArticleSelectorDialog
                onConfirm={(articleIds) => {
                  initEmailSection({
                    brand_id: activeBrandId,
                    section_type: EmailSectionType.blog,
                    id: selectedSection.id,
                    index: selectedSection.index,
                    generate: false,
                    article_ids: articleIds,
                  });
                }}
                triggerComponent={
                  <BlockContainer isPending={isPending} key={view}>
                    {content}
                  </BlockContainer>
                }
                limit={3}
              />
            );
          }

          return (
            <BlockContainer
              key={view}
              isPending={isPending}
              onClick={() => {
                if (view === EmailEditorViews.image) {
                  setIsChangeImageDialogOpen(true);
                } else {
                  pushView(view as EmailEditorViews);
                }
              }}
            >
              {content}
            </BlockContainer>
          );

          return null;
        })}
      </Grid>

      <AssetSelectorDialog
        isDialogOpen={isChangeImageDialogOpen}
        onToggleDialogOpen={setIsChangeImageDialogOpen}
        selectedAssets={[]}
        setSelectedAssets={(newImages) => {
          initEmailSection({
            brand_id: activeBrandId,
            section_type: EmailSectionType.image,
            id: selectedSection.id,
            index: selectedSection.index,
            generate: false,
            asset_id: newImages[0].id,
          });
        }}
      />
    </Flex>
  );
};

export default AddBlockView;
