import { operations } from "../../../openapi";
import { BrandStylingResponse } from "../data/useBrandStyleQuery";
import { useQueryClient } from "@tanstack/react-query";
import { useDraperApiPostMutation } from "~/utils/useDraperMutation";

type UpdateBrandColorPaletteResponse =
  operations["brand_api_update_color_palette"]["responses"][200]["content"]["application/json"];

type UpdateBrandColorPaletteRequestData =
  operations["brand_api_update_color_palette"]["requestBody"]["content"]["application/json"];

const useBrandColorPaletteUpdateMutation = ({
  brandID,
  onSuccess,
  onError,
}: {
  brandID: string;
  onSuccess?: (data: UpdateBrandColorPaletteResponse) => void;
  onError?: (error: Error) => void;
}) => {
  const queryClient = useQueryClient();

  const updateBrandColorPalette = useDraperApiPostMutation<
    UpdateBrandColorPaletteResponse,
    UpdateBrandColorPaletteRequestData
  >({
    mutationKey: ["brand-color-palette-update"],
    path: `/brand/${brandID}/stylebook/color_palette`,
    onError: (error) => {
      onError?.(error);
    },
    onSuccess: (data) => {
      onSuccess?.(data);
    },
    onMutate: (data) => {
      const prevStyle = queryClient.getQueryData<BrandStylingResponse>([
        "brand-style",
        brandID,
      ]);

      if (!prevStyle) return;

      const newColorPaletteSet = {
        ...prevStyle.color_palette_set,
      };

      if (
        data.id === newColorPaletteSet.primary?.id &&
        newColorPaletteSet.primary !== null
      ) {
        const palette = newColorPaletteSet.primary;
        if (data.background !== null) {
          palette.background = data.background;
        }
        if (data.foreground !== null) {
          palette.foreground = data.foreground;
        }
        if (data.accent !== null) {
          palette.accent = data.accent;
        }
      } else if (
        data.id === newColorPaletteSet.alternative_1?.id &&
        newColorPaletteSet.alternative_1 !== null
      ) {
        const palette = newColorPaletteSet.alternative_1;
        if (data.background !== null) {
          palette.background = data.background;
        }
        if (data.foreground !== null) {
          palette.foreground = data.foreground;
        }
        if (data.accent !== null) {
          palette.accent = data.accent;
        }
      } else if (
        data.id === newColorPaletteSet.alternative_2?.id &&
        newColorPaletteSet.alternative_2 !== null
      ) {
        const palette = newColorPaletteSet.alternative_2;
        if (data.background !== null) {
          palette.background = data.background;
        }
        if (data.foreground !== null) {
          palette.foreground = data.foreground;
        }
        if (data.accent !== null) {
          palette.accent = data.accent;
        }
      } else if (
        data.id === newColorPaletteSet.alternative_3?.id &&
        newColorPaletteSet.alternative_3 !== null
      ) {
        const palette = newColorPaletteSet.alternative_3;
        if (data.background !== null) {
          palette.background = data.background;
        }
        if (data.foreground !== null) {
          palette.foreground = data.foreground;
        }
        if (data.accent !== null) {
          palette.accent = data.accent;
        }
      }

      queryClient.setQueryData(["brand-style", brandID], {
        ...prevStyle,
        color_palette_set: newColorPaletteSet,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["brand-style", brandID],
      });
    },
  });

  return {
    updateBrandColorPalette: updateBrandColorPalette.mutate,
    isLoading: updateBrandColorPalette.isPending,
  };
};

export default useBrandColorPaletteUpdateMutation;
