import StyleLibraryCardContent from "../StyleLibraryCardContent";
import ColorBox from "./ColorBox";
import { components } from "@openapi";
import { Flex, Text } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import LoadingDots from "~/components/campaign/wizard/misc/LoadingDots";
import { useBrandStyle } from "~/contexts/BrandStylingContext";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useBrandColorPaletteUpdateMutation from "~/hooks/brand/useBrandColorPaletteUpdateMutation";

type ColorPalette = components["schemas"]["ColorPaletteSchema"];

interface ColorSet {
  key: "primary" | "alternative_1" | "alternative_2" | "alternative_3";
  title: string;
  palette?: ColorPalette;
  isUpdating: boolean;
}

const DefaultColorPaletteSets: ColorSet[] = [
  {
    key: "primary",
    title: "Primary",
    isUpdating: false,
  },
  {
    key: "alternative_1",
    title: "Alternative 1",
    isUpdating: false,
  },
  {
    key: "alternative_2",
    title: "Alternative 2",
    isUpdating: false,
  },
  {
    key: "alternative_3",
    title: "Alternative 3",
    isUpdating: false,
  },
] as const;

const ColorPaletteCardSection = () => {
  const activeBrandID = useActiveBrandID();

  const { data: brandStyle, isLoading } = useBrandStyle();

  const [colorSets, setColorSets] = useState<ColorSet[]>(
    DefaultColorPaletteSets
  );

  useEffect(() => {
    if (!brandStyle?.color_palette_set) {
      return;
    }

    const colorPaletteSet = brandStyle.color_palette_set;
    const newColorSets = DefaultColorPaletteSets.map((colorSet) => {
      const palette = colorPaletteSet[colorSet.key];
      return {
        ...colorSet,
        palette: palette ?? undefined,
      };
    });
    setColorSets(newColorSets);
  }, [brandStyle?.color_palette_set]);

  const { updateBrandColorPalette, isLoading: isUpdating } =
    useBrandColorPaletteUpdateMutation({ brandID: activeBrandID });

  if (isLoading) {
    return <LoadingDots />;
  }

  return (
    <StyleLibraryCardContent title="Color Palette" isLoading={isLoading}>
      <Flex
        direction={"column"}
        gap="4"
        style={{
          background: "white",
        }}
      >
        <Flex direction={"column"} gap="4">
          {colorSets.map((colorSet) => {
            const palette = colorSet.palette;
            if (!palette) {
              // TODO: Allow inserting new color palettes?
              return null;
            }
            return (
              <Flex key={colorSet.key} direction={"column"} gap="2">
                <Text size="4" weight="medium">
                  {colorSet.title}
                </Text>
                {palette && (
                  <Flex direction={"row"} gap="4">
                    <ColorBox
                      color={palette.background}
                      label=" Background"
                      disabled={false}
                      onSaveColor={(color) => {
                        updateBrandColorPalette({
                          ...palette,
                          background: color,
                        });
                      }}
                    />
                    <ColorBox
                      color={palette.foreground}
                      label="Foreground"
                      disabled={false}
                      onSaveColor={(color) => {
                        updateBrandColorPalette({
                          ...palette,
                          foreground: color,
                        });
                      }}
                    />
                    <ColorBox
                      color={palette.accent}
                      label="Accent"
                      disabled={false}
                      onSaveColor={(color) => {
                        updateBrandColorPalette({
                          ...palette,
                          accent: color,
                        });
                      }}
                    />
                  </Flex>
                )}
              </Flex>
            );
          })}
        </Flex>
      </Flex>
    </StyleLibraryCardContent>
  );
};

export default ColorPaletteCardSection;
