import { operations } from "@openapi";
import { useInitSection } from "~/routes/intern/email_editor/context/EmailEditorContext";
import { useDraperApiPostMutation } from "~/utils/useDraperMutation";

type Params =
  operations["emails_api_init_email_section"]["requestBody"]["content"]["application/json"];
type Response =
  operations["emails_api_init_email_section"]["responses"][200]["content"]["application/json"];

const useInitEmailSectionMutation = () => {
  const initSection = useInitSection();

  return useDraperApiPostMutation<Response, Params>({
    path: "/emails/section/init",
    onSuccess: (data) => {
      initSection(data);
    },
  });
};

export default useInitEmailSectionMutation;
