import { Button, ButtonProps } from "@radix-ui/themes";
import { CSSProperties, forwardRef } from "react";
import { fadedDark } from "~/utils/constants";

type ProgressionButtonVariants = "dark" | "light" | "outlined" | "secondary";

const variants: { [key in ProgressionButtonVariants]: CSSProperties } = {
  // TODO: try switching to classes and include hover/disabled states
  dark: {
    background: fadedDark,
    color: "white",
  },
  light: {
    background: "#F3E6D3",
    color: "#332E2E",
  },
  outlined: {
    background: "transparent",
    color: "var(--text-secondary)",
    border: "1px solid var(--border-strong)",
  },
  secondary: {
    background: "#F3E6D3",
    color: "#4C4747",
  },
};

type DraperButtonProps = Omit<ButtonProps, "children" | "variant"> & {
  isSelected?: boolean;
  text?: string | number | React.ReactNode;
  icon?: React.ReactNode;
  variant: ProgressionButtonVariants;
  iconPosition?: "left" | "right";
};

/**
 * @deprecated Use AppButton instead
 */
const DraperButton = forwardRef<HTMLDivElement, DraperButtonProps>(
  (
    {
      text,
      size,
      style: customStyles,
      icon,
      iconPosition = "left",
      variant,
      isSelected,
      ...props
    },
    ref
  ) => {
    const disabledBackground = props.disabled
      ? { background: "#F0EDEB", cursor: "not-allowed" }
      : {};
    return (
      <Button
        {...props}
        ref={ref as React.Ref<HTMLButtonElement>}
        style={{
          border: isSelected ? "2px solid black" : "unset",
          fontSize: "16px",
          fontWeight: 500,
          cursor: "pointer",
          ...variants[variant],
          ...customStyles,
          ...disabledBackground,
        }}
        size={size ?? "4"}
      >
        {iconPosition === "left" && icon}
        {text}
        {iconPosition === "right" && icon}
      </Button>
    );
  }
);

DraperButton.displayName = "DraperButton";

export default DraperButton;
