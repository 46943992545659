import { Text, TextProps } from "@radix-ui/themes";
import React from "react";
import styled from "styled-components";

const TextClamp = styled(Text)<{
  clamp?: number;
}>`
  display: -webkit-box;
  -webkit-line-clamp: ${({ clamp }) => clamp};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  max-width: 100%;
`;

type DraperTextProps = TextProps & {
  clamp?: number;
  colorOverride?: string;
};

const DraperText = React.forwardRef<HTMLDivElement, DraperTextProps>(
  ({ colorOverride, clamp, ...props }, ref) => {
    return (
      <TextClamp
        {...props}
        ref={ref}
        style={{
          color: colorOverride,
          ...(clamp ? { WebkitLineClamp: clamp } : {}),
          ...props.style,
        }}
        clamp={clamp}
      >
        {props.children}
      </TextClamp>
    );
  }
);

export default DraperText;
