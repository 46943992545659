import { AdMediaTemplateCategoryStrings } from "../../../types/ads";
import { AdMediaTemplateCategory, AdMediaType, operations } from "@openapi";
import {
  Box,
  Button,
  Container,
  Dialog,
  Flex,
  Select,
  Text,
  TextField,
} from "@radix-ui/themes";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useState } from "react";

type CreateAdMediaTemplateParams =
  operations["ads_api_create_ad_media_template"]["requestBody"]["content"]["application/json"];

type CreateAdMediaTemplateApiResponse =
  operations["ads_api_create_ad_media_template"]["responses"][200]["content"]["application/json"];

interface InternAdMediaTemplateCreationDialogProps {
  isDialogOpen: boolean;
  setIsDialogOpen: (isOpen: boolean) => void;
  onCreate: () => void;
}

const InternAdMediaTemplateCreationDialog: React.FC<
  InternAdMediaTemplateCreationDialogProps
> = ({ isDialogOpen, setIsDialogOpen, onCreate }) => {
  const [name, setName] = useState<string | null>(null);

  const [category, setCategory] = useState<AdMediaTemplateCategory>(
    AdMediaTemplateCategory.product
  );

  const [isPromotional, setIsPromotional] = useState<boolean>(false);

  const [isCreateAdTemplateLoading, setIsCreateAdTemplateLoading] =
    useState(false);

  const createTemplateMutation = useMutation<
    CreateAdMediaTemplateApiResponse,
    Error,
    CreateAdMediaTemplateParams
  >({
    mutationFn: async (
      params: CreateAdMediaTemplateParams
    ): Promise<CreateAdMediaTemplateApiResponse> => {
      setIsCreateAdTemplateLoading(true);
      const { data } = await axios.post(
        "/api/v1/ads/ad-media-template",
        params,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return data;
    },
    onSuccess: (data) => {
      setIsCreateAdTemplateLoading(false);
      setIsDialogOpen(false);
      alert("Template created successfully!");
      if (data.success) {
        console.log("Template created successfully");
        onCreate();
      } else {
        console.error("Failed to create template");
      }
    },
    onError: (error) => {
      setIsCreateAdTemplateLoading(false);
      alert(error);
      console.error("Error creating template:", error);
    },
  });

  const handleCreateClick = () => {
    if (!name || name.length === 0) {
      alert("Name is required");
      return;
    }
    if (!category || category.length === 0) {
      alert("Category is required");
      return;
    }
    createTemplateMutation.mutate({
      name,
      category,
      type: AdMediaType.image, // Update when we support video templates
      is_promotional: isPromotional,
    });
  };

  return (
    <Dialog.Root open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <Container>
        <Dialog.Content>
          <Dialog.Title>Create Ad Media Template</Dialog.Title>
          <Dialog.Description size="2" mb="4">
            Create a new ad media template that will be used for creating
            AI-generated ad media
          </Dialog.Description>
          <Flex direction="column" gap="3" width="100%">
            <Box>
              <Text as="div" size="1" mb="1" weight="bold">
                Name
              </Text>
              <TextField.Root
                placeholder="Enter a name (i.e Single Product Testimonial)"
                onChange={(event) => setName(event.target.value)}
              />
            </Box>
            <Box>
              <Text as="div" size="1" mb="1" weight="bold">
                Type
              </Text>
              <Select.Root defaultValue={"Image"} disabled>
                <Select.Trigger style={{ width: "100%" }} />
                <Select.Content>
                  <Select.Group>
                    <Select.Item key={"image"} value={"Image"}>
                      {"Image"}
                    </Select.Item>
                  </Select.Group>
                </Select.Content>
              </Select.Root>
            </Box>
            <Box>
              <Text as="div" size="1" mb="1" weight="bold">
                Category
              </Text>
              <Select.Root
                defaultValue={AdMediaTemplateCategory.product}
                onValueChange={(value) =>
                  setCategory(value as AdMediaTemplateCategory)
                }
              >
                <Select.Trigger style={{ width: "100%" }} />
                <Select.Content>
                  <Select.Group>
                    {Object.values(AdMediaTemplateCategory).map((category) => (
                      <Select.Item key={category} value={category}>
                        {AdMediaTemplateCategoryStrings[category]}
                      </Select.Item>
                    ))}
                  </Select.Group>
                </Select.Content>
              </Select.Root>
            </Box>
            <Box>
              <Text as="div" size="1" mb="1" weight="bold">
                Is Promotional?
              </Text>
              <Select.Root
                defaultValue="No"
                onValueChange={(value) => setIsPromotional(value === "Yes")}
              >
                <Select.Trigger style={{ width: "100%" }} />
                <Select.Content>
                  <Select.Group>
                    <Select.Item key="Yes" value="Yes">
                      {"Yes"}
                    </Select.Item>
                    <Select.Item key="No" value="No">
                      {"No"}
                    </Select.Item>
                  </Select.Group>
                </Select.Content>
              </Select.Root>
            </Box>
          </Flex>
          <Flex gap="3" mt="4" justify="end">
            <Dialog.Close>
              <Button variant="outline">Cancel</Button>
            </Dialog.Close>
            <Button
              onClick={handleCreateClick}
              loading={isCreateAdTemplateLoading}
            >
              Create
            </Button>
          </Flex>
        </Dialog.Content>
      </Container>
    </Dialog.Root>
  );
};

export default InternAdMediaTemplateCreationDialog;
