import PlusButton from "../../../audience/common/PlusButton";
import Paginator from "../paginator/Paginator";
import CategorySelector from "./components/CategorySelector";
import ProductsTable from "./components/ProductsTable";
import SearchField from "./components/SearchField";
import useGetFilteredProducts, {
  Product,
} from "./hooks/useGetFilteredProducts";
import useGetCategories from "./hooks/useGetProductCategories";
import { useProductsFilters } from "./hooks/useProductsFilters";
import { Flex, Dialog, IconButton, Text } from "@radix-ui/themes";
import { PlusIcon, X } from "lucide-react";
import { ReactNode, useState, useEffect } from "react";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import { MaybeFeaturedProduct } from "~/views/campaign/wizard/CampaignProducts";

const ITEMS_PER_PAGE = 9;
export const MAX_FEATURED_PRODUCTS = 6;

const ProductSelector = ({
  initialSelectedProducts,
  onConfirm,
  triggerComponent,
  isOpen,
  onOpenChange,
  isFeatureEnabled = false,
  onSelectSingleProduct,
}: {
  initialSelectedProducts?: Map<string, MaybeFeaturedProduct>;
  onConfirm?: (products: Map<string, MaybeFeaturedProduct>) => void;
  triggerComponent?: ReactNode;
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  isFeatureEnabled?: boolean;
  onSelectSingleProduct?: (product: Product) => void; // if set, only one product can be selected
}) => {
  const [selectedProducts, setSelectedProducts] = useState<
    Map<string, MaybeFeaturedProduct>
  >(initialSelectedProducts ?? new Map());
  const { filters, setters, sorters } = useProductsFilters();

  const canOnlySelectOneProduct = onSelectSingleProduct != null;

  useEffect(() => {
    setSelectedProducts(initialSelectedProducts ?? new Map());
  }, [initialSelectedProducts]);

  const {
    products: filteredProducts,
    hasNextPage,
    hasPreviousPage,
  } = useGetFilteredProducts(ITEMS_PER_PAGE, filters, sorters);
  const categories = useGetCategories();

  const selectProduct = (product: Product) => {
    if (canOnlySelectOneProduct) {
      onSelectSingleProduct?.(product);
    } else {
      setSelectedProducts((prev) => {
        const updatedMap = new Map(prev);
        updatedMap.set(product.product_id, {
          ...product,
          is_featured:
            selectedProducts.get(product.product_id)?.is_featured ?? false,
        });
        return updatedMap;
      });
    }
  };

  const deselectProduct = (product: Product) => {
    setSelectedProducts((prev) => {
      const updatedMap = new Map(prev);
      updatedMap.delete(product.product_id);
      return updatedMap;
    });
  };

  const handleFeatureToggle = (product: Product) => {
    setSelectedProducts((prev) => {
      const updatedMap = new Map(prev);
      const currentProduct = selectedProducts.get(product.product_id);
      updatedMap.set(product.product_id, {
        ...product,
        is_featured: currentProduct ? !currentProduct.is_featured : true,
      });
      return updatedMap;
    });
  };

  const header = (
    <Flex direction="row" justify="between" align="center">
      <Text size="4" weight="bold">
        Add Products
      </Text>
      <Dialog.Close>
        <IconButton
          style={{
            borderRadius: "8px",
            cursor: "pointer",
          }}
          color="gray"
          variant="outline"
        >
          <X color="#000000A6" />
        </IconButton>
      </Dialog.Close>
    </Flex>
  );
  const content = (
    <Flex direction="column">
      <Flex justify="between" align="center">
        <SearchField
          currentText={filters.name}
          onTextChange={setters.setName}
        />
        <CategorySelector
          categories={categories}
          selectedCategoryId={filters.categoryId}
          setCategoryId={setters.setCategoryId}
        />
      </Flex>
      <ProductsTable
        itemsPerPage={ITEMS_PER_PAGE}
        selectedProducts={selectedProducts}
        products={filteredProducts}
        selectProduct={selectProduct}
        deselectProduct={deselectProduct}
        setNameSort={setters.setNameSort}
        nameSort={sorters.nameSort}
        onFeatureToggle={isFeatureEnabled ? handleFeatureToggle : undefined}
        canOnlySelectOneProduct={canOnlySelectOneProduct}
      />
    </Flex>
  );
  const footer = (
    <Flex direction="row" justify="between" align="center">
      <Paginator
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
        currentPage={filters.page}
        updatePage={setters.updatePage}
      />
      {onConfirm && (
        <Flex gap="3">
          <Dialog.Close>
            <AppButton radius="large" variant="outlined">
              Cancel
            </AppButton>
          </Dialog.Close>
          <Dialog.Close>
            <AppButton
              onClick={() => onConfirm?.(selectedProducts)}
              radius="large"
              variant="dark"
            >
              Update
            </AppButton>
          </Dialog.Close>
        </Flex>
      )}
    </Flex>
  );
  return (
    <Dialog.Root open={isOpen} onOpenChange={onOpenChange}>
      <Dialog.Trigger>
        {triggerComponent || (
          <AppButton
            radius="large"
            style={{
              paddingTop: "12px",
              paddingBottom: "12px",
              height: "fit-content",
            }}
            variant="outlined"
          >
            <PlusIcon />
            Add product(s)
          </AppButton>
        )}
      </Dialog.Trigger>
      <Dialog.Content
        style={{
          padding: "14px",
          width: "50%",
        }}
      >
        <Flex direction="column" gap="2">
          {header}
          <div
            style={{ borderBottom: "1px solid #DDD7D7", margin: "0px -14px" }}
          ></div>
          {content}
          {footer}
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default ProductSelector;
