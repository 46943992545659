import { operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

type EmailCreativeListResponse =
  operations["emails_api_list_email_creatives"]["responses"][200]["content"]["application/json"];
export type EmailCreativeListSchema = EmailCreativeListResponse[0];

const useEmailCreativesQuery = () => {
  const { data, error, isLoading } = useQuery({
    queryKey: ["emailCreatives"],
    queryFn: async (): Promise<EmailCreativeListResponse> => {
      const { data } = await axios.get("/api/v1/emails/list-email-creatives");
      return data;
    },
    staleTime: Infinity,
  });
  return {
    creativesData: data ?? [],
    creativesError: error,
    isCreativesLoading: isLoading,
  };
};
export default useEmailCreativesQuery;
