import TextModifierGenerationSection from "./TextModifierGenerationSection";
import { components } from "@openapi";
import { Flex, IconButton } from "@radix-ui/themes";
import { PencilIcon, RefreshCcw } from "lucide-react";
import { useCallback, useState } from "react";
import styled from "styled-components";
import DraperText from "~/components/core/DraperText";
import {
  useEmailState,
  useUpdateSectionField,
  useSetActiveTipTapID,
} from "~/routes/intern/email_editor/context/EmailEditorContext";
import { getTipTapId3 } from "~/utils/emails/tipTapUtils";

const IconButtonContainer = styled(IconButton)`
  border-radius: 8px;
  padding: 8px;
`;

const SectionTextModifier = ({
  textElement,
  fieldName,
  label,
}: {
  textElement: components["schemas"]["EmailTextElementSchema"];
  fieldName: string;
  label: string;
}) => {
  const { activeTipTapID, selectedSectionId } = useEmailState();
  const updateSection = useUpdateSectionField();
  const setActiveTipTapID = useSetActiveTipTapID();

  const [isRegenActive, setIsRegenActive] = useState(false);

  const onTextEdit = useCallback(
    (id: string) => {
      const myTipTapID = getTipTapId3(id);
      const isEditing = activeTipTapID === myTipTapID;
      if (isEditing) {
        setActiveTipTapID("");
      } else {
        setActiveTipTapID(myTipTapID);
      }
    },
    [activeTipTapID, setActiveTipTapID]
  );

  const activeComponent = (
    <TextModifierGenerationSection
      textElement={textElement}
      onCancel={() => {
        setIsRegenActive(false);
      }}
      onRegenerateComplete={(newValue: string) => {
        updateSection({
          sectionId: selectedSectionId,
          field: fieldName,
          value: newValue,
        });
      }}
    />
  );

  const inactiveComponent = (
    <Flex gap="2">
      <IconButtonContainer
        color="gray"
        variant="outline"
        onClick={() => onTextEdit(textElement.id)}
      >
        <PencilIcon size={20} />
      </IconButtonContainer>
      <IconButtonContainer
        color="gray"
        variant="outline"
        onClick={() => setIsRegenActive(true)}
      >
        <RefreshCcw />
      </IconButtonContainer>
    </Flex>
  );

  return (
    <Flex direction="column" gap="2">
      <Flex justify="between" align="center">
        <DraperText clamp={1} size={"2"}>
          {label}
        </DraperText>
        {inactiveComponent}
      </Flex>
      {isRegenActive ? activeComponent : null}
    </Flex>
  );
};

export default SectionTextModifier;
