import { assertNever } from "../utils/typeUtils";
import { Brand, useAuthenticatedUserState } from "./CurrentUserContext";
import { components, EmailSectionType, operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import {
  createContext,
  useReducer,
  useMemo,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  CustomizationViews,
  EmailFooterSectionSchema,
  EmailHeaderSectionSchema,
  EmailSectionSocialLinkState,
  EmailTemplateStateCommon,
  TemplateEditorInterface,
} from "~/types/template-editor";
import { templateEditorInitialState } from "~/utils/template-editor";

const initialState: TemplateEditorInterface = templateEditorInitialState;

interface ChangeCurrentView {
  type: "CHANGE_CURRENT_VIEW";
  payload: CustomizationViews;
}

interface UpdateEditorState {
  type: "UPDATE_EDITOR_STATE";
  payload: Partial<TemplateEditorInterface>;
}

interface ResetState {
  type: "RESET_STATE";
  payload?: TemplateEditorInterface;
}

export type TemplateEditorActions =
  | UpdateEditorState
  | ChangeCurrentView
  | ResetState;

function reducer(
  state: TemplateEditorInterface,
  action: TemplateEditorActions
): TemplateEditorInterface {
  switch (action.type) {
    case "CHANGE_CURRENT_VIEW":
      return {
        ...state,
        viewType: (() => {
          switch (action.payload) {
            case CustomizationViews.HeaderOptions:
              return EmailSectionType.header;
            case CustomizationViews.FooterOptions:
              return EmailSectionType.footer;
            default:
              return state?.viewType;
          }
        })(),
        currentView: action.payload,
      };
    case "UPDATE_EDITOR_STATE":
      return {
        ...state,
        ...action.payload,
        headerOptions: {
          ...state.headerOptions,
          ...action.payload.headerOptions,
        },
        footerOptions: {
          ...state.footerOptions,
          ...action.payload.footerOptions,
        },
      };
    case "RESET_STATE":
      return action.payload ?? initialState;
    default:
      assertNever(action);
  }
}

const TemplateEditorContext =
  createContext<TemplateEditorInterface>(initialState);
const DispatchContext =
  createContext<React.Dispatch<TemplateEditorActions> | null>(null);

type BrandEmailSectionTemplatesResponse =
  operations["emails_api_get_brand_email_sections"]["responses"]["200"]["content"]["application/json"];

const getSocialLinkState = ({
  socialLink,
  defaultSocialLink,
  brandLink,
}: {
  socialLink?: components["schemas"]["EmailLinkElementSchema"] | null;
  defaultSocialLink: EmailSectionSocialLinkState;
  brandLink?: string;
}): EmailSectionSocialLinkState => {
  const link = socialLink ? socialLink.link_url : brandLink;

  return {
    ...defaultSocialLink,
    ...socialLink,
    enabled: socialLink?.enabled ?? link !== undefined,
    link_url: link ?? "",
  };
};

const getColorState = ({
  sectionData,
}: {
  sectionData: EmailHeaderSectionSchema | EmailFooterSectionSchema;
}): EmailTemplateStateCommon["colors"] => {
  if (sectionData.type === EmailSectionType.header) {
    return [
      {
        background:
          sectionData.palette?.background ??
          initialState.headerOptions.colors[0].background,
        backgroundImage: {
          id: sectionData.background_image?.id ?? "",
          image: sectionData.background_image?.image_url ?? "",
        },
        foreground:
          sectionData.color ?? initialState.headerOptions.colors[0].foreground,
      },
    ];
  } else {
    return [
      {
        background:
          sectionData.background_1?.color ??
          initialState.footerOptions.colors[0].background,
        backgroundImage: sectionData.background_1 ?? undefined,
        foreground:
          sectionData.color_1 ??
          initialState.footerOptions.colors[0].foreground,
      },
      {
        background:
          sectionData.background_2?.color ??
          initialState.footerOptions.colors[1].background,
        backgroundImage: sectionData.background_2 ?? undefined,
        foreground:
          sectionData.color_2 ??
          initialState.footerOptions.colors[1].foreground,
      },
    ];
  }
};

const getSectionCommonOptionsState = ({
  sectionData,
  availableTemplates,
}: {
  sectionData: EmailHeaderSectionSchema | EmailFooterSectionSchema;
  availableTemplates: components["schemas"]["EmailSectionTemplateSchema"][];
}): EmailTemplateStateCommon => {
  const defaultOptions =
    sectionData.type === EmailSectionType.header
      ? initialState.headerOptions
      : initialState.footerOptions;

  return {
    id: sectionData.id,
    widthType: sectionData.width_type,
    availableTemplates: availableTemplates.map((template) => ({
      id: template.id!,
      templateHtml: template.html,
    })),
    selectedTemplateIndex: availableTemplates.findIndex(
      (template) => template.id === sectionData.template.id
    ),
    colors: getColorState({ sectionData }),
    menuLinks: {
      enabled: sectionData.menu_links?.length > 0,
      links:
        sectionData.menu_links?.map((link) => ({
          ...link,
          dragId: link.id,
        })) ?? [],
    },
    palette: sectionData.palette,
    customHTML: {
      enabled: !!sectionData.custom_html,
      html: sectionData.custom_html ?? defaultOptions.customHTML.html,
    },
  };
};

const getStateFromBrandEmailSections = (
  brandEmailSectionsData: BrandEmailSectionTemplatesResponse,
  brand: Brand
): TemplateEditorInterface => {
  const headerData = {
    ...brandEmailSectionsData.header,
    type: EmailSectionType.header as const,
  };
  const footerData = {
    ...brandEmailSectionsData.footer,
    type: EmailSectionType.footer as const,
  };
  const tagLine = headerData.tagline ?? initialState.headerOptions.tagline;
  if (!tagLine.text) {
    // TODO: generate from BE
    tagLine.text = '<h2 style="text-align: center;">Lorem ipsum</h2>';
  }
  return {
    ...initialState,
    headerOptions: {
      type: EmailSectionType.header,
      logo: headerData.logo ?? initialState.headerOptions.logo,
      tagline: headerData.tagline ?? initialState.headerOptions.tagline,
      ...getSectionCommonOptionsState({
        sectionData: headerData,
        availableTemplates: brandEmailSectionsData.header_templates,
      }),
    },
    footerOptions: {
      type: EmailSectionType.footer,
      ...getSectionCommonOptionsState({
        sectionData: footerData,
        availableTemplates: brandEmailSectionsData.footer_templates,
      }),
      socialLinks: {
        enabled: brandEmailSectionsData.footer.show_social_links,
        facebook: getSocialLinkState({
          socialLink: brandEmailSectionsData.footer.facebook_link,
          defaultSocialLink: initialState.footerOptions.socialLinks.facebook,
          brandLink: brandEmailSectionsData.social_links.facebook ?? undefined,
        }),
        twitter: getSocialLinkState({
          socialLink: brandEmailSectionsData.footer.twitter_link,
          defaultSocialLink: initialState.footerOptions.socialLinks.twitter,
          brandLink: brandEmailSectionsData.social_links.twitter ?? undefined,
        }),
        instagram: getSocialLinkState({
          socialLink: brandEmailSectionsData.footer.instagram_link,
          defaultSocialLink: initialState.footerOptions.socialLinks.instagram,
          brandLink: brandEmailSectionsData.social_links.instagram ?? undefined,
        }),
        tiktok: getSocialLinkState({
          socialLink: brandEmailSectionsData.footer.tiktok_link,
          defaultSocialLink: initialState.footerOptions.socialLinks.tiktok,
          brandLink: brandEmailSectionsData.social_links.tiktok ?? undefined,
        }),
        youtube: getSocialLinkState({
          socialLink: brandEmailSectionsData.footer.youtube_link,
          defaultSocialLink: initialState.footerOptions.socialLinks.youtube,
          brandLink: brandEmailSectionsData.social_links.youtube ?? undefined,
        }),
      },
    },
    brandData: {
      organization: {
        // TODO: see if we actually need this in templates
        fullAddress: brand.domain,
        name: brand.name,
      },
      unsubscribeLink: brandEmailSectionsData.email_unsubscribe_url ?? "#",
    },
  };
};

export const getBrandEmailSectionsQueryKey = (brandID: string) => [
  "brand-email-sections-",
  brandID,
];

export const TemplateEditorProvider = ({
  children,
  includeQuillImports = false,
}: {
  children: React.ReactElement;
  includeQuillImports?: boolean;
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { activeBrand } = useAuthenticatedUserState();
  const activeBrandID = activeBrand?.id;
  const { data: brandEmailSectionsData } =
    useQuery<BrandEmailSectionTemplatesResponse>({
      queryKey: getBrandEmailSectionsQueryKey(activeBrandID),
      queryFn: async () => {
        const { data } = await axios.get(
          `/api/v1/emails/brand-email-sections`,
          {
            params: {
              brand_id: activeBrandID,
            },
          }
        );
        return data;
      },
      enabled: !!activeBrandID,
    });

  useEffect(() => {
    if (!brandEmailSectionsData) {
      dispatch({
        type: "RESET_STATE",
      });
      return;
    }

    dispatch({
      type: "RESET_STATE",
      payload: getStateFromBrandEmailSections(
        brandEmailSectionsData,
        activeBrand
      ),
    });
  }, [brandEmailSectionsData]);

  // setup quill imports
  const [quillImports, setQuillImports] = useState<HTMLElement[] | null>(null);
  useEffect(() => {
    if (!includeQuillImports) {
      return;
    }
    const quillScript = document.createElement("script");
    quillScript.src = "https://cdn.jsdelivr.net/npm/quill@2.0.2/dist/quill.js";
    quillScript.async = true;
    document.head.appendChild(quillScript);

    const quillStyles = document.createElement("link");
    quillStyles.href =
      "https://cdn.jsdelivr.net/npm/quill@2.0.2/dist/quill.snow.css";
    quillStyles.rel = "stylesheet";
    document.head.appendChild(quillStyles);
    setQuillImports([quillScript, quillStyles]);

    return () => {
      setQuillImports((imports) => {
        imports?.forEach((element) => element.remove());
        return [];
      });
    };
  }, []);

  return (
    <DispatchContext.Provider value={useMemo(() => dispatch, [dispatch])}>
      <TemplateEditorContext.Provider value={useMemo(() => state, [state])}>
        {children}
      </TemplateEditorContext.Provider>
    </DispatchContext.Provider>
  );
};

export function useTemplateEditorState() {
  return useContext(TemplateEditorContext);
}

export function useHeaderFooterState() {
  const state = useTemplateEditorState();
  switch (state.viewType) {
    case EmailSectionType.header:
      return state.headerOptions;
    case EmailSectionType.footer:
      return state.footerOptions;
    default:
      throw new Error("Invalid viewType");
  }
}

export function useTemplateEditorDispatch(): React.Dispatch<TemplateEditorActions> {
  const dispatch = useContext(DispatchContext);
  if (!dispatch) {
    throw new Error(
      "useTemplateEditorDispatch must be used within a TemplateEditorProvider"
    );
  }
  return dispatch;
}
