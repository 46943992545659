import ModifierContainer from "./ModifierContainer";
import { Flex, Switch, Text } from "@radix-ui/themes";
import {
  useEmailState,
  useToggleSectionMetadata,
} from "~/routes/intern/email_editor/context/EmailEditorContext";
import { EmailCollectionGridSectionSchema } from "~/utils/emails/useSectionTypeCheck";

const TOGGLES: {
  label: string;
  field: "title" | "cta_button";
}[] = [
  { label: "Title", field: "title" },
  { label: "CTA Button", field: "cta_button" },
];

const CollectionMetadataModifier = ({
  collections,
}: {
  collections: EmailCollectionGridSectionSchema["collections"];
}) => {
  const toggleSectionMetadata = useToggleSectionMetadata();
  const { selectedSectionId, sections } = useEmailState();

  const collection = collections.length > 0 ? collections[0] : null;
  const isDisabled = !collection;

  return (
    <ModifierContainer title="Collection Metadata" hideSwitch>
      <Flex direction="column" gap="3">
        {TOGGLES.map(({ label, field }) => (
          <Flex key={field} justify="between" align="center">
            <Text size="2">{label}</Text>
            <Switch
              disabled={isDisabled}
              checked={collection?.[field]?.enabled || false}
              onCheckedChange={(checked) => {
                toggleSectionMetadata(selectedSectionId, field);
              }}
            />
          </Flex>
        ))}
      </Flex>
    </ModifierContainer>
  );
};

export default CollectionMetadataModifier;
