import SelectedButton from "../campaign/wizard/audience/common/SelectedButton";
import CategorizedSelect from "../campaign/wizard/inputs/CategorizedSelect";
import AppButton from "../core/buttons/AppButton/AppButton";
import DraperButton from "../core/buttons/DraperButton";
import { CDPAudienceType, CDPType, components } from "@openapi";
import { Dialog, Flex, Text, IconButton } from "@radix-ui/themes";
import { PlusIcon, X } from "lucide-react";
import { useEffect, useState } from "react";
import useCDPAudiencesQuery from "~/hooks/cdp/useCDPAudiencesQuery";

const AudienceDialog = ({
  initialAudiences,
  trigger,
  onConfirm,
}: {
  initialAudiences: components["schemas"]["CDPAudienceRequestData"][];
  trigger: React.ReactNode;
  onConfirm: (
    audiences: components["schemas"]["CDPAudienceRequestData"][]
  ) => void;
}) => {
  const [selectedAudiences, setSelectedAudiences] = useState<
    Map<string, components["schemas"]["CDPAudienceRequestData"]>
  >(new Map());
  const [selectingAudiences, setSelectingAudiences] = useState(true);

  const { audiencesData: data, isLoading } = useCDPAudiencesQuery();

  useEffect(() => {
    setSelectedAudiences(
      new Map(
        initialAudiences.map((audience) => [audience.audience_id, audience])
      )
    );
  }, [initialAudiences]);

  // Callbacks
  const handleDeselect = (id: string) => {
    if (!selectedAudiences.has(id)) {
      return;
    }
    setSelectedAudiences((prev) => {
      const updatedMap = new Map(prev);
      updatedMap.delete(id);
      return updatedMap;
    });
  };
  const handleSelect = (
    id: string,
    name: string,
    cdpType: string,
    audienceType: string
  ) => {
    if (selectedAudiences.has(id)) {
      return;
    }
    setSelectedAudiences((prev) => {
      const updatedMap = new Map(prev);
      updatedMap.set(id, {
        cdp_type: cdpType as CDPType,
        audience_id: id,
        audience_name: name,
        audience_type: audienceType as CDPAudienceType,
      });
      return updatedMap;
    });
  };

  return (
    <Dialog.Root>
      <Dialog.Trigger>{trigger}</Dialog.Trigger>
      <Dialog.Content>
        <Flex direction="column" gap="4">
          <Flex direction="row" justify="between" align="center">
            <Text size="4" weight="bold">
              Select Audience
            </Text>
            <Dialog.Close>
              <IconButton
                style={{
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
                color="gray"
                variant="outline"
              >
                <X color="#000000A6" />
              </IconButton>
            </Dialog.Close>
          </Flex>

          <Flex direction="row" gap="2" wrap="wrap">
            {Array.from(selectedAudiences).map(([id, audience], index) => (
              <SelectedButton
                key={index}
                id={id}
                onClick={handleDeselect}
                text={audience.audience_name}
              />
            ))}
            <AppButton
              onClick={() => {
                setSelectingAudiences((prev) => !prev);
              }}
              variant="soft"
              size="3"
              style={{
                border: selectedAudiences
                  ? "1px solid var(--text-link)"
                  : undefined,
              }}
            >
              <PlusIcon />
              Add audience
            </AppButton>
          </Flex>

          {selectingAudiences && (
            <CategorizedSelect
              data={data}
              onSelect={handleSelect}
              loading={isLoading}
            />
          )}

          <Flex justify="end" pt="16px">
            <Flex gap="3">
              <Dialog.Close>
                <AppButton variant="outlined" radius="large">
                  Cancel
                </AppButton>
              </Dialog.Close>
              <Dialog.Close>
                <AppButton
                  variant="dark"
                  radius="large"
                  onClick={() =>
                    onConfirm(Array.from(selectedAudiences.values()))
                  }
                  disabled={selectedAudiences.size === 0}
                >
                  Confirm
                </AppButton>
              </Dialog.Close>
            </Flex>
          </Flex>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default AudienceDialog;
