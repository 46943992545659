import { Card, Flex, Heading, Switch } from "@radix-ui/themes";
import React from "react";
import styled from "styled-components";

const CustomCard = styled(Flex)`
  border-radius: 20px;
  border: 1px solid var(--border-secondary);
  width: 100%;
`;

type AdEditorCardProps = {
  title: string;
} & React.ComponentProps<typeof Card> &
  (
    | {
        checkable: false;
      }
    | {
        checkable: true;
        checked: boolean;
        onCheckedChange: (checked: boolean) => void;
      }
  );

const AdEditorCard: React.FC<AdEditorCardProps> = ({
  children,
  title,
  ...props
}) => {
  const enabled = props.checkable ? props.checked : true;
  return (
    <CustomCard p="3">
      <Flex direction="column" gap="4" width="100%">
        <Flex direction="row" justify="between">
          <Heading size="2">{title}</Heading>
          {props.checkable && (
            <Switch
              checked={props.checked}
              onCheckedChange={props.onCheckedChange}
            />
          )}
        </Flex>
        {enabled && children}
      </Flex>
    </CustomCard>
  );
};

export default AdEditorCard;
