import { AdMediaTextElementSchema } from "../../types/ads";
import { AdMediaUpdateableElement, useAdMediaContext } from "./AdMediaContext";
import { AdMediaElementType } from "@openapi";
import { Container, Skeleton, Spinner } from "@radix-ui/themes";
import React, {
  useCallback,
  useRef,
  useEffect,
  CSSProperties,
  useState,
} from "react";
import { SvgLoader } from "react-svgmt";
import styled from "styled-components";
import layoutAndWrapTextElement, {
  updateImageElement,
  updateShapeElementColor,
} from "~/utils/ads/svg";
import { assertNever } from "~/utils/typeUtils";

const FULL_AD_WIDTH = 600;

const SVGContainer = styled.div`
  display: flex;
  width: 100%;
  border-radius: 8px;
  border-width: 2px;
  border-color: #afafaf;
  overflow: hidden;
  & p {
    font-size: inherit;
  }
`;

interface AdMediaSVGCanvasProps {
  id?: string;
  svgUrl: string | null | undefined;
  /** Between 1 and 100 */
  zoom?: number;
  customStyle?: CSSProperties;
  aspectRatio?: number;
}

const AdMediaSVGCanvas: React.FC<AdMediaSVGCanvasProps> = ({
  id,
  svgUrl,
  zoom,
  customStyle,
  aspectRatio = 1,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const { elements, setElements } = useAdMediaContext();

  const svgContainerRef = useRef<HTMLDivElement | null>(null);

  const updateElementAttributes = useCallback(() => {
    if (!elements.length) return;

    elements.forEach((element) => {
      updateSVGElement(element, setElements, svgContainerRef);
    });
  }, [elements, setElements]);

  useEffect(() => {
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === "childList" && mutation.addedNodes.length) {
          updateElementAttributes();
          observer.disconnect();
          setIsLoading(false);
          break;
        }
      }
    });

    if (svgContainerRef.current) {
      observer.observe(svgContainerRef.current, {
        childList: true,
        subtree: true,
      });
    }

    return () => {
      observer.disconnect();
    };
  }, [updateElementAttributes]);

  useEffect(() => {
    updateElementAttributes();
  }, [updateElementAttributes]);

  if (!svgUrl) {
    return (
      <Container>
        <Spinner size="3" />
      </Container>
    );
  }

  return (
    <div
      style={{
        position: "relative",
        ...(zoom
          ? {
              width: `${FULL_AD_WIDTH}px`,
              minWidth: `${FULL_AD_WIDTH}px`,
              zoom: `${zoom}%`,
            }
          : { width: "100%" }),
      }}
    >
      {isLoading && (
        <Skeleton
          width={`${(FULL_AD_WIDTH * (zoom ?? 100)) / 100}px`}
          height={`${((FULL_AD_WIDTH * (zoom ?? 100)) / 100) * aspectRatio}px`}
        />
      )}
      <SVGContainer
        style={{
          ...customStyle,
          ...(isLoading ? { display: "none" } : {}),
        }}
        id={id}
        ref={svgContainerRef}
      >
        <SvgLoader width="100%" height="100%" path={svgUrl} />
      </SVGContainer>
    </div>
  );
};

function updateSVGElement(
  element: AdMediaUpdateableElement,
  setElements: React.Dispatch<React.SetStateAction<AdMediaUpdateableElement[]>>,
  svgContainerRef: React.RefObject<HTMLDivElement>
) {
  const targetElement = svgContainerRef.current!.querySelector<SVGElement>(
    `#${element.target_element_id}`
  );
  if (targetElement && "is_enabled" in element) {
    targetElement.style.display = element.is_enabled ? "" : "none";
  }

  switch (element.type) {
    case AdMediaElementType.text: {
      if (!targetElement) {
        return;
      }
      const textElement = element as AdMediaTextElementSchema;
      if (!element.color && targetElement.tagName.toLowerCase() === "text") {
        const color =
          targetElement.getAttribute("fill") ||
          targetElement.getAttribute("stroke");
        if (color) {
          setElements((prevElements) =>
            prevElements.map((el) =>
              el.id === element.id ? { ...element, color } : el
            )
          );
        }
      }
      layoutAndWrapTextElement(targetElement, textElement.text, {
        fontSize: parseFloat(textElement.font_size),
        fontFamily: textElement.font_family,
        fontWeight: textElement.font_weight.toString(),
        textAlignment: textElement.text_alignment ?? undefined,
        color: element.color ?? undefined,
      });

      break;
    }
    case AdMediaElementType.image: {
      if (!targetElement) {
        return;
      }
      const imageUrl = element.uploadedFile
        ? URL.createObjectURL(element.uploadedFile)
        : element.file;
      updateImageElement(targetElement, imageUrl, svgContainerRef);

      // Mutate the DOM to force fill based images in svgs to render in Safari
      let executionCount = 0;
      const maxExecutions = 10;
      const timeoutTime = 300;

      function executeWithTimeout() {
        if (executionCount < maxExecutions) {
          if (svgContainerRef.current) {
            svgContainerRef.current.innerHTML += "";
          }
          executionCount++;
          setTimeout(executeWithTimeout, timeoutTime);
        }
      }

      executeWithTimeout();
      break;
    }
    case AdMediaElementType.shape: {
      if (!targetElement) {
        return;
      }
      updateShapeElementColor(
        targetElement,
        element.fill_color,
        element.stroke_color
      );
      break;
    }
    case AdMediaElementType.collection_group:
    case AdMediaElementType.product_group:
      element.elements.forEach((el) => {
        updateSVGElement(el, setElements, svgContainerRef);
      });
      break;
    default:
      assertNever(element);
  }
}

export default AdMediaSVGCanvas;
