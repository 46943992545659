import BackgroundColorModifier from "./modifiers/BackgroundColorModifier";
import ButtonModifier from "./modifiers/ButtonModifier";
import GeneratedVariantSelector from "./modifiers/GeneratedVariantSelector";
import ListicleModifier from "./modifiers/ListicleModifier";
import TextModifier from "./modifiers/TextModifier";
import { EmailSectionType } from "@openapi";
import { Flex, Separator } from "@radix-ui/themes";
import { SparklesIcon } from "lucide-react";
import ChatMessageContainer from "~/components/chat/ChatMessageContainer";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import TextAreaWithLabel from "~/components/core/inputs/TextAreaWithLabel";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useInitEmailSectionMutation from "~/hooks/emails/useInitEmailSectionQuery";
import {
  useEmailState,
  useUpdateSectionField,
} from "~/routes/intern/email_editor/context/EmailEditorContext";
import {
  isListicleSection,
  isNewSection,
} from "~/utils/emails/useSectionTypeCheck";

const ListicleView = () => {
  const activeBrandId = useActiveBrandID();
  const { selectedSectionId, sections } = useEmailState();
  const updateSection = useUpdateSectionField();

  const { mutate: initEmailSection, isPending: isInitEmailSectionLoading } =
    useInitEmailSectionMutation();

  const selectedSection = sections.find(
    (section) => section.id === selectedSectionId
  );

  if (!selectedSection) {
    return null;
  }

  if (!isListicleSection(selectedSection) && !isNewSection(selectedSection)) {
    return null;
  }

  if (!isListicleSection(selectedSection)) {
    const onGenerateOrWriteManually = (generate: boolean) => {
      initEmailSection({
        brand_id: activeBrandId,
        section_type: EmailSectionType.listicle,
        id: selectedSection.id,
        index: selectedSection.index,
        generate: generate,
      });
    };
    return (
      <Flex direction="column" gap="24px">
        <Flex gap="16px">
          <ChatMessageContainer message="Please input what list content should be generated." />
        </Flex>
        <TextAreaWithLabel labelColor="var(--text-tertiary)" label="Prompt" />

        <Separator size="4" />
        <Flex justify="end" gap="16px">
          <AppButton
            onClick={() => onGenerateOrWriteManually(false)} // hardcoded to test
            variant="dark"
            size="3"
            radius="large"
            disabled={isInitEmailSectionLoading}
          >
            <SparklesIcon />
            Generate
          </AppButton>
        </Flex>
      </Flex>
    );
  }

  const title = selectedSection.title;
  const subtitle = selectedSection.subtitle;
  const backgroundColor = selectedSection.palette.background;
  const items = selectedSection.list_items;

  return (
    <Flex direction="column" gap="24px">
      <GeneratedVariantSelector />
      <TextModifier
        textElement={title}
        label="Title"
        fieldName="title.text"
        enabled={title.enabled}
        onSwitchToggle={(value) =>
          updateSection({
            sectionId: selectedSection.id,
            field: "title.enabled",
            value,
          })
        }
      />
      <TextModifier
        textElement={subtitle}
        label="Subtitle"
        fieldName="subtitle.text"
        enabled={subtitle.enabled}
        onSwitchToggle={(value) =>
          updateSection({
            sectionId: selectedSection.id,
            field: "subtitle.enabled",
            value,
          })
        }
      />

      <ListicleModifier items={items} />

      <BackgroundColorModifier
        hideSwitch={true}
        palette={selectedSection.palette}
        sectionId={selectedSection.id}
      />
    </Flex>
  );
};

export default ListicleView;
