import BrandAssetsCardSection from "../../components/style-library/assets/BrandAssetsCardSection";
import LogoAssetsCardSection from "../../components/style-library/assets/LogoAssetsCardSection";
import ButtonStylesCardSection from "../../components/style-library/button-styles/ButtonStylesCardSection";
import ColorPaletteCardSection from "../../components/style-library/color-palette/ColorPaletteCardSection";
import TypographyCardSection from "../../components/style-library/typography/TypographyCardSection";
import { Text, Box, Heading } from "@radix-ui/themes";
import styled from "styled-components";
import BackgroundGradient from "~/components/core/layout/BackgroundGradient";
import CampaignTemplateCardSection from "~/components/style-library/campaign-template/CampaignTemplateCardSection";
import { BrandStylingProvider } from "~/contexts/BrandStylingContext";
import { TemplateEditorProvider } from "~/contexts/TemplateEditorContext";

const Card = styled(Box)`
  border: 1px solid var(--border-primary);
  border-radius: 12px;
  padding: 24px;
  background-color: var(--primary-white);
`;

const StyleLibraryRoute = () => {
  return (
    <BrandStylingProvider>
      <Box
        m={"6"}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
        }}
      >
        <BackgroundGradient variant="3" />
        <Heading size="6">Style Library</Heading>

        <Card>
          <LogoAssetsCardSection />
        </Card>
        <Card>
          <ColorPaletteCardSection />
        </Card>
        <Card>
          <ButtonStylesCardSection />
        </Card>
        <Card>
          <TypographyCardSection />
        </Card>
        <Card>
          <BrandAssetsCardSection />
        </Card>
        <Card>
          <TemplateEditorProvider>
            <CampaignTemplateCardSection />
          </TemplateEditorProvider>
        </Card>
      </Box>
    </BrandStylingProvider>
  );
};

export default StyleLibraryRoute;
