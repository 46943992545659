import CategorySelector from "./components/CategorySelector";
import ProductsTable from "./components/ProductsTable";
import SearchField from "./components/SearchField";
import useGetFilteredProducts from "./hooks/useGetFilteredProducts";
import { useProductsFilters } from "./hooks/useProductsFilters";
import { components } from "@openapi";
import { Dialog, Flex, IconButton, Text } from "@radix-ui/themes";
import { X } from "lucide-react";
import { useMemo } from "react";
import Paginator from "~/components/campaign/wizard/products/components/paginator/Paginator";
import DraperButton from "~/components/core/buttons/DraperButton";

type ShopifyProductType = components["schemas"]["ShopifyProductInfoExtra"];

const ITEMS_PER_PAGE = 9;
export const MAX_FEATURED_PRODUCTS = 6;

const AdProductSelector = ({
  onSelectProduct,
  children,
}: {
  onSelectProduct: (product: ShopifyProductType) => void;
  children: React.ReactNode;
}) => {
  const { filters, setters, sorters } = useProductsFilters();

  const {
    products: filteredProducts,
    hasNextPage,
    hasPreviousPage,
  } = useGetFilteredProducts(ITEMS_PER_PAGE, filters, sorters);

  const categories = useMemo<Map<string, { id: string; name: string }>>(() => {
    const map = new Map<string, { id: string; name: string }>();
    filteredProducts?.forEach((product) => {
      if (!product.category_name) {
        return;
      }
      map.set(product.category_name, {
        id: product.category_name,
        name: product.category_name,
      });
    });
    return map;
  }, [filteredProducts]);

  const header = (
    <Flex direction="row" justify="between" align="center">
      <Text size="4" weight="bold">
        Change Product
      </Text>
      <Dialog.Close>
        <IconButton
          style={{
            borderRadius: "8px",
            cursor: "pointer",
          }}
          color="gray"
          variant="outline"
        >
          <X color="#000000A6" />
        </IconButton>
      </Dialog.Close>
    </Flex>
  );
  const content = (
    <Flex direction="column">
      <Flex justify="between" align="center">
        <SearchField
          currentText={filters.name}
          onTextChange={setters.setName}
        />
        <CategorySelector
          categories={categories}
          selectedCategoryId={filters.categoryId}
          setCategoryId={setters.setCategoryId}
        />
      </Flex>
      {filteredProducts && (
        <ProductsTable
          onSelectProduct={onSelectProduct}
          products={filteredProducts}
          setNameSort={setters.setNameSort}
          nameSort={sorters.nameSort}
        />
      )}
    </Flex>
  );
  const footer = (
    <Flex direction="row" justify="between" align="center">
      <Paginator
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
        currentPage={filters.page}
        updatePage={setters.updatePage}
      />
      <Flex gap="3">
        <Dialog.Close>
          <DraperButton text={"Cancel"} variant="outlined"></DraperButton>
        </Dialog.Close>
      </Flex>
    </Flex>
  );
  return (
    <Dialog.Root>
      <Dialog.Trigger>{children}</Dialog.Trigger>
      <Dialog.Content style={{ padding: "14px", width: "50%" }}>
        <Flex direction="column" gap="4">
          {header}
          <div
            style={{
              borderBottom: "1px solid #DDD7D7",
              margin: "0px -14px",
            }}
          ></div>
          {content}
          {footer}
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default AdProductSelector;
