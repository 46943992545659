import { Avatar, Box, Card, Flex, Text } from "@radix-ui/themes";
import { CircleUser } from "lucide-react";
import React from "react";
import styled from "styled-components";
import FacebookLogin, {
  FacebookLoginProps,
} from "~/components/meta/authentication/FacebookLogin";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useMetaAdAccountsQuery from "~/hooks/meta/useMetaAdAccountsQuery";

const Container = styled(Card)`
  padding: 24px;
  width: 100%;
`;

const MetaCard: React.FC<Pick<FacebookLoginProps, "beforeRedirect">> = (
  props
) => {
  const activeBrandID = useActiveBrandID();
  const { adAccounts } = useMetaAdAccountsQuery(activeBrandID);
  const firstAccount = adAccounts?.[0];

  return (
    <Container>
      <Flex gap="4" align="center" justify="between">
        {firstAccount ? (
          <Flex gap="2" align="center">
            <Avatar
              src={firstAccount?.profile_picture_url ?? undefined}
              fallback={firstAccount?.name?.[0] ?? <CircleUser size={20} />}
            />
            <Text size={"3"}>{firstAccount?.name}</Text>
          </Flex>
        ) : (
          <Box>
            <Text weight="bold">Meta Account</Text>
            <br />
            <Text>Will open a popup to connect your Meta account</Text>
          </Box>
        )}
        <FacebookLogin showAdAccounts={!firstAccount} {...props} />
      </Flex>
    </Container>
  );
};

export default MetaCard;
