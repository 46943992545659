import ButtonModifier from "./ButtonModifier";
import ModifierContainer from "./ModifierContainer";
import { Flex, Switch, Text } from "@radix-ui/themes";
import {
  useEmailState,
  useToggleSectionMetadata,
} from "~/routes/intern/email_editor/context/EmailEditorContext";
import { EmailProductsSectionSchema } from "~/utils/emails/useSectionTypeCheck";

const TOGGLES: {
  label: string;
  field: "price" | "description" | "cta_button";
}[] = [
  { label: "Price", field: "price" },
  { label: "Description", field: "description" },
  { label: "CTA Button", field: "cta_button" },
];

const ProductMetadataModifier = ({
  products,
}: {
  products: EmailProductsSectionSchema["products"];
}) => {
  const toggleProductMetadata = useToggleSectionMetadata();
  const { selectedSectionId, sections } = useEmailState();

  const product = products.length > 0 ? products[0] : null;
  const isDisabled = !product;

  return (
    <ModifierContainer title="Product Metadata" hideSwitch>
      <Flex direction="column" gap="3">
        {TOGGLES.map(({ label, field }) => {
          if (field === "cta_button" && product?.cta_button) {
            return (
              <ButtonModifier
                buttonElement={product?.cta_button}
                fieldName="products.0.cta_button"
                onSwitchToggle={(enabled) => {
                  toggleProductMetadata(selectedSectionId, field);
                }}
              />
            );
          }
          return (
            <Flex key={field} justify="between" align="center">
              <Text size="2">{label}</Text>
              <Switch
                disabled={isDisabled}
                checked={product?.[field]?.enabled || false}
                onCheckedChange={(checked) => {
                  toggleProductMetadata(selectedSectionId, field);
                }}
              />
            </Flex>
          );
        })}
      </Flex>
    </ModifierContainer>
  );
};

export default ProductMetadataModifier;
