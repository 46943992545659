import DraperSegmentControl from "../core/DraperSegmentedControl";
import AppButton from "../core/buttons/AppButton/AppButton";
import LogoAssetsCardSection from "../style-library/assets/LogoAssetsCardSection";
import ButtonStylesCardSection from "../style-library/button-styles/ButtonStylesCardSection";
import ColorPaletteCardSection from "../style-library/color-palette/ColorPaletteCardSection";
import TypographyCardSection from "../style-library/typography/TypographyCardSection";
import OnboardingCard from "./OnboardingCard";
import { Flex, SegmentedControl, Text } from "@radix-ui/themes";
import { Check, X } from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useBrandStyle } from "~/contexts/BrandStylingContext";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useBrandUpdateMutation from "~/hooks/brand/useBrandUpdateMutation";
import { HOME_ROUTE } from "~/routes/constants";

enum StyleTabStep {
  LOGO = "0",
  PALETTE = "1",
  TYPOGRAPHY = "2",
  BUTTON = "3",
}

interface StyleTab {
  step: StyleTabStep;
  title: string;
  component: React.ReactNode;
  isComplete: (data: ReturnType<typeof useBrandStyle>["data"]) => boolean;
}

const StyleTabSteps: StyleTab[] = [
  {
    step: StyleTabStep.LOGO,
    title: "Logo",
    component: <LogoAssetsCardSection />,
    isComplete: (data) => !!data?.logos?.[0]?.url,
  },
  {
    step: StyleTabStep.PALETTE,
    title: "Color Palette",
    component: <ColorPaletteCardSection />,
    isComplete: (data) => !!data?.color_palette_set,
  },
  {
    step: StyleTabStep.TYPOGRAPHY,
    title: "Typography",
    component: <TypographyCardSection />,
    isComplete: (data) => !!data?.typography,
  },
  {
    step: StyleTabStep.BUTTON,
    title: "Buttons",
    component: <ButtonStylesCardSection />,
    isComplete: (data) => !!data?.button_styles?.length,
  },
];

const StyleTabIcon: React.FC<{ isComplete: boolean }> = ({ isComplete }) => {
  return isComplete ? (
    <Check color="#000000" size={20} />
  ) : (
    <X color="#000000" size={20} />
  );
};

const StyleLibraryReview = () => {
  const activeBrandID = useActiveBrandID();
  const [tab, setTab] = useState(StyleTabStep.LOGO);
  const { data } = useBrandStyle();
  const navigate = useNavigate();

  const { updateBrand, isLoading: isUpdatingBrand } = useBrandUpdateMutation({
    brandID: activeBrandID,
    onSuccess: () => {
      localStorage.removeItem("draper_onboarding_step");
      navigate(HOME_ROUTE);
    },
  });

  const selectedTab = StyleTabSteps.find((step) => step.step === tab)!;

  const header = (
    <Flex justify="between" align="center" gap="24px" p="16px">
      <DraperSegmentControl
        $size="large"
        value={tab}
        defaultValue={StyleTabStep.LOGO}
        onValueChange={(value) => setTab(value as StyleTabStep)}
      >
        {StyleTabSteps.map((step) => (
          <SegmentedControl.Item key={step.step} value={step.step}>
            <Flex align="center" gap="2">
              <StyleTabIcon isComplete={step.isComplete(data)} />
              <Text weight="medium">{step.title}</Text>
            </Flex>
          </SegmentedControl.Item>
        ))}
      </DraperSegmentControl>
    </Flex>
  );

  const handleNext = () => {
    const selectedTabIndex = StyleTabSteps.findIndex(
      (step) => step.step === tab
    );
    const nextTab = StyleTabSteps[selectedTabIndex + 1];
    if (nextTab) {
      setTab(nextTab.step);
      return;
    }

    const firstIncompleteStep = StyleTabSteps.find(
      (step) => !step.isComplete(data)
    );
    if (firstIncompleteStep) {
      setTab(firstIncompleteStep.step);
      return;
    }

    updateBrand({
      is_onboarded: true,
    });
  };

  return (
    <Flex
      direction={"column"}
      justify={"start"}
      align={"center"}
      gap="6"
      overflowY="auto"
      py="4"
    >
      <OnboardingCard
        width="100%"
        maxWidth="70vw"
        primary={
          <AppButton
            variant="dark"
            disabled={!selectedTab.isComplete(data)}
            onClick={handleNext}
            loading={isUpdatingBrand}
          >
            Continue
          </AppButton>
        }
        header={header}
      >
        {selectedTab.component}
      </OnboardingCard>
    </Flex>
  );
};

export default StyleLibraryReview;
