import InternAdCreativeComponent from "../../components/ads/intern/InternAdCreativeComponent";
import InternAdCreativesComponent from "../../components/ads/intern/InternAdCreativesComponent";
import InternAdMediaTemplateComponent from "../../components/ads/intern/InternAdMediaTemplateComponent";
import InternAdMediaTemplateVariantComponent from "../../components/ads/intern/InternAdMediaTemplateVariantComponent";
import InternAdMediaTemplatesComponent from "../../components/ads/intern/InternAdMediaTemplatesComponent";
import InternCampaignsComponent from "../../components/campaign/intern/InternCampaignsComponent";
import PrivateRoute from "../../components/core/auth/PrivateRoute";
import InternEmailTemplatesComponent from "../../components/emails/intern/InternEmailTemplatesComponent";
import AdsRoute from "../intern/ads";
import BrandRoute from "../intern/brand";
import InternCampaignsRoute from "../intern/campaigns";
import CherryRoute from "../intern/cherry";
import EmailsRoute from "../intern/emails";
import RecommenderRoute from "../intern/recommender";
import InternRoute from "../intern/root";
import StylesRoute from "../intern/styles";
import { Theme } from "@radix-ui/themes";
import { RouteObject, Navigate } from "react-router-dom";

export default function getInternRoutes(): RouteObject[] {
  return [
    {
      path: "/intern",
      element: (
        <Theme>
          <PrivateRoute isInternal>
            <InternRoute />
          </PrivateRoute>
        </Theme>
      ),
      children: [
        {
          path: "brand",
          element: <BrandRoute />,
        },
        {
          path: "recommender",
          element: <RecommenderRoute />,
        },
        {
          path: "styles",
          element: <StylesRoute />,
        },
        {
          path: "cherry",
          element: <CherryRoute />,
        },
        {
          path: "ads",
          element: <AdsRoute />,
          children: [
            {
              path: "",
              element: <Navigate to="templates" />,
            },
            {
              path: "templates",
              element: <InternAdMediaTemplatesComponent />,
            },
            {
              path: "template/:id",
              element: <InternAdMediaTemplateComponent />,
            },
            {
              path: "template/:templateId/variant/:variantId",
              element: <InternAdMediaTemplateVariantComponent />,
            },
            {
              path: "creatives",
              element: <InternAdCreativesComponent />,
            },
            {
              path: "creative/:id",
              element: <InternAdCreativeComponent />,
            },
          ],
        },
        {
          path: "emails",
          element: <EmailsRoute />,
          children: [
            {
              path: "",
              element: <Navigate to="templates" />,
            },
            {
              path: "templates",
              element: <InternEmailTemplatesComponent />,
            },
          ],
        },
        {
          path: "campaigns",
          element: <InternCampaignsRoute />,
          children: [
            {
              path: "",
              element: <Navigate to="campaigns" />,
            },
            {
              path: "campaigns",
              element: <InternCampaignsComponent />,
            },
          ],
        },
      ],
    },
  ];
}
