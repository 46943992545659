import InternAdMediaTemplateElementComponent from "./InternAdMediaTemplateElementComponent";
import InternAdMediaTemplateElementCreationDialog from "./InternAdMediaTemplateElementCreationDialog";
import { operations } from "@openapi";
import {
  AlertDialog,
  Box,
  Button,
  Flex,
  Heading,
  Spinner,
} from "@radix-ui/themes";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import Cookies from "js-cookie";
import React, { useState } from "react";

interface InternAdMediaTemplateElementsTabProps {
  templateId: string;
}

type AdMediaTemplateElementsApiResponse =
  operations["ads_api_get_ad_media_template_elements"]["responses"][200]["content"]["application/json"];

type AdMediaTemplateElementDeleteApiResponse =
  operations["ads_api_delete_ad_media_template_element"]["responses"][200]["content"]["application/json"];

const InternAdMediaTemplateElementsTab: React.FC<
  InternAdMediaTemplateElementsTabProps
> = ({ templateId }) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const [isDeleteElementDialogOpen, setIsDeleteElementDialogOpen] =
    useState<boolean>(false);

  const [elementIdForDeletion, setElementIdForDeletion] = useState<
    string | null
  >(null);

  const query = useQuery({
    queryKey: ["ad-media-template-elements", templateId],
    queryFn: async (): Promise<AdMediaTemplateElementsApiResponse> => {
      const { data } = await axios.get(
        `/api/v1/ads/ad-media-template/${templateId}/elements`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return data;
    },
    retry: false,
    staleTime: Infinity,
  });

  const deleteElementMutation = useMutation<
    AdMediaTemplateElementDeleteApiResponse,
    AxiosError
  >({
    mutationFn: async (): Promise<AdMediaTemplateElementDeleteApiResponse> => {
      const { data } = await axios.delete(
        `/api/v1/ads/ad-media-template/${templateId}/element/${elementIdForDeletion}`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return data;
    },
    onSuccess: (data) => {
      setIsDeleteElementDialogOpen(false);
      if (data.success) {
        setElementIdForDeletion(null);
        query.refetch();
      } else {
        alert(data.error_message);
      }
    },
    onError: (error) => {
      setIsDeleteElementDialogOpen(false);
      alert(error.response?.data);
      console.error("Error deleting element:", error);
    },
  });

  const handleOnElementCreate = () => {
    query.refetch();
  };

  const handleDeleteElementClick = (elementId: string) => {
    setElementIdForDeletion(elementId);
    setIsDeleteElementDialogOpen(true);
  };

  if (!query.data) {
    return (
      <Flex align="center">
        <Spinner />
      </Flex>
    );
  }

  return (
    <Box style={{ maxHeight: "100%", overflowY: "auto" }}>
      <AlertDialog.Root open={isDeleteElementDialogOpen}>
        <AlertDialog.Content maxWidth="450px">
          <AlertDialog.Title>Delete Element</AlertDialog.Title>
          <AlertDialog.Description size="2">
            Are you sure you'd like to permanently delete this template element?
          </AlertDialog.Description>
          <Flex gap="3" mt="4" justify="end">
            <Button
              variant="soft"
              color="gray"
              onClick={() => {
                setElementIdForDeletion(null);
                setIsDeleteElementDialogOpen(false);
              }}
            >
              Cancel
            </Button>
            <AlertDialog.Action>
              <Button
                variant="solid"
                color="red"
                onClick={() => {
                  deleteElementMutation.mutate();
                }}
              >
                Delete Element
              </Button>
            </AlertDialog.Action>
          </Flex>
        </AlertDialog.Content>
      </AlertDialog.Root>
      <InternAdMediaTemplateElementCreationDialog
        templateId={templateId}
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        onCreate={handleOnElementCreate}
      />
      <Flex align="center" justify="between" style={{ marginBottom: "10px" }}>
        <Heading size="4">Elements</Heading>
        <Button
          onClick={() => {
            setIsDialogOpen(true);
          }}
        >
          + Add Element
        </Button>
      </Flex>
      <Flex
        direction="column"
        gap="0.5rem"
        style={{
          overflowY: "auto",
          justifyContent: "stretch",
        }}
      >
        {query.data.elements.map((element) => (
          <InternAdMediaTemplateElementComponent
            element={element}
            onDeleteElementClick={handleDeleteElementClick}
          />
        ))}
      </Flex>
    </Box>
  );
};

export default InternAdMediaTemplateElementsTab;
