import AuthPageBase from "./AuthPageBase";
import { Flex, Link, Text } from "@radix-ui/themes";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as EmailCheckmarkIcon } from "~/assets/mailCompleted.svg";

const Card = styled(Flex)`
  width: 480px;
  border: 1px solid #ddd7d7;
  border-radius: 12px;
  background: white;
`;

const Footer = styled(Flex)`
  width: 100%;
  padding: 24px;
  border-top: 1px solid #ddd7d7;
`;

const StyledLink = styled(Link)`
  color: var(--primary-vibrant-orange);
`;

const FadedText = styled(Text)`
  color: var(--text-tertiary);
`;

const VerifyEmail = () => {
  const navigate = useNavigate();
  const location = (useLocation().state as { email: string }) ?? {
    email: "rob@mail.com",
  };
  return (
    <AuthPageBase>
      <Flex direction="column">
        <Flex p="6" direction={"column"} gap={"2"}>
          <Flex justify="center">
            <EmailCheckmarkIcon />
          </Flex>
          <Text mt="32px" mb="4px">
            To start using Kin, activate your account with the link sent to:
          </Text>
          <Text size={"2"}>{location.email}</Text>
        </Flex>

        {/* <Footer justify={"center"} align={"center"}>
        <FadedText size={"1"}>
        Didn't receive an email?{" "}
        <StyledLink href="" underline="hover" onClick={() => navigate("/")}>
        Resend code
        </StyledLink>
        </FadedText>
      </Footer> */}
      </Flex>
    </AuthPageBase>
  );
};

export default VerifyEmail;
