import FooterView from "../editor/sidepanel-views/FooterView";
import HeaderView from "../editor/sidepanel-views/HeaderView";
import { Box, Flex, Text } from "@radix-ui/themes";
import { ChevronLeftIcon } from "lucide-react";
import {
  MousePointerClick,
  PresentationIcon,
  ImageIcon,
  LayoutGridIcon,
  SparkleIcon,
  LayoutPanelTop,
  ListIcon,
  NewspaperIcon,
} from "lucide-react";
import styled from "styled-components";
import AddBlockView from "~/components/editor/editor/sidepanel-views/AddBlockView";
import BlogView from "~/components/editor/editor/sidepanel-views/BlogView";
import ButtonView from "~/components/editor/editor/sidepanel-views/ButtonView";
import CollectionGridView from "~/components/editor/editor/sidepanel-views/CollectionGridView";
import CtaView from "~/components/editor/editor/sidepanel-views/CtaView";
import GenerateVariantView from "~/components/editor/editor/sidepanel-views/GenerateVariantView";
import HeroCollectionView from "~/components/editor/editor/sidepanel-views/HeroCollectionView";
import HeroProductView from "~/components/editor/editor/sidepanel-views/HeroProductView";
import HeroView from "~/components/editor/editor/sidepanel-views/HeroView";
import ImageView from "~/components/editor/editor/sidepanel-views/ImageView";
import ListicleView from "~/components/editor/editor/sidepanel-views/ListicleView";
import ProductGridView from "~/components/editor/editor/sidepanel-views/ProductGridView";
import { BrandStylingProvider } from "~/contexts/BrandStylingContext";
import {
  EmailEditorViews,
  useEmailState,
  usePopEditorView,
} from "~/routes/intern/email_editor/context/EmailEditorContext";

const Container = styled(Flex)`
  padding-top: 72px;
  margin: 0px 16px;
  height: 100%;
  width: 432px;
`;

const Aside = styled(Flex)`
  background-color: var(--primary-white);
  border-radius: 24px;
  height: calc(100% - 16px);
  border: 1px solid rgba(240, 240, 240, 1);
  z-index: 1000;
  width: 100%;
  flex-direction: column;
  box-shadow: 0px 12px 120px 0px rgba(0, 0, 0, 0.08),
    0px 12px 32px -16px rgba(0, 9, 50, 0.04);
`;

const Header = styled(Flex)`
  border-bottom: 1px solid #ddd7d7;
  padding: 18px 0px;
  justify-content: center;
  position: relative;
  width: 100%;
  height: min-content;
`;

const DoneButton = styled(Flex)`
  cursor: pointer;
  color: var(--text-link);
  gap: 6px;
  align-items: center;
  position: absolute;
  left: 16px;
`;

export const getViewValues = (view: EmailEditorViews) => {
  switch (view) {
    case EmailEditorViews.GenerateVariant:
      return {
        name: "Generate Variant",
        Component: GenerateVariantView,
      };
    case EmailEditorViews.Button:
      return {
        name: "Button",
        Component: ButtonView,
      };
    case EmailEditorViews.AddBlock:
      return {
        name: "Add Block",
        Component: AddBlockView,
      };
    case EmailEditorViews.blog:
      return {
        name: "Blog",
        Component: BlogView,
        Icon: NewspaperIcon,
      };
    case EmailEditorViews.collection_grid:
      return {
        name: "Collection Grid",
        Component: CollectionGridView,
        Icon: LayoutPanelTop,
      };
    case EmailEditorViews.cta_card:
      return {
        name: "Cta Card",
        Component: CtaView,
        Icon: MousePointerClick,
      };
    case EmailEditorViews.footer:
      return {
        name: "Footer",
        Component: FooterView,
      };
    case EmailEditorViews.header:
      return {
        name: "Header",
        Component: HeaderView,
      };
    case EmailEditorViews.hero:
      return {
        name: "Hero",
        Component: HeroView,
        Icon: PresentationIcon,
      };
    case EmailEditorViews.hero_collection:
      return {
        name: "Collection Spotlight",
        Component: HeroCollectionView,
        Icon: SparkleIcon,
      };
    case EmailEditorViews.hero_product:
      return {
        name: "Featured Product",
        Component: HeroProductView,
        Icon: SparkleIcon,
      };
    case EmailEditorViews.hero_sales_event:
      return {
        name: "Hero",
        Component: HeroView,
      };
    case EmailEditorViews.image:
      return {
        name: "Image",
        Component: ImageView,
        Icon: ImageIcon,
      };
    case EmailEditorViews.listicle:
      return {
        name: "Listicle",
        Component: ListicleView,
        Icon: ListIcon,
      };
    case EmailEditorViews.products:
      return {
        name: "Product Grid",
        Component: ProductGridView,
        Icon: LayoutGridIcon,
      };
    default:
      throw new Error(`Unsupported view: ${view}`);
  }
};

const EmailEditorAside = () => {
  const { editorViewStack } = useEmailState();
  const popView = usePopEditorView();
  const currentEditorView = editorViewStack[editorViewStack.length - 1];

  const { name, Component } = getViewValues(currentEditorView);

  return (
    <BrandStylingProvider>
      <Container>
        <Aside>
          {editorViewStack.length > 1 && (
            <Header>
              <DoneButton onClick={() => popView()}>
                <ChevronLeftIcon />
                <Text size="4" weight="medium">
                  Done
                </Text>
              </DoneButton>
              <Text size="4" weight="medium">
                {name}
              </Text>
            </Header>
          )}
          <Flex direction="column" gap="24px" p="24px" overflow="auto">
            <Component />
          </Flex>
        </Aside>
      </Container>
    </BrandStylingProvider>
  );
};

export default EmailEditorAside;
