import CampaignWizardCard from "../../../components/campaign/wizard/cards/CampaignWizardCard";
import CheckboxWithQuantity from "../../../components/campaign/wizard/inputs/CheckboxWithQuantity";
import CampaignMetaConnect from "./CampaignMetaConnect";
import useCampaignChannelProps, {
  NewCampaignChannelCountDefaults,
  RecommendedCampaignChannelCountDefaults,
} from "./hooks/useCampaignChannelProps";
import { CampaignType, ItemSetType } from "@openapi";
import { Flex } from "@radix-ui/themes";
import { useCampaignWizardState } from "~/contexts/CampaignWizardContext";

const CampaignChannel = () => {
  const {
    wizardType,
    campaignData: { campaign_type },
  } = useCampaignWizardState();
  const { cardProps, checkboxProps, campaignRecommendation, metaProps } =
    useCampaignChannelProps();

  let emailCount = 2;
  let adsCount = 2;
  if (wizardType === "NEW" && campaign_type) {
    emailCount =
      NewCampaignChannelCountDefaults[campaign_type as CampaignType]["Email"];
    adsCount =
      NewCampaignChannelCountDefaults[campaign_type as CampaignType][
        "Meta Ads"
      ];
  } else if (campaignRecommendation) {
    emailCount =
      RecommendedCampaignChannelCountDefaults[
        campaignRecommendation?.item_set_type as ItemSetType
      ]["Email"];
    adsCount =
      RecommendedCampaignChannelCountDefaults[
        campaignRecommendation?.item_set_type as ItemSetType
      ]["Meta Ads"];
  }

  if (metaProps.showsMetaConnect) {
    return (
      <CampaignMetaConnect
        onCancel={() => metaProps.setShowsMetaConnect(false)}
      />
    );
  }

  return (
    <CampaignWizardCard
      {...cardProps}
      subtitle={`We propose sending ${emailCount} emails and ${adsCount} ads as part of this campaign but you can modify the channels and touchpoints if you want.`}
    >
      <Flex direction="column" gap="24px">
        {checkboxProps.map((props) => (
          <CheckboxWithQuantity key={props.label} {...props} />
        ))}
      </Flex>
    </CampaignWizardCard>
  );
};

export default CampaignChannel;
