import ModifierContainer from "./ModifierContainer";
import TextModifierGenerationSection from "./TextModifierGenerationSection";
import { components } from "@openapi";
import { Flex, Grid, Text } from "@radix-ui/themes";
import { PencilIcon, RefreshCcwIcon } from "lucide-react";
import { useState, useMemo } from "react";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import {
  useEmailState,
  useSetActiveTipTapID,
  useUpdateSectionField,
} from "~/routes/intern/email_editor/context/EmailEditorContext";
import { getTipTapId3 } from "~/utils/emails/tipTapUtils";

const TextModifier = ({
  textElement,
  label,
  enabled = false,
  onSwitchToggle,
  fieldName,
}: {
  textElement: components["schemas"]["EmailTextElementSchema"];
  label: string;
  enabled?: boolean;
  onSwitchToggle?: (enabled: boolean) => void;
  fieldName: string;
}) => {
  const setActiveTipTapID = useSetActiveTipTapID();
  const { selectedSectionId } = useEmailState();
  const updateSection = useUpdateSectionField();
  const [isRegenActive, setIsRegenActive] = useState(false);

  const myTipTapID = useMemo(() => {
    return getTipTapId3(textElement.id);
  }, [textElement.id]);

  const regenInactiveComponent = (
    <Grid columns="2" gap="12px">
      <AppButton
        disabled={!enabled}
        variant="outlined"
        radius="large"
        size="3"
        onClick={() => {
          setActiveTipTapID(myTipTapID);
        }}
      >
        <PencilIcon />
        <Text size={"3"} weight="medium">
          Edit
        </Text>
      </AppButton>
      <AppButton
        disabled={!enabled}
        radius="large"
        size="3"
        variant="outlined"
        onClick={() => {
          setIsRegenActive(true);
        }}
      >
        <RefreshCcwIcon />
        <Text size={"3"} weight="medium">
          Regenerate
        </Text>
      </AppButton>
    </Grid>
  );

  const regenActiveComponent = (
    <TextModifierGenerationSection
      textElement={textElement}
      label={
        <Flex align="center" gap="2">
          <RefreshCcwIcon size="16" />
          <Text size={"2"}>Regenerate: </Text>
        </Flex>
      }
      onCancel={() => {
        setIsRegenActive(false);
      }}
      onRegenerateComplete={(newValue: string) => {
        updateSection({
          sectionId: selectedSectionId,
          field: fieldName,
          value: newValue,
        });
      }}
    />
  );

  return (
    <ModifierContainer
      title={label}
      checked={enabled}
      onSwitch={(checked) => {
        if (!checked) {
          setIsRegenActive(false);
        }
        onSwitchToggle?.(checked);
      }}
    >
      {isRegenActive ? regenActiveComponent : regenInactiveComponent}
    </ModifierContainer>
  );
};

export default TextModifier;
