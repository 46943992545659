import {
  EmailElementType,
  EmailSectionType,
  EmailSectionWidthType,
} from "@openapi";
import {
  CustomizationViews,
  EmailSectionSocialLinkState,
  TemplateEditorInterface,
} from "~/types/template-editor";

const defaultSocialLinkState = (text: string): EmailSectionSocialLinkState => ({
  enabled: false,
  text: text,
  link_url: "",
  opens_new_tab: true,
});

export const templateEditorInitialState: TemplateEditorInterface = {
  currentView: CustomizationViews.TypeSelect,
  headerOptions: {
    id: "",
    type: EmailSectionType.header,
    widthType: EmailSectionWidthType.contained,
    availableTemplates: [],
    selectedTemplateIndex: 0,
    colors: [
      {
        background: "#FFFFFF",
        foreground: "#000000",
      },
    ],
    palette: {
      id: "palette-1",
      background: "#FFFFFF",
      foreground: "#000000",
      accent: "#000000",
    },
    logo: {
      enabled: false,
      image_url: "",
      id: "",
      type: EmailElementType.image,
    },
    menuLinks: {
      enabled: false,
      links: [
        {
          text: "Link 1",
          link_url: "",
          opens_new_tab: true,
          enabled: true,
          dragId: "link-1",
        },
      ],
    },
    customHTML: {
      enabled: false,
      html: "<strong>Hello World</strong>",
    },
    tagline: {
      enabled: false,
      text: "",
    },
  },
  footerOptions: {
    id: "",
    type: EmailSectionType.footer,
    widthType: EmailSectionWidthType.contained,
    availableTemplates: [],
    selectedTemplateIndex: 0,
    colors: [
      {
        background: "#FFFFFF",
        foreground: "#000000",
      },
      {
        background: "#000000",
        foreground: "#FFFFFF",
      },
    ],
    palette: {
      id: "palette-1",
      background: "#FFFFFF",
      foreground: "#000000",
      accent: "#000000",
    },
    menuLinks: {
      enabled: false,
      links: [
        {
          text: "Link 1",
          link_url: "",
          opens_new_tab: true,
          enabled: true,
          dragId: "link-1",
        },
      ],
    },
    customHTML: {
      enabled: false,
      html: "<strong>Hello World</strong>",
    },
    socialLinks: {
      enabled: false,
      facebook: defaultSocialLinkState("Facebook"),
      twitter: defaultSocialLinkState("Twitter"),
      instagram: defaultSocialLinkState("Instagram"),
      tiktok: defaultSocialLinkState("TikTok"),
      youtube: defaultSocialLinkState("YouTube"),
    },
  },
  brandData: {
    organization: {
      name: "Organization Name",
      fullAddress: "1234 Main St, City, State 12345",
    },
    unsubscribeLink: "https://example.com/unsubscribe",
  },
  previewMode: "desktop",
};

export const getParentView = (
  currentView: CustomizationViews,
  viewType?: TemplateEditorInterface["viewType"]
): CustomizationViews => {
  switch (currentView) {
    case CustomizationViews.HeaderOptions:
    case CustomizationViews.FooterOptions:
      return CustomizationViews.TypeSelect;
    case CustomizationViews.MenuLinks:
      return viewType === EmailSectionType.header
        ? CustomizationViews.HeaderOptions
        : CustomizationViews.FooterOptions;
    case CustomizationViews.SocialLinks:
      return CustomizationViews.FooterOptions;
    default:
      return CustomizationViews.TypeSelect;
  }
};
