import AppButton from "../buttons/AppButton/AppButton";
import ProgressionButton from "../buttons/ProgressionButton";
import * as Popover from "@radix-ui/react-popover";
import { Flex, Text, Box, Button, Theme } from "@radix-ui/themes";
import parseColor from "parse-color";
import { useCallback, useState } from "react";

const LinkInput = ({
  children,
  defaultUrl,
  onSaveLink,
}: {
  defaultUrl?: string;
  onSaveLink: (url: string) => void;
  children: React.ReactNode;
}) => {
  const [url, setUrl] = useState("");
  const [open, setOpen] = useState(false);

  const resetState = useCallback(() => {
    setUrl(defaultUrl ?? "");
  }, [defaultUrl]);

  return (
    <Popover.Root
      open={open}
      onOpenChange={(value) => {
        resetState();
        setOpen(value);
      }}
    >
      <Popover.Trigger asChild>{children}</Popover.Trigger>
      <Popover.Portal>
        <Theme>
          <Popover.Content
            sideOffset={8}
            className="color-picker-content"
            align="start"
          >
            <Flex gap="24px" align="center">
              <Box>
                <Text as="label" size="2" mb="2">
                  URL
                </Text>
                <input
                  type="url"
                  placeholder="Enter URL"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  style={{
                    width: "100%",
                    padding: "8px",
                    border: "1px solid #DDD7D7",
                    borderRadius: "6px",
                    fontSize: "14px",
                  }}
                />
              </Box>
              <Box mt="24px">
                <AppButton
                  size={"3"}
                  radius="medium"
                  onClick={() => {
                    onSaveLink(url);
                    setOpen(false);
                  }}
                >
                  Save
                </AppButton>
              </Box>
            </Flex>
          </Popover.Content>
        </Theme>
      </Popover.Portal>
    </Popover.Root>
  );
};

export default LinkInput;
