import ButtonModifier from "./ButtonModifier";
import ModifierContainer from "./ModifierContainer";
import SectionTextModifier from "./SectionTextModifier";
import { components, EmailSectionType, ImageAssetCategory } from "@openapi";
import { Flex, IconButton } from "@radix-ui/themes";
import { useQueryClient } from "@tanstack/react-query";
import { Crop, ImageIcon, PencilIcon } from "lucide-react";
import { useState } from "react";
import styled from "styled-components";
import AssetSelectorDialog from "~/components/campaign/wizard/dialogs/AssetSelectorDialog";
import DraperText from "~/components/core/DraperText";
import DraperButton from "~/components/core/buttons/DraperButton";
import ArticleSelectorDialog from "~/components/dialogs/ArticleSelectorDialog";
import CropImageDialog from "~/components/editor/dialogs/CropImageDialog";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import { BrandStylingResponse } from "~/hooks/data/useBrandStyleQuery";
import useInitEmailSectionMutation from "~/hooks/emails/useInitEmailSectionQuery";
import useMediaBrandImagesUpload from "~/hooks/style-library/useMediaBrandImagesUpload";
import {
  useEmailState,
  useUpdateSectionField,
} from "~/routes/intern/email_editor/context/EmailEditorContext";
import { dataURIToFile } from "~/utils/fileUtils";
import { getFilenameFromUrl } from "~/utils/helpers";

const IconButtonContainer = styled(IconButton)`
  border-radius: 8px;
  padding: 8px;
`;

const BlogPostModifier = ({
  blogElement,
}: {
  blogElement: components["schemas"]["EmailBlogSectionSchema"];
}) => {
  const updateSection = useUpdateSectionField();
  const queryClient = useQueryClient();
  const activeBrandID = useActiveBrandID();
  const { selectedSectionId, sections } = useEmailState();

  const [isAssetDialogOpen, setIsAssetDialogOpen] = useState(false);
  const [isCropImageDialogOpen, setIsCropImageDialogOpen] = useState(false);

  const selectedSection = sections.find(
    (section) => section.id === selectedSectionId
  );
  const fileName = getFilenameFromUrl(blogElement.image.image_url);

  // Mutations
  const { mutate: initEmailSection, isPending } = useInitEmailSectionMutation();
  const { mutate: uploadImageAssets } = useMediaBrandImagesUpload({
    data: {
      brandID: activeBrandID,
      category: ImageAssetCategory.asset,
    },
    onSuccess: (data) => {
      queryClient.setQueryData(
        ["brand-style", activeBrandID],
        (oldData: BrandStylingResponse) => ({
          ...oldData,
          image_assets: [...oldData.image_assets, ...data.new_assets],
        })
      );
      updateSection({
        sectionId: selectedSectionId,
        field: `image.image_url`,
        value: data.new_assets[0].url,
      });
      setIsCropImageDialogOpen(false);
    },
    onError: (error) => {
      // TODO
    },
  });
  const handleUpload = (file: File) => {
    uploadImageAssets({
      images: [file as unknown as string],
    });
  };

  if (!selectedSection) {
    return null;
  }

  const dialog = (
    <ArticleSelectorDialog
      onConfirm={(articleIds) => {
        initEmailSection({
          brand_id: activeBrandID,
          section_type: EmailSectionType.blog,
          id: selectedSection.id,
          index: selectedSection.index,
          generate: false,
          article_ids: articleIds,
        });
      }}
      triggerComponent={
        <DraperButton
          variant="outlined"
          text="Change Article"
          icon={<PencilIcon />}
          disabled={isPending}
        />
      }
      limit={1}
    />
  );
  return (
    <ModifierContainer title="Blog Post" hideSwitch>
      {dialog}

      <Flex direction="column" gap="3">
        <SectionTextModifier
          textElement={blogElement.title}
          label="Title"
          fieldName="title.text"
        />
        <SectionTextModifier
          label="Description"
          textElement={blogElement.description}
          fieldName="description.text"
        />

        <Flex justify="between" align="center">
          <DraperText clamp={1} size={"2"}>
            Image
          </DraperText>
          <Flex gap="2">
            <IconButtonContainer
              color="gray"
              variant="outline"
              radius="large"
              onClick={() => setIsAssetDialogOpen(true)}
            >
              <ImageIcon />
            </IconButtonContainer>
            <IconButtonContainer
              color="gray"
              variant="outline"
              radius="large"
              onClick={() => setIsCropImageDialogOpen(true)}
            >
              <Crop />
            </IconButtonContainer>
          </Flex>
        </Flex>

        <ButtonModifier
          buttonElement={blogElement.cta_button}
          fieldName="cta_button"
          onSwitchToggle={(enabled) => {
            updateSection({
              sectionId: selectedSectionId,
              field: "cta_button.enabled",
              value: enabled,
            });
          }}
        />
      </Flex>

      <AssetSelectorDialog
        isDialogOpen={isAssetDialogOpen}
        onToggleDialogOpen={setIsAssetDialogOpen}
        selectedAssets={[]}
        relatedAssets={[]}
        setRelatedAsset={(url) => {
          updateSection({
            sectionId: selectedSectionId,
            field: `image.image_url`,
            value: url,
          });
          setIsAssetDialogOpen(false);
        }}
      />
      <CropImageDialog
        open={isCropImageDialogOpen}
        onOpenChange={setIsCropImageDialogOpen}
        img={blogElement.image.image_url}
        onCrop={(data: string) => {
          const file = dataURIToFile(data, (fileName ?? "") + "_cropped.png");
          handleUpload(file);
        }}
      />
    </ModifierContainer>
  );
};

export default BlogPostModifier;
