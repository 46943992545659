import HomeSection from "../../HomeSection";
import { Campaign } from "../../hooks/useGetCampaigns";
import useGetLazyCampaigns from "../../hooks/useGetLazyCampaigns";
import CampaignDraftCard from "./CampaignDraftCard";
import { CampaignStatus } from "@openapi";
import { Flex } from "@radix-ui/themes";
import { useEffect, useRef, useState } from "react";

const DRAFTS_PER_BATCH = 6;
const CampaignDraftSection = () => {
  const isloading = useRef(false);
  const [draftsOffset, setDraftsOffset] = useState(0);
  const [campaignDrafts, setCampaignDrafts] = useState<Array<Campaign>>([]);
  const [hasMore, setHasMore] = useState(true);
  useEffect(() => {
    if (!campaignDrafts.length && !isloading.current) {
      fetchNextBatch();
    }
  }, []);
  const refetch = useGetLazyCampaigns({
    status: CampaignStatus.draft,
    limit: DRAFTS_PER_BATCH,
    offset: draftsOffset,
  });
  const fetchNextBatch = async () => {
    isloading.current = true;
    const data = await refetch();
    if (!data.isSuccess) {
      alert(data.error);
      return;
    }
    setCampaignDrafts((prev) => {
      const newArray = Array.from(prev);
      newArray.push(...data.data.campaigns);
      return newArray;
    });
    setDraftsOffset((prev) => prev + DRAFTS_PER_BATCH);
    setHasMore(data.data.has_more);
    isloading.current = false;
  };

  if (!campaignDrafts.length) {
    return null;
  }

  return (
    <HomeSection
      title="Drafts"
      content={
        <Flex direction="row" gap="5" justify="start" wrap="wrap">
          {campaignDrafts?.map((campaignDraft) => (
            <CampaignDraftCard
              campaign={campaignDraft}
              key={campaignDraft.id}
            />
          ))}
        </Flex>
      }
    />
  );
};

export default CampaignDraftSection;
