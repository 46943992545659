import { components, EmailPropertyType } from "@openapi";
import { Box, Flex, Grid, Separator, Switch, Text } from "@radix-ui/themes";
import { PencilIcon } from "lucide-react";
import { useState } from "react";
import styled from "styled-components";
import BrandButton from "~/components/core/buttons/BrandButton";
import DraperButton from "~/components/core/buttons/DraperButton";
import ButtonStyleDialog from "~/components/style-library/button-styles/dialogs/ButtonStyleDialog";
import { useBrandStyle } from "~/contexts/BrandStylingContext";
import {
  useEmailState,
  useUpdateSectionField,
  useApplyAllButtonStyle,
  EmailButtonElementSchema,
  getNestedValue,
} from "~/routes/intern/email_editor/context/EmailEditorContext";

const ButtonStyleCard = styled(Box)<{ isSelected: boolean }>`
  background-color: ${({ isSelected }) => (isSelected ? "#ffffff" : "#EDEBF0")};
  border: 1px solid ${({ isSelected }) => (isSelected ? "#857C7B" : "#e0e0e0")};
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const ButtonView = () => {
  const [applyToAllButtons, setApplyToAllButtons] = useState(false);
  const updateSection = useUpdateSectionField();
  const applyAllButtonStyle = useApplyAllButtonStyle();
  const { activeButtonField, selectedSectionId, sections } = useEmailState();
  const { data: brandStyle } = useBrandStyle();
  const section = sections.find((section) => section.id === selectedSectionId);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  if (!section || !activeButtonField) {
    return null;
  }

  let activeButtonStyle: EmailButtonElementSchema | null = getNestedValue(
    section,
    activeButtonField
  );

  if (!activeButtonStyle) {
    return null;
  }

  const shouldUpdateAllButtonsInSection =
    activeButtonField.split(".").length > 1;
  const buttonStyles = brandStyle?.button_styles ?? [];

  const onStyleChange = (
    style: components["schemas"]["BrandButtonStyleSchema"]
  ) => {
    const newStyle = {
      ...activeButtonStyle,
      background_color: style.background_color,
      border_color: style.border_color,
      border_style: style.border_style,
      border_width: style.border_width ?? "0px",
      border_radius: style.border_radius,
      color: style.color,
      padding: style.padding,
      text_transform: style.text_transform,
      font_styles: {
        id: activeButtonStyle.font_styles?.id ?? "",
        name: "New Font Style",
        type: EmailPropertyType.font,
        value: "#000000",
        family: style.font.font_family ?? "",
        size: style.font.size ?? "",
        weight: style.font.weight ?? 0,
        style: style.font.style ?? "",
      },
    };
    if (applyToAllButtons) {
      applyAllButtonStyle(newStyle, true);
    } else {
      if (shouldUpdateAllButtonsInSection) {
        applyAllButtonStyle(newStyle, false);
      } else {
        updateSection({
          sectionId: selectedSectionId,
          field: activeButtonField,
          value: newStyle,
        });
      }
    }
  };

  return (
    <Flex direction="column" gap="24px">
      <Text size={"3"} weight={"medium"}>
        Select Style
      </Text>

      <Grid columns={"2"} gap={"2"}>
        {buttonStyles.map((style) => {
          return (
            <ButtonStyleCard
              isSelected={false}
              key={style.id}
              onClick={() => {
                onStyleChange(style);
              }}
            >
              <BrandButton
                brandButtonStyle={style}
                style={{
                  margin: "4px",
                }}
              >
                Shop Now
              </BrandButton>
            </ButtonStyleCard>
          );
        })}
      </Grid>
      <Flex align="center" gap="3" justify="between">
        <Text size={"3"} weight={"medium"}>
          Local Style
        </Text>
        <Flex align="center" gap="3">
          <Text size="1" style={{ flexGrow: 1, textAlign: "center" }}>
            Apply to all buttons
          </Text>
          <Switch
            onCheckedChange={(checked) => {
              setApplyToAllButtons(checked);
              if (checked) {
                applyAllButtonStyle(activeButtonStyle, true);
              }
            }}
            checked={applyToAllButtons}
          />
        </Flex>
      </Flex>

      <Grid columns={"2"} gap={"2"}>
        <ButtonStyleCard isSelected={false}>
          <BrandButton
            brandButtonStyle={{
              ...activeButtonStyle,
              text_align: "center",
              font: {
                font_family: activeButtonStyle.font_styles?.family ?? "",
                size: activeButtonStyle.font_styles?.size ?? "",
                weight: activeButtonStyle.font_styles?.weight ?? 0,
                style: activeButtonStyle.font_styles?.style ?? "",
              },
            }}
            style={{
              margin: "4px",
            }}
          >
            Shop Now
          </BrandButton>
        </ButtonStyleCard>
      </Grid>

      <Separator size="4" />
      <DraperButton
        variant="outlined"
        text="Edit Button Style"
        icon={<PencilIcon />}
        onClick={() => {
          setIsDialogOpen(true);
        }}
      />

      {isDialogOpen && (
        <ButtonStyleDialog
          open={isDialogOpen}
          onOpenChange={setIsDialogOpen}
          buttonStyle={{
            ...activeButtonStyle,
            id: "1",
            text_align: "center",
            font: {
              font_family: activeButtonStyle.font_styles?.family ?? "",
              size: activeButtonStyle.font_styles?.size ?? "",
              weight: activeButtonStyle.font_styles?.weight ?? 0,
              style: activeButtonStyle.font_styles?.style ?? "",
            },
          }}
          onSave={(
            style: components["schemas"]["UpdateButtonStyleRequestData"]
          ) => {
            onStyleChange({
              ...style,
              id: activeButtonStyle.id,
              text_transform: activeButtonStyle.text_transform,
              text_align: "center",
            });
            setIsDialogOpen(false);
          }}
        />
      )}
    </Flex>
  );
};

export default ButtonView;
