import {
  AdMediaUpdateableElement,
  useAdMediaContext,
} from "../../AdMediaContext";
import AdEditorCard from "../AdEditorCard";
import AdProductSelector from "../dialogs/productSelector/AdProductSelector";
import { AdMediaElementType, components } from "@openapi";
import { Flex, IconButton, Text } from "@radix-ui/themes";
import { GripVerticalIcon, PencilIcon } from "lucide-react";
import { useMemo } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
} from "react-beautiful-dnd";
import styled from "styled-components";
import { StrictModeDroppable } from "~/components/core/StrictModeDroppable";
import DraperButton from "~/components/core/buttons/DraperButton";
import ImageSelector from "~/components/core/inputs/ImageSelector";
import { useAdEditorDispatch } from "~/contexts/AdEditorContext";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useShopifyProductsQuery from "~/hooks/shopify/useShopifyProductsQuery";
import {
  getProductImageElement,
  getProductTitleElement,
} from "~/utils/ads/helpers";
import { downloadFile } from "~/utils/fileUtils";
import { currencyFormatter } from "~/utils/helpers";
import { assertNever } from "~/utils/typeUtils";

type ShopifyProductsType = components["schemas"]["ShopifyProductInfoExtra"][];
type ElementType = Extract<AdMediaUpdateableElement, { type: "product_group" }>;

interface AdMediaProductGroupElementComponentProps {
  elements: ElementType[];
  onElementUpdate: (element: ElementType) => void;
}

const AdProductCard = ({
  elements,
  onElementUpdate,
}: AdMediaProductGroupElementComponentProps) => {
  const { setElements } = useAdMediaContext();
  const dispatch = useAdEditorDispatch();
  const activebrandID = useActiveBrandID();
  const { productsData } = useShopifyProductsQuery(
    activebrandID,
    elements.map((element) => element.commerce_platform_item_id)
  );

  if (!elements.length) {
    return;
  }

  const handleProductUpdate = (
    element: ElementType,
    selectedProduct: ShopifyProductsType[0]
  ) => {
    if (selectedProduct.image_url) {
      dispatch({
        type: "INCREMENT_PENDING_CHANGES",
        payload: 1,
      });
      downloadFile(selectedProduct.image_url).then((file) => {
        // not using `onElementUpdate` as it might revert changes
        setElements((elements) => {
          return elements.map((el) => {
            if (el.id === element.id && el.type === element.type) {
              return {
                ...el,
                elements: el.elements.map((el) => {
                  switch (el.type) {
                    case "image":
                      return {
                        ...el,
                        uploadedFile: file,
                      };
                    case "text":
                      return {
                        ...el,
                        text: selectedProduct.title,
                      };
                    default:
                      return el;
                  }
                }),
              };
            }
            return el;
          });
        });

        dispatch({
          type: "INCREMENT_PENDING_CHANGES",
          payload: -1,
        });
      });
    }

    onElementUpdate({
      ...element,
      commerce_platform_item_id: selectedProduct.product_id,
      elements: element.elements.map((el) => {
        switch (el.type) {
          case "image":
            return {
              ...el,
              file: selectedProduct.image_url ?? "",
              uploadedFile: undefined,
            };
          default:
            return el;
        }
      }),
    });
  };

  const handleImageUpdate = (element: ElementType, file: File) => {
    onElementUpdate({
      ...element,
      elements: element.elements.map((el) => {
        switch (el.type) {
          case "image":
            return {
              ...el,
              uploadedFile: file,
            };
          default:
            return el;
        }
      }),
    });
  };

  if (elements.length > 1) {
    return (
      <AdMultipleProductsCard
        elements={elements}
        products={productsData ?? []}
        handleProductUpdate={handleProductUpdate}
        onElementUpdate={onElementUpdate}
      />
    );
  }

  const [product] = productsData ?? [];
  const imageElement = getProductImageElement(elements[0]);

  return (
    <AdEditorCard checkable={false} title="Product">
      <Text style={{ color: "--var(text-secondary)" }}>
        {product?.title} - {currencyFormatter().format(product?.price)}
      </Text>
      <AdProductSelector
        onSelectProduct={(product) => handleProductUpdate(elements[0], product)}
      >
        <DraperButton
          variant="outlined"
          text="Change Product"
          icon={<PencilIcon />}
        />
      </AdProductSelector>
      <ImageSelector
        value={imageElement?.uploadedFile ?? imageElement?.file ?? ""}
        onUpload={(file) => handleImageUpdate(elements[0], file)}
        onCrop={(file) => handleImageUpdate(elements[0], file)}
      />
    </AdEditorCard>
  );
};

const ProductImage = styled.img`
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 8px;
  margin-left: 8px;
  margin-right: 16px;
`;

const AdMultipleProductItem: React.FC<{
  product?: ShopifyProductsType[number];
  element: ElementType;
  onChangeProduct: (product: ShopifyProductsType[number]) => void;
  draggableProvided: DraggableProvided;
}> = ({ product, element, onChangeProduct, draggableProvided }) => {
  const imageUrl = useMemo(() => {
    const imageElement = getProductImageElement(element);
    if (imageElement?.uploadedFile) {
      return URL.createObjectURL(imageElement.uploadedFile);
    }
    return imageElement?.file ?? product?.image_url ?? "";
  }, [element]);
  const productTitleElement = useMemo(
    () => getProductTitleElement(element),
    [element]
  );
  return (
    <div ref={draggableProvided.innerRef} {...draggableProvided.draggableProps}>
      <Flex align="center" key={element.id}>
        <div {...draggableProvided.dragHandleProps}>
          <GripVerticalIcon color="#595D62" />
        </div>
        <ProductImage src={imageUrl} alt={product?.title} />
        <Flex direction="column" gap="5px">
          <Text size="2">{productTitleElement?.text ?? product?.title}</Text>
          <Text style={{ opacity: 0.7 }} size="1">
            {product ? currencyFormatter().format(product.price) : "-"}
          </Text>
        </Flex>
        <AdProductSelector onSelectProduct={onChangeProduct}>
          <IconButton
            radius="large"
            variant="outline"
            color="gray"
            ml="auto"
            size="2"
          >
            <PencilIcon size={20} />
          </IconButton>
        </AdProductSelector>
      </Flex>
    </div>
  );
};

const AdMultipleProductsCard = ({
  products,
  elements,
  handleProductUpdate,
  onElementUpdate,
}: {
  products: ShopifyProductsType;
  elements: ElementType[];
  handleProductUpdate: (
    element: ElementType,
    selectedProduct: ShopifyProductsType[0]
  ) => void;
  onElementUpdate: (element: ElementType) => void;
}) => {
  const handleDragEnd = (result: any) => {
    if (!result.destination) return;

    const swapElements = (
      sourceElement: ElementType,
      destinationElement: ElementType
    ) => {
      const sourceImageElement = sourceElement.elements.find(
        (el) => el.type === "image"
      );
      const sourceTextTitleElement = sourceElement.elements.find(
        (el): el is Extract<typeof el, { type: "text" }> =>
          el.type === "text" && el.text_type === "product_title"
      );
      const sourceProduct = products.find(
        (product) =>
          product.product_id === sourceElement.commerce_platform_item_id
      );
      const destinationImageElement = destinationElement.elements.find(
        (el) => el.type === "image"
      );
      const destinationTitleElement = destinationElement.elements.find(
        (el): el is Extract<typeof el, { type: "text" }> =>
          el.type === "text" && el.text_type === "product_title"
      );
      const destinationProduct = products.find(
        (product) =>
          product.product_id === destinationElement.commerce_platform_item_id
      );

      onElementUpdate({
        ...sourceElement,
        commerce_platform_item_id: destinationElement.commerce_platform_item_id,
        elements: sourceElement.elements.map((el) => {
          switch (el.type) {
            case AdMediaElementType.image:
              return {
                ...el,
                file:
                  destinationImageElement?.file ??
                  destinationProduct?.image_url ??
                  "",
                uploadedFile: destinationImageElement?.uploadedFile,
              };
            case AdMediaElementType.text:
              return {
                ...el,
                text: destinationTitleElement?.text ?? "",
              };
            default:
              assertNever(el);
          }
        }),
      });

      onElementUpdate({
        ...destinationElement,
        commerce_platform_item_id: sourceElement.commerce_platform_item_id,
        elements: destinationElement.elements.map((el) => {
          switch (el.type) {
            case AdMediaElementType.image:
              return {
                ...el,
                file:
                  sourceImageElement?.file ?? sourceProduct?.image_url ?? "",
                uploadedFile: sourceImageElement?.uploadedFile,
              };
            case AdMediaElementType.text:
              return {
                ...el,
                text: sourceTextTitleElement?.text ?? "",
              };
            default:
              assertNever(el);
          }
        }),
      });
    };

    swapElements(
      elements[result.source.index],
      elements[result.destination.index]
    );
  };

  return (
    <AdEditorCard checkable={false} title="Product">
      <DragDropContext onDragEnd={handleDragEnd}>
        <StrictModeDroppable
          droppableId="product-collection-droppable"
          type="group"
        >
          {(provided) => (
            <Flex
              gap="16px"
              direction="column"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {elements.map((el, index) => {
                const product = products.find(
                  (product) =>
                    product.product_id === el.commerce_platform_item_id
                );
                return (
                  <Draggable key={el.id} draggableId={el.id} index={index}>
                    {(draggableProvided) => (
                      <AdMultipleProductItem
                        product={product}
                        element={el}
                        onChangeProduct={(product) =>
                          handleProductUpdate(el, product)
                        }
                        draggableProvided={draggableProvided}
                      />
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </Flex>
          )}
        </StrictModeDroppable>
      </DragDropContext>
    </AdEditorCard>
  );
};

export default AdProductCard;
