import Handlebars from "handlebars";

Handlebars.registerHelper(
  "or",
  function (
    this: any,
    a: boolean,
    b: boolean,
    options: Handlebars.HelperOptions
  ) {
    if (a || b) {
      return options.fn(this);
    } else {
      return options.inverse(this);
    }
  }
);
Handlebars.registerHelper("isEven", function (index: number): boolean {
  return index % 2 === 0;
});
Handlebars.registerPartial(
  "button",
  ` {{#if enabled}}
  <tr>
  <td style="text-align: center">
  <a
  id="ttid:{{id}}"
    target="_blank"
    href="{{link_url}}"
    style="
      background-color: {{background_color}};
      color: {{color}};
      border-radius: {{border_radius}};
      border-style: {{border_style}};
      border-width: {{border_width}};
      border-color: {{border_color}};
      padding: {{padding}};
      font-size: {{font_styles.size}};
      font-weight: {{font_styles.weight}};
      font-style: {{font_styles.style}};
      font-family: {{font_styles.family}};
      text-transform: {{text_transform}};
      text-align: center;
      text-decoration: none;
      cursor: pointer;
      display: inline-block;
  "
  >
    {{{text}}}
  </a>
  </td>
  </tr>
  {{/if}}`
);
Handlebars.registerPartial(
  "text",
  `{{#if enabled}}
  <tr>
    <td id="ttid:{{id}}" style="text-align: {{text_align}}; padding-bottom: 12px">
        {{{text}}}
    </td>
  </tr>
  {{/if}}`
);

export const getTipTapId3 = (id: string) => {
  return `ttid:${id}`;
};

// Function to recursively traverse and modify the AST
function traverseAndReplace(
  node: any,
  path: string[] = [],
  sectionId: string,
  listCount?: number
) {
  if (node.type === "MustacheStatement" && node.escaped === false) {
    // Get the full path of the field
    const fieldPath = path.concat(node.path.parts).join(".");
    // Replace triple brackets with <span id="field.path"/>
    return {
      type: "ContentStatement",
      value: `<span id="ttid:${sectionId}:${fieldPath}"/>`,
      original: node.original,
      loc: node.loc,
    };
  }

  // Handle list items
  if (
    node.type === "BlockStatement" &&
    node.path.original === "each" &&
    listCount
  ) {
    const listItemsPath = path.concat(node.params[0].parts).join(".");
    for (let i = 0; i < listCount; i++) {
      node.program = traverseAndReplace(
        node.program,
        [...path, listItemsPath, i.toString()],
        sectionId,
        listCount
      );
    }
    return node;
  }

  // Recursively process child nodes
  if (node.body && Array.isArray(node.body)) {
    node.body = node.body.map((child: any, index: number) =>
      traverseAndReplace(
        child,
        node.type === "BlockStatement"
          ? path.concat(node.path.parts, index.toString())
          : path,
        sectionId,
        listCount
      )
    );
  }
  if (node.program) {
    node.program = traverseAndReplace(node.program, path, sectionId, listCount);
  }
  if (node.inverse) {
    node.inverse = traverseAndReplace(node.inverse, path, sectionId, listCount);
  }

  return node;
}

export function getCompiledTemplateWithTipTapIds(
  templateString: string,
  sectionId: string,
  listCount?: number
) {
  // Parse the template into an AST
  const ast = Handlebars.parse(templateString);

  // Traverse and modify the AST
  const modifiedAst = traverseAndReplace(ast, [], sectionId, listCount);

  // Generate the new template string from the modified AST
  const newTemplateString = Handlebars.precompile(modifiedAst);

  return Handlebars.template(eval(`(${newTemplateString})`));
}
