import { Flex } from "@radix-ui/themes";
import React from "react";
import styled from "styled-components";

const Content = styled(Flex)`
  overflow: hidden;
`;

const EmailEditorLayout = ({
  children,
}: {
  children: [React.ReactNode, React.ReactNode, React.ReactNode];
}) => {
  return (
    <Flex
      direction="column"
      width="100%"
      height={"100vh"}
      style={{ backgroundColor: "var(--primary-white)" }}
    >
      {children[0]}
      <Content width="100%" justify="between" height="100%">
        {children[1]}
        {children[2]}
      </Content>
    </Flex>
  );
};

export default EmailEditorLayout;
