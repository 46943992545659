import CampaignWizardCard from "~/components/campaign/wizard/cards/CampaignWizardCard";
import MetaCard from "~/components/core/cards/MetaCard";
import { useCampaignWizardDispatch } from "~/contexts/CampaignWizardContext";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useMetaAdAccountsQuery from "~/hooks/meta/useMetaAdAccountsQuery";

interface CampaignMetaConnectProps {
  onCancel: () => void;
}

const CampaignMetaConnect: React.FC<CampaignMetaConnectProps> = ({
  onCancel,
}) => {
  const wizardDispatch = useCampaignWizardDispatch();
  const activeBrandID = useActiveBrandID();

  const { adAccounts } = useMetaAdAccountsQuery(activeBrandID);
  const isMetaConnected = (adAccounts?.length ?? 0) > 0;

  const persistState = () =>
    wizardDispatch({
      type: "PERSIST_STATE",
      payload: { isShowingMetaConnect: true },
    });

  return (
    <CampaignWizardCard
      badgeTitle="Connect Meta Account"
      nextButtonProps={{
        text: "Continue",
        disabled: !isMetaConnected,
      }}
      backButtonProps={{
        hide: false,
        onClick: onCancel,
      }}
      subtitle="For Meta ads to be generated we need to connect your Meta account."
    >
      <MetaCard beforeRedirect={persistState} />
    </CampaignWizardCard>
  );
};

export default CampaignMetaConnect;
