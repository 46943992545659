import { AccountSettingsComponents } from ".";
import React, { useState } from "react";
import { AccountSettingsTabsEnum } from "~/types/account-settings";

const AccountSettings = ({ children }: { children: React.ReactNode }) => {
  const [activeTab, setActiveTab] = useState<AccountSettingsTabsEnum>(
    AccountSettingsTabsEnum.General
  );

  return (
    <AccountSettingsComponents.Dialog trigger={children}>
      <AccountSettingsComponents.Tabs
        activeTab={activeTab}
        setActiveTab={(tab) => setActiveTab(tab)}
      />
      <AccountSettingsComponents.Content activeTab={activeTab} />
    </AccountSettingsComponents.Dialog>
  );
};

export default AccountSettings;
