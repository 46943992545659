import ModifierContainer from "./ModifierContainer";
import { components } from "@openapi";
import { Label } from "@radix-ui/react-label";
import { Flex, Grid, TextField } from "@radix-ui/themes";
import { PencilIcon, RefreshCcwIcon } from "lucide-react";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import {
  EmailEditorViews,
  usePushEditorView,
  useEmailState,
  useSetActiveButtonField,
  useSetActiveTipTapID,
  useUpdateSectionField,
} from "~/routes/intern/email_editor/context/EmailEditorContext";
import { getTipTapId3 } from "~/utils/emails/tipTapUtils";

export type EmailButtonElementSchema =
  components["schemas"]["EmailButtonElementSchema"];

const ButtonModifier = ({
  buttonElement,
  label = "CTA Button",
  onSwitchToggle,
  fieldName,
}: {
  buttonElement: EmailButtonElementSchema;
  label?: string;
  onSwitchToggle?: (enabled: boolean) => void;
  fieldName: string;
}) => {
  const updateSection = useUpdateSectionField();
  const setActiveTipTapID = useSetActiveTipTapID();
  const pushView = usePushEditorView();
  const setActiveButtonField = useSetActiveButtonField();

  const { selectedSectionId, activeTipTapID } = useEmailState();

  const myTipTapID = getTipTapId3(buttonElement.id ?? "");
  const isEditing = activeTipTapID === getTipTapId3(buttonElement.id ?? "");

  const { enabled, link_url } = buttonElement;

  const isSingleButton = fieldName.split(".").length === 1;

  const customizeButton = (
    <AppButton
      variant="outlined"
      size="3"
      radius="large"
      onClick={() => {
        setActiveButtonField(fieldName);
        pushView(EmailEditorViews.Button);
      }}
    >
      <RefreshCcwIcon />
      Customize
    </AppButton>
  );
  return (
    <ModifierContainer
      title={label}
      checked={enabled}
      onSwitch={onSwitchToggle}
    >
      {isSingleButton ? (
        <Grid columns="2" gap="12px">
          <AppButton
            style={isEditing ? { border: "2px solid black" } : {}}
            variant="outlined"
            size="3"
            radius="large"
            onClick={() => {
              if (isEditing) {
                setActiveTipTapID("");
              } else {
                setActiveTipTapID(myTipTapID);
              }
            }}
          >
            <PencilIcon />
            {isEditing ? "Editing" : "Edit"}
          </AppButton>

          {customizeButton}
        </Grid>
      ) : (
        customizeButton
      )}
      {isSingleButton ? (
        <Flex direction="column" gap="8px" mt="12px">
          <Label htmlFor="linkUrl">Link URL</Label>
          <TextField.Root
            required
            name="link"
            value={link_url}
            onChange={(e) => {
              updateSection({
                sectionId: selectedSectionId,
                field: `${fieldName}.link_url`,
                value: e.target.value,
              });
            }}
            type="url"
            placeholder="https://www.example.com"
          />
        </Flex>
      ) : null}
    </ModifierContainer>
  );
};

export default ButtonModifier;
