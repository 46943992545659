import { useCampaignWizardState } from "../../contexts/CampaignWizardContext";
import { useCallback } from "react";
import scrollIntoView from "scroll-into-view-if-needed";

const useCenterCampaignCard = () => {
  const { currentStep } = useCampaignWizardState();

  const centerCurrentCard = useCallback(() => {
    const element = document.getElementById(`step-${currentStep}`);
    const step = currentStep;
    if (element) {
      setTimeout(() => {
        if (step !== currentStep) {
          return;
        }
        scrollIntoView(element, {
          behavior: "smooth",
          block: "center",
          inline: "center",
        });

        setTimeout(() => {
          if (step !== currentStep) {
            return;
          }
          scrollIntoView(element, {
            behavior: "instant",
            block: "center",
            inline: "center",
          });
        }, 500);
      }, 50);
    }
  }, [currentStep]);

  return { centerCurrentCard };
};

export default useCenterCampaignCard;
