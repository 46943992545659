import { AdMediaUpdateableElement, useAdMediaContext } from "../AdMediaContext";
import AdMediaElementListComponent from "../elements/AdMediaElementListComponent";
import { Flex } from "@radix-ui/themes";

const EditorSidePanel: React.FC = () => {
  const { elements, setElements } = useAdMediaContext();

  // not really safe in case of partial updates, as the original element might be outdated
  const handleElementUpdate = (updatedElement: AdMediaUpdateableElement) => {
    setElements((prevElements) =>
      prevElements.map((element) =>
        element.id === updatedElement.id ? updatedElement : element
      )
    );
  };

  return (
    <Flex
      width="430px"
      flexGrow="0"
      flexShrink="0"
      p="4"
      mr="3"
      style={{
        background: "var(--background_white, #FFFFFF)",
        border: "1px solid var(--border-secondary, #F0F0F0)",
        borderRadius: "24px",
      }}
    >
      <AdMediaElementListComponent
        elements={elements}
        onElementUpdate={handleElementUpdate}
      />
    </Flex>
  );
};

export default EditorSidePanel;
