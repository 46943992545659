import Handlebars from "handlebars";
import {
  EmailBrandFooterSectionHandlebars,
  EmailBrandHeaderSectionHandlebars,
} from "~/types/template-vars";

Handlebars.registerHelper("default", (option: String, defaultValue: String) => {
  return option ?? defaultValue;
});
Handlebars.registerHelper("chunk", function (array, size) {
  if (!Array.isArray(array)) return [];
  const chunked = [];
  for (let i = 0; i < array.length; i += size) {
    chunked.push(array.slice(i, i + size));
  }
  return chunked;
});

Handlebars.registerHelper("isEven", function (index: number): boolean {
  return index % 2 === 0;
});

type EmailSectionPreviewProps = {
  template: Handlebars.TemplateDelegate;
  vars: EmailBrandHeaderSectionHandlebars | EmailBrandFooterSectionHandlebars;
};

const EmailSectionPreview: React.FC<EmailSectionPreviewProps> = ({
  template,
  vars,
}) => {
  return (
    <div
      style={{
        // TODO: this should hold the default email style
        padding: "16px",
        fontFamily: "Arial",
        fontSize: "16px",
        lineHeight: "24px",
      }}
      dangerouslySetInnerHTML={{
        __html: template(vars),
      }}
    />
  );
};

export default EmailSectionPreview;
