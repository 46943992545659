import { Box, Flex, Heading, Spinner, Text } from "@radix-ui/themes";
import { CheckIcon, StarsIcon } from "lucide-react";
import { useState } from "react";
import LoadingDots from "~/components/campaign/wizard/misc/LoadingDots";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import {
  useAdEditorDispatch,
  useAdEditorSelectedAdState,
} from "~/contexts/AdEditorContext";
import useGenerateAdCreativeMediaMutation from "~/hooks/ads/useGenerateAdCreativeMediaMutation";

const AdLayoutVariants: React.FC = () => {
  const state = useAdEditorSelectedAdState();
  const dispatch = useAdEditorDispatch();
  const [serverError, setServerError] = useState<string | null>(null);

  const { mutate: generateMedia, isPending: isGenerating } =
    useGenerateAdCreativeMediaMutation({
      onSuccess: (data) => {
        if (!data.success || !data.media.length) {
          setServerError("Failed to generate layout variants");
          return;
        }
        dispatch({
          type: "APPEND_AD_MEDIA",
          payload: {
            media: data.media,
          },
        });
      },
      onError: (error) => {
        setServerError(error);
      },
    });

  const handleRegenerate = () => {
    if (isGenerating) {
      return;
    }
    setServerError(null);
    generateMedia({ adCreativeId: state.data.id });
  };

  const handleMediaSelect = (index: number) => {
    if (state.media.status !== "loaded") {
      return;
    }
    if (state.media.selectedMediaIndex === index) {
      return;
    }
    dispatch({
      type: "SELECT_AD_MEDIA_INDEX",
      payload: {
        adCreativeId: state.data.id,
        selectedMediaIndex: index,
      },
    });
  };

  return (
    <Flex direction="column" gap="4">
      <Heading size="2">Explore Layout Variants</Heading>
      {state.media.status === "error" && (
        <Heading size="1" color="red" mx="2">
          {state.media.message}
        </Heading>
      )}
      {state.media.status === "loading" && <LoadingDots />}
      {state.media.status === "loaded" && (
        <>
          <Flex direction="column">
            <AppButton
              variant="soft"
              disabled={isGenerating}
              onClick={handleRegenerate}
            >
              <Spinner loading={isGenerating}>
                <StarsIcon size={20} />
              </Spinner>
              {isGenerating ? "Generating..." : "Generate new version"}
            </AppButton>
            {serverError && (
              <Text size="1" color="red">
                {serverError}
              </Text>
            )}
          </Flex>
          <Flex direction="row" gap="2" wrap="wrap">
            {state.media.list.map((media, index) => (
              <Box
                key={media.id}
                style={{
                  position: "relative",
                  borderRadius: "8px",
                  overflow: "hidden",
                  backgroundImage: `url(${
                    media.variants[0]?.preview_image ??
                    media.variants[0]?.file ??
                    ""
                  })`,
                  width: 80,
                  height: 80,
                  backgroundPosition: "center center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
                onClick={() => {
                  handleMediaSelect(index);
                }}
              >
                {state.media.status === "loaded" &&
                  index === state.media.selectedMediaIndex && (
                    <Flex
                      align="center"
                      justify="center"
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        width: "100%",
                        height: "100%",
                        background: "rgba(0, 0, 0, 0.5)",
                        borderRadius: "0 4px 0 4px",
                      }}
                    >
                      <CheckIcon size={24} color="white" />
                    </Flex>
                  )}
              </Box>
            ))}
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default AdLayoutVariants;
