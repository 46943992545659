import { Avatar, Flex, FlexProps, Text } from "@radix-ui/themes";
import React from "react";
import styled, { CSSProperties } from "styled-components";
import markSrc from "~/assets/mark.png";

const Container = styled.div`
  width: 802px;
  position: relative;
`;

const Card = styled(Flex)`
  border: 1px solid var(--border-primary);
  border-radius: 24px;
  background-color: var(--primary-white);
  box-shadow: 0px 12px 120px 0px #00000014;
  box-shadow: 0px 12px 32px -16px #0009320a;
`;

const Footer = styled(Flex)`
  border-top: 1px solid var(--border-primary);
  padding: 24px;
  justify-content: flex-end;
  gap: 16px;
`;

const Title = styled(Text)`
  color: var(--icon-primary-active);
  font-size: 18px;
  font-weight: 500;
`;

const Subtitle = styled(Text)`
  font-size: 24px;
  font-weight: 600;
  white-space: pre-wrap;
`;

const AssistantAvatar = styled(Avatar)`
  position: absolute;
  top: -32px;
  left: -32px;
  width: 72px;
  height: 72px;
`;

interface ActionCardProps {
  title: string;
  subtitle: string | string[];
  children?: React.ReactNode;
  footer?: React.ReactNode;
  overflowY?: FlexProps["overflowY"];
  maxHeight?: FlexProps["maxHeight"];
}

/**
 * @prop {string} title - The title of the card.
 * @prop {string} subtitle - The subtitle of the card.
 * @prop {React.ReactNode} children - The content of the card.
 * @prop {React.ReactNode} footer - The footer of the card.
 *
 * @example
 * <ActionCard
 *  title="Welcome to Draper"
 *  subtitle="Let's get started"
 *  footer={<AppButton>Continue</AppButton>}
 * >
 *   <Text>Some content</Text>
 * </ActionCard>
 */
const ActionCard = ({
  title,
  subtitle,
  children,
  footer,
  overflowY = "auto",
  maxHeight,
}: ActionCardProps) => {
  const subtitleElement =
    typeof subtitle === "string" ? (
      <Subtitle>{subtitle}</Subtitle>
    ) : (
      <Flex direction="column" gap="6" align="start">
        {subtitle
          .filter((sub) => sub)
          .map((sub, index) => (
            <Subtitle key={index}>{sub}</Subtitle>
          ))}
      </Flex>
    );
  return (
    <Container>
      <AssistantAvatar src={markSrc} fallback="Mark" />
      <Card direction="column" overflowY={overflowY} maxHeight={maxHeight}>
        <Flex
          direction="column"
          pt="40px"
          pb="var(--action-card-xb-padding)"
          px="var(--action-card-xb-padding)"
          gap="24px"
        >
          <Title>{title}</Title>
          {subtitleElement}
          {children}
        </Flex>
        {!!footer && <Footer>{footer}</Footer>}
      </Card>
    </Container>
  );
};

export default ActionCard;
