import useGetCampaignRecommendations from "../../hooks/recommendations/useGetCampaignRecommendations";
import RecommenderCard from "./RecommenderCard";
import RecommenderHero from "./RecommenderHero";
import RecommenderZeroState from "./RecommenderZeroState";
import { Flex, Grid } from "@radix-ui/themes";

const RecommenderSection = () => {
  const { data, isLoading } = useGetCampaignRecommendations({ page: 0 });

  const allRecommendations = data?.pages.flatMap(
    (page) => page.campaign_recommendations
  );

  if (!isLoading && allRecommendations?.length === 0) {
    return <RecommenderZeroState />;
  }

  return (
    <Flex direction="column" gap="7" width="100%" overflow="hidden" pt="72px">
      <RecommenderHero
        isSkeleton={isLoading}
        recommendation={allRecommendations?.[0]}
      />
      <Grid columns={{ initial: "1", md: "2", lg: "2" }} gap="6">
        {allRecommendations?.slice(1)?.map((recommendation) => (
          <RecommenderCard
            key={recommendation.id}
            recommendation={recommendation}
          />
        ))}
      </Grid>
    </Flex>
  );
};

export default RecommenderSection;
