import BackgroundSelector from "./modifiers/BackgroundSelector";
import CustomHTMLView from "./modifiers/CustomHTMLView";
import LogoSelector from "./modifiers/LogoSelector";
import VariantSelector from "./modifiers/VariantSelector";
import {
  EmailElementType,
  EmailSectionType,
  EmailSectionWidthType,
} from "@openapi";
import { Button, Flex, SegmentedControl, Switch, Text } from "@radix-ui/themes";
import { PencilIcon, RefreshCcwIcon } from "lucide-react";
import React from "react";
import styled from "styled-components";
import { useBrandStyle } from "~/contexts/BrandStylingContext";
import {
  useHeaderFooterState,
  useTemplateEditorDispatch,
} from "~/contexts/TemplateEditorContext";
import useUpdateTemplateEditorState from "~/hooks/template-editor/useUpdateTemplateEditorState";
import {
  CustomizationViews,
  EmailTemplateHeaderState,
} from "~/types/template-editor";

const TemplateModifier = styled(Flex)`
  border-bottom: 1px dashed #ddd7d7;
  padding-bottom: 24px;
`;

const TemplateCustomization = () => {
  const state = useHeaderFooterState();
  const updateState = useUpdateTemplateEditorState(state.type);
  const dispatch = useTemplateEditorDispatch();
  const { data: brandStyle } = useBrandStyle();

  const getHeaderLogo = (
    state: EmailTemplateHeaderState
  ): EmailTemplateHeaderState["logo"] => {
    if (!!state.logo) {
      return state.logo;
    }
    const brandLogo =
      brandStyle && !!brandStyle.logos.length ? brandStyle.logos[0] : null;
    if (!brandLogo) {
      return {
        enabled: false,
        image_url: "",
        id: "",
        type: EmailElementType.image,
      };
    }

    return {
      enabled: true,
      image_url: brandLogo.url,
      id: brandLogo.id,
      type: EmailElementType.image,
    };
  };

  if (state.customHTML.enabled) {
    return <CustomHTMLView options={state} />;
  }

  return (
    <Flex direction="column" gap="32px">
      <TemplateModifier gap="16px" direction="column">
        <Text weight="medium">Type</Text>
        <SegmentedControl.Root
          onValueChange={(val) =>
            updateState({
              ...state,
              widthType: val as EmailSectionWidthType,
            })
          }
          value={state.widthType}
          size="3"
        >
          <SegmentedControl.Item value="contained">
            Contained
          </SegmentedControl.Item>
          <SegmentedControl.Item value="full">Fullwidth</SegmentedControl.Item>
        </SegmentedControl.Root>
      </TemplateModifier>
      <TemplateModifier gap="16px" direction="column">
        <Text weight="medium">Explore layout variants</Text>
        <VariantSelector
          option={state.selectedTemplateIndex}
          onChange={(option) =>
            updateState({
              ...state,
              selectedTemplateIndex: option,
            })
          }
        />
      </TemplateModifier>
      {state.type === EmailSectionType.header && (
        <TemplateModifier gap="16px" direction="column">
          <LogoSelector
            img={getHeaderLogo(state)}
            onUpload={(img) =>
              updateState({
                ...state,
                logo: {
                  ...state.logo,
                  image_url: URL.createObjectURL(img),
                },
              })
            }
          />
        </TemplateModifier>
      )}
      {state.colors.map((color, index) => (
        <TemplateModifier gap="16px" direction="column" key={index}>
          <Text weight="medium">
            {state.colors.length > 1 ? `Background ${index + 1}` : "Background"}
          </Text>
          <BackgroundSelector
            color={color}
            onColorChange={(color) =>
              updateState({
                ...state,
                colors: state.colors.map((c, i) =>
                  i === index
                    ? {
                        ...c,
                        background: color,
                      }
                    : c
                ),
              })
            }
            onUpload={(img) =>
              updateState({
                ...state,
                colors: state.colors.map((c, i) =>
                  i === index
                    ? {
                        ...c,
                        backgroundImage: img,
                      }
                    : c
                ),
              })
            }
          />
        </TemplateModifier>
      ))}
      {state.menuLinks.enabled && (
        <TemplateModifier gap="16px" direction="column">
          <Flex justify="between" align="center">
            <Text weight="medium">Menu Links</Text>
            <Switch
              color="gray"
              highContrast
              checked={state.menuLinks.enabled}
              onCheckedChange={(c) =>
                updateState({
                  ...state,
                  menuLinks: {
                    ...state.menuLinks,
                    enabled: c,
                  },
                })
              }
            />
          </Flex>
          <Button
            onClick={() =>
              dispatch({
                type: "CHANGE_CURRENT_VIEW",
                payload: CustomizationViews.MenuLinks,
              })
            }
            size="4"
            variant="outline"
            color="gray"
            radius="large"
          >
            <PencilIcon />
            Edit
          </Button>
        </TemplateModifier>
      )}
      {state.type === EmailSectionType.footer && state.socialLinks.enabled && (
        <TemplateModifier gap="16px" direction="column">
          <Flex justify="between" align="center">
            <Text weight="medium">Social Links</Text>
            <Switch
              color="gray"
              highContrast
              checked={state.socialLinks.enabled}
              onCheckedChange={(c) =>
                updateState({
                  ...state,
                  socialLinks: {
                    ...state.socialLinks,
                    enabled: c,
                  },
                })
              }
            />
          </Flex>
          <Button
            onClick={() =>
              dispatch({
                type: "CHANGE_CURRENT_VIEW",
                payload: CustomizationViews.SocialLinks,
              })
            }
            size="4"
            variant="outline"
            color="gray"
            radius="large"
          >
            <PencilIcon />
            Edit
          </Button>
        </TemplateModifier>
      )}
      {state.type === EmailSectionType.header && state.tagline.enabled && (
        <TemplateModifier gap="16px" direction="column">
          <Flex justify="between" align="center">
            <Text weight="medium">Tagline</Text>
            <Switch
              color="gray"
              highContrast
              checked={state.tagline.enabled}
              onCheckedChange={(c) =>
                updateState({
                  ...state,
                  tagline: {
                    ...state.tagline,
                    enabled: c,
                  },
                })
              }
            />
          </Flex>
          <Flex gap="16px" width="100%">
            <Button
              style={{ width: "calc(50% - 8px)" }}
              size="4"
              variant="outline"
              color="gray"
              radius="large"
              onClick={() => {
                updateState({
                  ...state,
                  tagline: {
                    ...state.tagline,
                    isEditing: !state.tagline.isEditing,
                  },
                });
              }}
            >
              {state.tagline.isEditing ? "Done" : "Edit"}
            </Button>
            <Button
              style={{ width: "calc(50% - 8px)" }}
              size="4"
              variant="outline"
              color="gray"
              radius="large"
            >
              <RefreshCcwIcon />
              Regenerate
            </Button>
          </Flex>
        </TemplateModifier>
      )}
      {!state.menuLinks.enabled && (
        <Flex justify="between" align="center">
          <Text weight="medium">Menu Links</Text>
          <Switch
            color="gray"
            highContrast
            checked={state.menuLinks.enabled}
            onCheckedChange={(c) =>
              updateState({
                ...state,
                menuLinks: {
                  ...state.menuLinks,
                  enabled: c,
                },
              })
            }
          />
        </Flex>
      )}
      {state.type === EmailSectionType.footer && !state.socialLinks.enabled && (
        <Flex justify="between" align="center">
          <Text weight="medium">Social Links</Text>
          <Switch
            color="gray"
            highContrast
            checked={state.socialLinks.enabled}
            onCheckedChange={(c) =>
              updateState({
                ...state,
                socialLinks: {
                  ...state.socialLinks,
                  enabled: c,
                },
              })
            }
          />
        </Flex>
      )}
      {state.type === EmailSectionType.header && !state.tagline.enabled && (
        <Flex justify="between" align="center">
          <Text weight="medium">Tagline</Text>
          <Switch
            color="gray"
            highContrast
            checked={state.tagline.enabled}
            onCheckedChange={(c) =>
              updateState({
                ...state,
                tagline: {
                  ...state.tagline,
                  enabled: c,
                },
              })
            }
          />
        </Flex>
      )}
      {!state.customHTML.enabled && (
        <Flex justify="between" align="center">
          <Text weight="medium">Use Custom HTML</Text>
          <Switch
            color="gray"
            highContrast
            checked={state.customHTML.enabled}
            onCheckedChange={(c) =>
              updateState({
                ...state,
                customHTML: {
                  ...state.customHTML,
                  enabled: c,
                },
              })
            }
          />
        </Flex>
      )}
    </Flex>
  );
};

export default TemplateCustomization;
