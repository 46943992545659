import CampaignWizardCard from "../../../components/campaign/wizard/cards/CampaignWizardCard";
import LoadingDots from "../../../components/campaign/wizard/misc/LoadingDots";
import {
  RecommendedCampaignWizardSteps,
  useCampaignWizardDispatch,
} from "../../../contexts/CampaignWizardContext";
import { Flex } from "@radix-ui/themes";
import { useParams } from "react-router-dom";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useCampaignRecommendationQuery from "~/hooks/campaign/useCampaignRecommendationQuery";

const CampaignSummary = () => {
  const activeBrandID = useActiveBrandID();
  const { recommendationID } = useParams();
  const dispatch = useCampaignWizardDispatch();

  const { campaignRecommendation, isLoading } = useCampaignRecommendationQuery(
    activeBrandID,
    recommendationID ?? null
  );
  const subtitle =
    campaignRecommendation?.summary ??
    campaignRecommendation?.creative_recommendations?.[0]?.title ??
    "";
  return (
    <CampaignWizardCard
      badgeTitle="Campaign Summary"
      subtitle={
        isLoading
          ? ""
          : [
              subtitle,
              "Do you want to quickly review and tweak key campaign choices?",
            ]
      }
      backButtonProps={{
        hide: true,
      }}
      additionalButtons={
        <AppButton
          onClick={() => {
            dispatch({
              type: "SET_CURRENT_STEP",
              payload: {
                currentStep: RecommendedCampaignWizardSteps.CREATIVE_CONCEPT,
              },
            });
          }}
          variant="outlined"
        >
          All good. Let's see the creatives!
        </AppButton>
      }
      nextButtonProps={{
        text: "Yes, let’s review.",
      }}
      hideFooter={isLoading}
    >
      <Flex direction="column" gap="16px">
        {isLoading && <LoadingDots />}
      </Flex>
    </CampaignWizardCard>
  );
};

export default CampaignSummary;
