import CampaignPublishDialogAdCard from "./CampaignPublishDialogAdCard";
import CampaignPublishDialogEmailCard from "./CampaignPublishDialogEmailCard";
import { AdMediaAspectRatio, EmailCreativeStatus, operations } from "@openapi";
import {
  Dialog,
  Flex,
  Text,
  SegmentedControl,
  ScrollArea,
  Box,
} from "@radix-ui/themes";
import { Info, X } from "lucide-react";
import { useState } from "react";
import { Toaster } from "sonner";
import styled from "styled-components";
import ChatContainer from "~/components/chat/ChatContainer";
import AppButton from "~/components/core/buttons/AppButton/AppButton";

const Header = styled.div`
  border: 1px solid var(--border-primary);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
`;

const CreativesList = styled(Flex)`
  justify-content: center;
  padding: 32px 48px;
`;

type CampaignDetailResponse =
  operations["campaign_api_get_campaign_detail"]["responses"][200]["content"]["application/json"];

const CampaignPublishDialog = ({
  campaign,
}: {
  campaign: CampaignDetailResponse;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [campaignType, setCampaignType] = useState<"email" | "ads">("email");
  const [selectedCreatives, setSelectedCreatives] = useState<{
    emails: string[];
    ads: string[];
  }>({
    emails: campaign.email_creatives.map(({ id }) => id),
    ads: campaign.ad_creatives.map(({ id }) => id),
  });

  const handleCreativeSelection = (
    creativeId: string,
    type: "emails" | "ads"
  ) => {
    setSelectedCreatives((prev) => ({
      ...prev,
      [type]: prev[type].includes(creativeId)
        ? prev[type].filter((id) => id !== creativeId)
        : [...prev[type], creativeId],
    }));
  };

  const unpublishedEmailCreatives = campaign.email_creatives.filter(
    ({ status }) =>
      status === EmailCreativeStatus.unpublished ||
      status === EmailCreativeStatus.draft
  );
  const publishedEmailCreatives = campaign.email_creatives.filter(
    ({ status }) =>
      status !== EmailCreativeStatus.unpublished &&
      status !== EmailCreativeStatus.draft
  );

  const emailContent = (
    <CreativesList
      direction={"column"}
      width={"100%"}
      gap={"24px"}
      overflow={"auto"}
    >
      {unpublishedEmailCreatives.map((email, idx) => (
        <CampaignPublishDialogEmailCard
          audiences={email.audiences}
          publishedAt={email.published_at}
          onSelect={() => handleCreativeSelection(email.id, "emails")}
          selected={selectedCreatives.emails.includes(email.id)}
          key={email.id}
          emailId={email.id}
          campaignId={campaign.id}
          previewImage={email.preview_image}
          title={`Email ${idx + 1}`}
          contentHeader={email.title}
          contentSubheader={email.subtitle}
        />
      ))}
      {publishedEmailCreatives.length > 0 && (
        <Box>
          <Text size={"2"} weight={"bold"}>
            Published Emails
          </Text>

          <Flex align="center" gap="2" mt="2">
            <Info size={16} />
            <Text size="2" color="gray">
              You can still edit & change scheduled date from the email editor.
            </Text>
          </Flex>
        </Box>
      )}
      {publishedEmailCreatives.map((email, idx) => (
        <CampaignPublishDialogEmailCard
          audiences={email.audiences}
          publishedAt={email.published_at}
          selected={selectedCreatives.emails.includes(email.id)}
          key={email.id}
          emailId={email.id}
          campaignId={campaign.id}
          previewImage={email.preview_image}
          title={`Email ${idx + 1}`}
          contentHeader={email.title}
          contentSubheader={email.subtitle}
        />
      ))}
    </CreativesList>
  );

  const adContent = (
    <CreativesList
      direction={"column"}
      width={"100%"}
      gap={"24px"}
      overflow={"auto"}
    >
      {campaign.ad_creatives.map((ad) => (
        <CampaignPublishDialogAdCard
          key={ad.id}
          onSelect={() => handleCreativeSelection(ad.id, "ads")}
          selected={selectedCreatives.ads.includes(ad.id)}
          adId={ad.id}
          title={ad.title}
          previewImages={ad.preview_images}
          contentHeader=""
          contentSubheader={ad.primary_text}
        />
      ))}
    </CreativesList>
  );

  return (
    <>
      <Dialog.Root open={isOpen}>
        <Dialog.Trigger>
          <AppButton onClick={() => setIsOpen(true)}>
            Publish Campaign
          </AppButton>
        </Dialog.Trigger>

        <Dialog.Content
          style={{ padding: 0 }}
          maxWidth={"95%"}
          maxHeight={"95%"}
          height={"90vh"}
        >
          <Toaster richColors />

          <Flex direction={"column"} height={"100%"}>
            <Header>
              <Text size="5" weight={"bold"}>
                Publish Campaign
              </Text>
              <SegmentedControl.Root
                value={campaignType}
                radius="large"
                size="3"
                onValueChange={(value) => {
                  if (value) {
                    setCampaignType(value as "email" | "ads");
                  }
                }}
              >
                <SegmentedControl.Item value="email">
                  <Text>Email</Text>
                </SegmentedControl.Item>
                <SegmentedControl.Item value="ads">Ads</SegmentedControl.Item>
              </SegmentedControl.Root>
              <AppButton
                variant="outlined"
                radius="large"
                onClick={() => setIsOpen(false)}
              >
                <X size={16} />
                Close
              </AppButton>
            </Header>

            <Flex direction="row" height={"100%"} minHeight={"0"}>
              <ChatContainer
                campaignId={campaign.id}
                toggleSelectedCampaignType={() =>
                  setCampaignType(campaignType === "email" ? "ads" : "email")
                }
                selectedCampaignType={campaignType}
                selectedAds={selectedCreatives.ads}
                selectedEmails={selectedCreatives.emails}
              />

              <ScrollArea scrollbars="vertical">
                {campaignType === "email" ? emailContent : adContent}
              </ScrollArea>
            </Flex>
          </Flex>
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
};

export default CampaignPublishDialog;
